import { Component, OnInit } from '@angular/core';
import { ServiceService } from './../service.service';
import * as moment from 'moment';

@Component({
  selector: 'app-networkstatus',
  templateUrl: './networkstatus.component.html',
  styleUrls: ['./networkstatus.component.css']
})
export class NetworkstatusComponent implements OnInit {

  constructor(
    public service: ServiceService
  ) { }

  listNetworkStatus = [];
  screenSize = false;
  
  ngOnInit(): void {
    console.log(this.service.idUser)
    this.service.post_NetworkStatus(this.service.idUser).subscribe((data: any) => {
      for (let i = 0; i < data.length; i++) {
        this.listNetworkStatus.push(
          {
            'timestamp': moment(data[i]['timestamp']).format('DD/MM/YYYY HH:mm:SS'),
            'lastvalidconection': moment(data[i]['lastvalidconection']).format('DD/MM/YYYY HH:mm:SS'),
            'concession': data[i]['concession'],
            'status': data[i]['status']
          }
        );
        if (!data[i]['status']) {
          this.service.networkcountoff.push(
            {
              'off': data[i]['status']
            }
          )
        }
      }
    });
  }

}
