import { Component, OnInit } from '@angular/core';
import { ServiceService } from './../service.service';
import { MatDialogRef} from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { sha256 } from 'js-sha256';

@Component({
  selector: 'app-emailservice',
  templateUrl: './emailservice.component.html',
  styleUrls: ['./emailservice.component.css']
})
export class EmailserviceComponent implements OnInit {

  constructor(
    public service: ServiceService,
    public dialogRef: MatDialogRef<EmailserviceComponent>,
    public snackBar: MatSnackBar
  ) { }

  enablessl = false;
  server = "";
  port;
  emailfrom = "";
  user = "";
  pass = "";
  loading = false;

  ngOnInit(): void {
    this.service.sessionExpired();
    this.getEmailCredential();
  }

  getEmailCredential() {
    this.service.get_serveremailcredential().subscribe((data: any) => {
      this.server = data[0]['server'];
      this.port = data[0]['emailport'];
      this.emailfrom = data[0]['emailclient'];
      this.user = data[0]['emailuser'];
      this.enablessl = data[0]['emailssl'];
    });
  }

  editEmailService() {

    this.loading = true;

    let sslenabled;

    if (this.enablessl) {
      sslenabled = 1;
    } else {
      sslenabled = 0;
    }

    if (this.server == "") {
      this.alertmessage('Insira o servidor de e-mail.');
    } else if (this.emailfrom == "") {
      this.alertmessage('Insira o e-mail de origem.');
    } else if (this.port == "") {
      this.alertmessage('Insira a porta.');
    } else if (isNaN(Number(this.port))) {
        this.alertmessage('Insira um valor númerico para a porta.');
    } else if (this.user == "") {
      this.alertmessage('Insira o usuário.');
    } else {
      this.service.post_serveremailcredential(this.server, this.emailfrom, this.port, this.user, sha256(this.pass), sslenabled).subscribe
      ((data: any) => {
        if (data) {
          this.loading = false;
          this.dialogRef.close(true);
          this.snackBar.open('Dados alterado com sucesso.', '', {
            duration: 5000,
            verticalPosition: 'top',
            horizontalPosition : 'center',
            panelClass: ['snackbarOk']
          });
        }
      });
    }
  }

  alertmessage(message) {
    this.loading = false;
    this.snackBar.open(message, '', {
      duration: 3000,
      verticalPosition: 'top',
      horizontalPosition : 'center',
      panelClass: ['snackbarError']
    });
  }

}
