import { Component, OnInit, ViewChild } from '@angular/core';
import { ServiceService } from './../service.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import {MatDialog} from '@angular/material/dialog';
import { AddgroupsComponent } from './../addgroups/addgroups.component';

@Component({
  selector: 'app-tablegroups',
  templateUrl: './tablegroups.component.html',
  styleUrls: ['./tablegroups.component.css']
})
export class TablegroupsComponent implements OnInit {

  displayedColumnsGroups = ['nome', 'tensao', 'complinha', 'categoria'];
  dataSource: MatTableDataSource<listgroups>;
  loading = false;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;


  constructor(
    public service: ServiceService,
    public dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    this.listGroups();
  }

  listGroups() {
    this.loading = true;
    const listgroupstable: listgroups[] = [];

    this.service.post_ListGroups().subscribe((data: any) => {
      for (let i = 0; i < data.length; i++) {
        listgroupstable.push(
          {
            'nome': data[i]['nome'],
            'tensao': data[i]['tensao'],
            'complinha': data[i]['complinha'],
            'categoria': data[i]['categoria']
          }
        );
        if (i == data.length - 1) {
          this.loading = false;
          this.dataSource = new MatTableDataSource(listgroupstable);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
        }
      }
    });
  }

  openDialog(): void {
    let dialogRef = this.dialog.open(AddgroupsComponent, {
      width: '500px',
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }

}


export interface listgroups {
  nome: string;
  tensao: string;
  complinha: string;
  categoria: number;
}
