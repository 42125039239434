import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ServiceService } from './../service.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import {MatDialog} from '@angular/material/dialog';
import { ChartoscComponent } from './../chartosc/chartosc.component';
import * as moment from 'moment';
import { FormControl } from '@angular/forms';
import { SelectionModel } from '@angular/cdk/collections';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { map, startWith, timeout, retry, tap, retryWhen, delayWhen } from 'rxjs/operators';
import { Observable, timer } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-listosc',
  templateUrl: './listosc.component.html',
  styleUrls: ['./listosc.component.css']
})
export class ListoscComponent implements OnInit {

  displayedColumns: string[] = ['select', 'favorite', 'DataHoraOcorrencia', 'Concessao',
  'Subestacao', 'Linha', 'Equipamento', 'Arquivo', 'download', 'grafico', 'updatetable'];
  dataSource: MatTableDataSource<listosc>;

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  selection = new SelectionModel<Element>(true, []);
  myControlConcessao: FormControl = new FormControl();

  @ViewChild('downloadZipLink') private downloadZipLink: ElementRef;
  @ViewChild('menuTrigger') trigger;

  loading = false;
  loadingfilters = false;
  dataInicial: string = '';
  dataFinal: string = '';
  hora_inicial: '';
  hora_final: '';
  listConcessao = [];
  listSubest = [];
  listBay = [];
  listRele = [];
  concessao: string = '';
  subest: string = '';
  bay: string = '';
  rele: string = '';
  flagNoOscSelected = false;
  flagHasOsc = false;
  warningNosOscMessage = false;
  objHttp;
  flagDownloadOk = false;
  nameFileFavorite = '';
  flagAddFavoriteAll = false;
  listosctable: listosc[] = [];
  listosctableexcel = [];
  showButtonClenaFilter = false;
  counterFilesToDownload = 0;
  selectConcessao;
  selectSubestacao;
  selectLinha;
  selectRele;
  flagNoData = false;
  fileUrl;
  filtropalavra;
  typesearch = 0;
  filterconcessao: string = '';
  labelfilterloading0: string = 'Carregando';
  labelfilterloading1: string = 'Carregando';
  labelfilterloading2: string = 'Carregando';
  labelfilterloading3: string = 'Carregando';
  loadingbutton = false;

  constructor(
    public service: ServiceService,
    public dialog: MatDialog,
    private http: HttpClient,
    public snackBar: MatSnackBar,
    private sanitizer: DomSanitizer
  ) { }


  ngOnInit(): void {
    this.loading = true;
    this.listAllFilters(this.typesearch, this.filterconcessao);
    this.mountListOsc(this.dataInicial, this.dataFinal, this.rele, this.subest, this.concessao, this.bay);
    this.service.sessionExpired();
  }

  listAllFilters(type, concessaofiltered) {
    this.loadingfilters = true;
    this.service.post_ListAllFilters(this.service.idUser, type, concessaofiltered).subscribe((data: any) => {
      if (data['Equipamentos']) {
        this.listConcessao = data['Concessoes'];
        this.listSubest =  data['Subestacoes'];
        this.listBay =  data['Linhas'];
        this.listRele =  data['Equipamentos'];
        this.loadingfilters = false;
        this.labelfilterloading0 = 'Filtro Concessão'
        this.labelfilterloading1 = 'Filtro Subestação'
        this.labelfilterloading2 = 'Filtro Linha'
        this.labelfilterloading3 = 'Filtro Relé/RDP'
      }
    });
  }

  mountListOsc(initianldate, finaldate, rele, subest, concessao, bay) {

    this.loading = true;
    let isFavorite = false;

    this.service.post_ListOsc('', initianldate, finaldate, rele, subest, concessao, bay, this.service.username).subscribe((data: any) => {

      if (data['data'].length > 0) {

        this.warningNosOscMessage = false;
        this.flagNoData = true;

        for (let i = 0; i < data['data'].length; i++) {

          if (data['data'][i]['Favorito'] != '0') {
            isFavorite = true;
          } else {
            isFavorite = false;
          }

          this.listosctable.push(
            {
              'select': false,
              'favorite': isFavorite,
              'DataHoraOcorrencia': moment(data['data'][i]['DataHoraOcorrencia']).locale('pt-BR').
              format('dddd, DD/MM/YY [às] HH:mm:ss.SSS').toString(),
              'Concessao': data['data'][i]['Concessao'],
              'Subestacao': data['data'][i]['Subestacao'],
              'Linha': data['data'][i]['Linha'],
              'Equipamento': data['data'][i]['Equipamento'],
              'Arquivo': data['data'][i]['Arquivo'],
              'download': '',
              'grafico': '',
              'updatetable': false
            }
          );
          this.listosctableexcel.push(
            {
              'Data e Hora da ocorrência': moment(data['data'][i]['DataHoraOcorrencia']).locale('pt-BR').
              format('dddd, DD/MM/YY [às] HH:mm:ss.SSS').toString(),
              'Concessão': data['data'][i]['Concessao'],
              'Subestação': data['data'][i]['Subestacao'],
              'Linha': data['data'][i]['Linha'],
              'Equipamento': data['data'][i]['Equipamento']
            }
          );
          if (i == data['data'].length - 1) {
            this.loading = false;
            this.dataSource = new MatTableDataSource(this.listosctable);
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;
            /* this.listAllFilters(this.typesearch, this.filterconcessao); */
          }
        }
      } else {
        this.loading = false;
        this.flagNoData = false;
        this.warningNosOscMessage = true;
      }
    });
  }

  openChart(Concessao, Subestacao, Equipamento, Arquivo) {

    let dialogRef = this.dialog.open(ChartoscComponent, {
      maxWidth: '100vw',
      maxHeight: '100vh',
      height: '100%',
      width: '100%',
      autoFocus: true,
      disableClose: false,
      data: { 'concessao': Concessao, 'subestacao': Subestacao, 'equipamento': Equipamento,
              'arquivo': Arquivo }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (typeof (result) !== 'undefined') {
      }
    });
  }

  clearFilter() {
    this.trigger.closeMenu()
    this.dataInicial = '';
    this.dataFinal = '';
    this.concessao = '';
    this.rele = '';
    this.subest = '';
    this.bay = '';
    this.selectConcessao = '';
    this.selectSubestacao = '';
    this.selectLinha = '';
    this.selectRele = '';
    this.filtropalavra = '';
    this.listosctable = [];
    this.showButtonClenaFilter = false;
    this.listosctableexcel = [];
    this.mountListOsc(this.dataInicial, this.dataFinal, this.rele, this.subest, this.concessao, this.bay);
  }

  clearFilterAll() {
    this.dataInicial = '';
    this.dataFinal = '';
    this.concessao = '';
    this.rele = '';
    this.subest = '';
    this.bay = '';
    this.selectConcessao = '';
    this.selectSubestacao = '';
    this.selectLinha = '';
    this.selectRele = '';
    this.filtropalavra = '';
    this.listosctableexcel = [];
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  filtroConcessao(e) {
    this.trigger.closeMenu()
    this.filterconcessao = e.value;
    if (e.value == 'All') {
      this.typesearch = 0;
     } else {
      this.typesearch = 1;
    }
    this.listosctable = [];
    this.listosctableexcel = [];
    this.showButtonClenaFilter = true;
    this.flagNoData = false;
    this.warningNosOscMessage = false;
    this.listAllFilters(this.typesearch, this.filterconcessao);
    this.mountListOsc(this.dataInicial, this.dataFinal, this.rele, this.subest, e.value, this.bay);
  }

  filtroSubest(e) {
    this.trigger.closeMenu()
    this.listosctable = [];
    this.listosctableexcel = [];
    this.showButtonClenaFilter = true;
    this.flagNoData = false;
    this.warningNosOscMessage = false;

    if (e.value == 'All') {
      this.typesearch = 0;
      //this.clearFilterAll();
     } else {
      this.typesearch = 1;
    }
    
    this.listAllFilters(this.typesearch, this.filterconcessao);
    this.mountListOsc(this.dataInicial, this.dataFinal, this.rele, e.value, this.concessao, this.bay);
  }

  filtroBay(e) {
    this.trigger.closeMenu()
    this.listosctable = [];
    this.listosctableexcel = [];
    this.showButtonClenaFilter = true;
    this.flagNoData = false;
    this.warningNosOscMessage = false;

    if (e.value == 'All') {
      this.typesearch = 0;
      this.clearFilterAll();
     } else {
      this.typesearch = 1;
    }

    this.listAllFilters(this.typesearch, this.filterconcessao);
    this.mountListOsc(this.dataInicial, this.dataFinal, this.rele, this.subest, this.concessao, e.value);
  }

  filtroRele(e) {
    this.trigger.closeMenu()
    this.listosctable = [];
    this.listosctableexcel = [];
    this.showButtonClenaFilter = true;
    this.flagNoData = false;
    this.warningNosOscMessage = false;

    if (e.value == 'All') {
      this.typesearch = 0;
      this.clearFilterAll();
     } else {
      this.typesearch = 1;
    }

    this.listAllFilters(this.typesearch, this.filterconcessao);
    this.mountListOsc(this.dataInicial, this.dataFinal, e.value, this.subest, this.concessao, this.bay);
  }

  filtroDataInicial(e) {

    let hora = this.hora_inicial;
    let data = this.dataInicial;

    let x
    if (typeof (e.target) !== 'undefined') {
      x = moment(e.target.value, 'YYYY-MM-DDTHH:mm:SS')
      x = x.add(moment(hora, 'HH:mm:ss').get('hour'), 'hour')
        .add(moment(hora, 'HH:mm:ss').get('minute'), 'minute')
        .add(moment(hora, 'HH:mm:ss').get('seconds'), 'seconds')
        .format('YYYY-MM-DD[T]HH:mm:ss').toString()
    } else {
      x = moment(data)
      x = x.add(moment(e, 'HH:mm:ss').get('hour'), 'hour')
        .add(moment(e, 'HH:mm:ss').get('minute'), 'minute')
        .add(moment(e, 'HH:mm:ss').get('seconds'), 'seconds')
        .format('YYYY-MM-DD[T]HH:mm:ss').toString()
    }

    this.dataInicial = x;
  }

  filtroDataFinal(e) {

    this.listosctable = [];
    this.listosctableexcel = [];
    this.showButtonClenaFilter = true;

    let hora = this.hora_final;
    let data = this.dataFinal;
    let x;

    if (typeof (e.target) !== 'undefined') {
      x = moment(e.target.value, 'YYYY-MM-DDTHH:mm:SS');
      x = x.add(moment(hora, 'HH:mm:ss').get('hour'), 'hour')
        .add(moment(hora, 'HH:mm:ss').get('minute'), 'minute')
        .add(moment(hora, 'HH:mm:ss').get('seconds'), 'seconds')
        .format('YYYY-MM-DD[T]HH:mm:ss').toString();
    } else {
      x = moment(data)
      x = x.add(moment(e, 'HH:mm:ss').get('hour'), 'hour')
        .add(moment(e, 'HH:mm:ss').get('minute'), 'minute')
        .add(moment(e, 'HH:mm:ss').get('seconds'), 'seconds')
        .format('YYYY-MM-DD[T]HH:mm:ss').toString();
    }
    if(this.hora_inicial === undefined){
      x = moment(x)
        .hours(23)
        .minutes(59)
        .seconds(59)
        .format('YYYY-MM-DD[T]HH:mm:ss').toString();
    }

    this.dataFinal = x;

    if (this.dataInicial != '') {
      this.trigger.closeMenu()
      this.mountListOsc(this.dataInicial, this.dataFinal, this.rele, this.subest, this.concessao, this.bay);
    }
  }

  stopPropagation(e) {
    e.stopPropagation();
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  masterToggle(e) {

    if (e.checked) {
      this.listosctable.forEach((currentValue, index) => {
        currentValue.select = true;
      });
    } else {
      this.listosctable.forEach((currentValue, index) => {
        currentValue.select = false;
      });
    }
  }

  addFavorite(type, data) {

    if (type == 0) {
      data.favorite = true;
      this.service.post_addFavorite(this.service.idUser, data.Arquivo, type).subscribe((data: any) => {
        if (data) {
          console.log('inserido com sucesso')
        }
      });
    } else {
      data.favorite = false;
      this.service.post_addFavorite(this.service.idUser, data.Arquivo, type).subscribe((data: any) => {
        if (data) {
          console.log('inserido com sucesso')
        }
      });
    }
  }

  addAllFavorite(type) {
    let i = 0;
    this.nameFileFavorite = 'Preparando para adicionar aos favoritos.';
    this.flagAddFavoriteAll = true;
    this.listosctable.forEach((currentValue, index) => {
      currentValue.favorite = true;
      this.service.post_addFavorite(this.service.idUser, currentValue.Arquivo, type).subscribe((data: any) => {
        if (data) {
          this.nameFileFavorite = 'Adicioando ' + currentValue.Arquivo + ' aos favoritos.';
          i = i + 1;
          if (i == this.listosctable.length - 1) {
            this.flagAddFavoriteAll = false;
            this.snackBar.open('Arquivos adicionados ao favortio com sucesso', '', {
              duration: 2000,
              verticalPosition: 'top',
              horizontalPosition : 'center',
              panelClass: ['snackbarOk']
            });
          }
        }
      });
    });
  }

  downloadOscByOneFile(obj) {
    this.flagDownloadOk = false;
    let listaselected = [];
    this.flagNoOscSelected = false;
    this.flagHasOsc = true;
    listaselected.push(obj);

    console.log(listaselected)

    this.counterFilesToDownload = listaselected.length;

    this.service.post_addFilesToDownload(listaselected).subscribe((data: any) => {
      if (data) {
        this.callBlob();
      }
    });
  }

  downloadOsc() {

    let listaselected = [];
    this.flagNoOscSelected = false;
    this.flagHasOsc = true;
    this.flagDownloadOk = false;

    for (let i = 0; i < this.selection.selected.length; i++) {
      this.selection.selected[i]['selected'] = true;
      listaselected.push(this.selection.selected[i]);
    }

    this.listosctable.forEach((currentValue, index) => {
      if (currentValue.select == true) {
        listaselected.push(currentValue);
      }
    });

    if (listaselected.length == 0) {
      this.flagNoOscSelected = true;
      this.flagHasOsc = false;
    } else {
      this.counterFilesToDownload = listaselected.length;
      this.service.post_addFilesToDownload(listaselected).subscribe((data: any) => {
        if (data) {
          this.callBlob();
        }
      });
    }
  }

  callBlob() {
    let obj = { 'info': 'obj' };

    this.flagNoOscSelected = false;
    this.flagHasOsc = true;

    const headers = new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8', timeout: `${1000000}` });
    headers.append('Authorization', 'Basic ' + btoa('sicarfosc:sicarfosc'));
    /* const url = 'http://localhost:82/downloadfile'; */
    /* const url = 'http://172.20.186.10:82/apinew/downloadfile'; */
    const url = 'backend/downloadfile';
    this.objHttp = this.http
      .post(url, obj, { headers: headers, responseType: 'blob', })
    .pipe(
      tap(val => {
      }),
      tap(() => console.log("HTTP request executed MANDOU!")),
    )
      .subscribe(
        data => {
          console.log(data)
          var a = document.createElement("a");
          a.setAttribute('style', 'display:none;');
          document.body.appendChild(a);
          var url = window.URL.createObjectURL(data);
          a.href = url;
          a.download = 'Oscilografias_Download_' + moment().format("DD-MM-YYYY HH_mm_ss"); + '.zip';
          a.click();
          this.flagHasOsc = false;
          this.flagDownloadOk = true;

          this.listosctable.forEach((currentValue, index) => {
            currentValue.select = false;
          });
        },
    );
  }

  ExportExcel() {
    this.service.generalloading = true;
    this.service.exportAsExcelFile(this.listosctableexcel, 'Lista de Oscilografias', 'Lista de Oscilografias');
  }

  refresh() {
    this.listosctable = [];
    this.listosctableexcel = [];
    this.mountListOsc(this.dataInicial, this.dataFinal, this.rele, this.subest, this.concessao, this.bay);
  }
  
}

export interface listosc {
  select: boolean;
  favorite: boolean;
  DataHoraOcorrencia: string;
  Concessao: string;
  Subestacao: string;
  Linha: string;
  Equipamento: string;
  Arquivo: string;
  download: string;
  grafico: string;
  updatetable: boolean;
}
