import { Component, OnInit, Inject } from '@angular/core';
import { ServiceService } from './../service.service';
import {FormControl} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import { sha256 } from 'js-sha256';
import { MatSnackBar } from '@angular/material/snack-bar';

export interface DialogData {
  username: string;
  iduser: number;
  fullname: string;
  email: string;
  groups: Array<any>;
  type: number;
}

@Component({
  selector: 'app-edituser',
  templateUrl: './edituser.component.html',
  styleUrls: ['./edituser.component.css']
})
export class EdituserComponent implements OnInit {

  constructor(
    public service: ServiceService,
    public dialogRef: MatDialogRef<EdituserComponent>,
    public snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
  ) { }

    validateedituser = false;
    warning = false;
    warningmessage = '';
    username = '';
    email = '';
    editUserError = false;
    editUserOk = false;
    fullname = '';
    fieldTextType: boolean;
    fieldTextTypeConfirm: boolean;
    passwordini = '';
    password = '';
    passwordconfirm = '';
    comboboxMultGrupos = new FormControl();
    listaGruposCombobox = [];
    finalList = [];
    changePass = false;
    changePassCheck = false;
    changeOscCheck = false;
    getOscByEmail;
    getNetworkFailByEmail;
    changeNetworkCheck = false;

  ngOnInit(): void {
    this.service.sessionExpired();
    this.checkStatusToEmail(this.data.iduser);
    this.username = this.data.username;
    this.fullname =  this.data.fullname;
    this.email = this.data.email;
    this.listGroups(this.data.iduser);
  }

  onEditUser() {

    this.validateedituser = true;

    this.finalList.push(
      {
        'iduser': this.data.iduser,
        'username': this.username,
        'fullname': this.fullname,
        'email': this.email,
        'password': this.password,
        'groups': this.listaGruposCombobox
      }
    );

    if (this.changePassCheck) {
      this.password = this.password;
    } else {
      this.password = sha256(this.password);
    }

    this.service.post_EditUser(this.data.iduser, this.username, this.fullname, this.email,
      this.password, this.listaGruposCombobox, this.changePass, this.changeOscCheck, this.changeNetworkCheck).subscribe((data: any) => {
      if (data) {
        this.warning = true;
        this.editUserOk = true;
        this.validateedituser = false;
        this.dialogRef.close(true);
        /* this.warningmessage = 'Dados alterado com sucesso.'; */
        this.snackBar.open('Dados alterado com sucesso.', '', {
          duration: 5000,
          verticalPosition: 'top',
          horizontalPosition : 'center',
          panelClass: ['snackbarOk']
        });
      }
    });

    this.service.post_AddToOperationHistory(this.service.idUser, 2).subscribe();
  }

  toggleFieldTextType(type) {
    if (type == 0) {
      this.fieldTextType = !this.fieldTextType;
    } else {
      this.fieldTextTypeConfirm = !this.fieldTextTypeConfirm;
    }
  }

  OnChange(event, groupid) {
    for (let i = 0; i < this.listaGruposCombobox.length; i++) {
      if (this.listaGruposCombobox[i]['id'] == groupid) {
        this.listaGruposCombobox[i]['status'] = event.checked;
      }
    }
  }

  changeStatus(event) {
    this.changePassCheck = event.checked;
    if (event.checked) {
      this.service.post_PassUser(this.username, this.fullname, this.email).subscribe((data: any) => {
        this.password = data[0]['pass'];
      });
    }
  }

  changeStatusOscEmail(event) {
    if (event.checked) {
      this.changeOscCheck = true;
    } else {
      this.changeOscCheck = false;
    }
  }

  changeStatusNetworkEmail(event) {
    if (event.checked) {
      this.changeNetworkCheck = true;
    } else {
      this.changeNetworkCheck = false;
    }
  }

  checkStatusToEmail(iduser) {
    this.service.post_userConditionToEmail(iduser).subscribe((data: any) => {
      if (data[0]['sendoscemail']) {
        this.changeOscCheck = true;
      } else {
        this.changeOscCheck = false;
      }

      if (data[0]['sendnetworkfailemail']) {
        this.changeNetworkCheck = true;
      } else {
        this.changeNetworkCheck = false;
      }
    });
  }

  listGroups(iduser) {

    this.service.post_ListGroupByUser(iduser).subscribe((data: any) => {
      let statusChecked;

      for (let i = 0; i < data.length; i++) {

        if (data[i]['status'] == 0) {
          statusChecked = false;
        } else {
          statusChecked = true;
        }

        this.listaGruposCombobox.push(
          {
            'id': data[i]['id'],
            'groupname': data[i]['nome'],
            'status': statusChecked
          }
        );
      }
    });
  }

}
