<div class="grid-container">
    <h1 mat-dialog-title>
        <mat-icon style="display: inline-flex; vertical-align: middle;padding-bottom: 4px; padding-right: 6px;">edit</mat-icon> Alterar dados serviço de e-mail
    </h1>
    <mat-card>
        <mat-card-content>
            <table style="width: 100%;">
                <tr *ngIf="this.loading">
                    <td style="border-bottom: 1px solid #E0E0E0;padding-bottom: 10px;">
                        <mat-spinner  [diameter]="20" style="margin: auto"></mat-spinner> <b>Editando dados</b>
                    </td>
                </tr>
                <tr *ngIf="this.service.isAdmin">
                    <td style="text-align: left;padding-top: 10px;">
                    <mat-checkbox [(ngModel)]="enablessl" (change)="changeStatus($event)" [checked]="enablessl">Habilitar SSL</mat-checkbox>
                    </td>
                </tr>
                <tr>
                    <mat-form-field class="full-width-input">
                        <input class="inputcolor" matInput placeholder="Servidor de e-mail" [(ngModel)]="server" [value]="this.server" name="server">
                    </mat-form-field>
                </tr>
                <tr>
                    <mat-form-field class="full-width-input">
                        <input class="inputcolor" matInput placeholder="Porta" [(ngModel)]="port" [value]="this.port" name="port">
                    </mat-form-field>
                </tr>
                <tr>
                    <mat-form-field class="full-width-input">
                        <input class="inputcolor" matInput placeholder="E-mail de origem" [(ngModel)]="emailfrom" [value]="this.emailfrom" name="emailfrom">
                    </mat-form-field>
                </tr>
                <tr>
                    <mat-form-field class="full-width-input">
                        <input class="inputcolor" matInput placeholder="Usuário" [(ngModel)]="user" [value]="this.user" name="user">
                    </mat-form-field>
                </tr>
                <tr>
                    <mat-form-field class="full-width-input">
                        <input class="inputcolor" matInput placeholder="Senha" type="password" [(ngModel)]="pass" [value]="this.pass" name="pass">
                    </mat-form-field>
                </tr>
            </table>
            <br>
            <!--<div style="width:100%;">
                <table style="width:100%;">
                    <tr>
                        <td style="text-align:center;">
                            <button mat-raised-button color="primary" type="submit" (click)="editEmailService()">Testar serviço</button>
                        </td>
                        <td>
                            <button mat-raised-button color="primary" type="submit" (click)="editEmailService()">Testar serviço</button>
                        </td>
                    </tr>
                </table>
            </div>
            <br>-->
            <button mat-raised-button color="primary" type="submit" (click)="editEmailService()">Salvar</button>
        </mat-card-content>
    </mat-card>
</div>