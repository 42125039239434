import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxEchartsModule } from 'ngx-echarts';
import { LoginComponent } from './login/login.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSliderModule } from '@angular/material/slider';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatIconModule} from '@angular/material/icon';
import {MatMenuModule} from '@angular/material/menu';
import {MatDialogModule} from '@angular/material/dialog';
import {MatSidenavModule} from '@angular/material/sidenav';
import { EdituserComponent } from './edituser/edituser.component';
import {MatListModule} from '@angular/material/list';
import {MatCardModule} from '@angular/material/card';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { ListoscComponent } from './listosc/listosc.component';
import { MatTableModule } from '@angular/material/table';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatButtonModule} from '@angular/material/button';
import {MatGridListModule} from '@angular/material/grid-list';
import { MatNativeDateModule } from '@angular/material/core';
import { ChartoscComponent } from './chartosc/chartosc.component';
import {MatSortModule} from '@angular/material/sort';
import { ListusersComponent } from './listusers/listusers.component';
import { MAT_DATE_LOCALE, MAT_DATE_FORMATS } from '@angular/material/core';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatTabsModule} from '@angular/material/tabs';
import { TableusersComponent } from './tableusers/tableusers.component';
import { TablegroupsComponent } from './tablegroups/tablegroups.component';
import { AdduserComponent } from './adduser/adduser.component';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import { AddgroupsComponent } from './addgroups/addgroups.component';
import { HttpClientModule } from '@angular/common/http';
import {MatExpansionModule} from '@angular/material/expansion';
import { NewpassComponent } from './newpass/newpass.component';
import { ForgetpassComponent } from './forgetpass/forgetpass.component';
import { NewforgetpassComponent } from './newforgetpass/newforgetpass.component';
import { Ng2GoogleChartsModule } from 'ng2-google-charts';
import { NgApexchartsModule } from 'ng-apexcharts';
import { HistorysystemoperationComponent } from './historysystemoperation/historysystemoperation.component';
import { HistoryalarmseventsComponent } from './historyalarmsevents/historyalarmsevents.component';
import { NetworkstatusComponent } from './networkstatus/networkstatus.component';
import { SessionexpiredalertComponent } from './sessionexpiredalert/sessionexpiredalert.component';
import {DragDropModule} from '@angular/cdk/drag-drop';
import { HistorynetworkComponent } from './historynetwork/historynetwork.component';
import { HistorydataComponent } from './historydata/historydata.component';
import { EmailserviceComponent } from './emailservice/emailservice.component';

const formats = {
  parse: {
    dateInput: 'DD/MM/YY'
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'D MMMM YYYY',
    monthYearA11yLabel: 'MMMM YYYY'
  }
};

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    EdituserComponent,
    ListoscComponent,
    ChartoscComponent,
    ListusersComponent,
    TableusersComponent,
    TablegroupsComponent,
    AdduserComponent,
    AddgroupsComponent,
    NewpassComponent,
    ForgetpassComponent,
    NewforgetpassComponent,
    HistorysystemoperationComponent,
    HistoryalarmseventsComponent,
    NetworkstatusComponent,
    SessionexpiredalertComponent,
    HistorynetworkComponent,
    HistorydataComponent,
    EmailserviceComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    NgxEchartsModule.forRoot({
      echarts: () => import('echarts'), // or import('./path-to-my-custom-echarts')
    }),
    MatAutocompleteModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatInputModule,
    MatRadioModule,
    MatSelectModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatToolbarModule,
    MatIconModule,
    MatMenuModule,
    MatDialogModule,
    MatSidenavModule,
    MatListModule,
    MatCardModule,
    MatProgressSpinnerModule,
    FormsModule,
    ReactiveFormsModule,
    MatTableModule,
    MatPaginatorModule,
    MatButtonModule,
    MatGridListModule,
    MatNativeDateModule,
    MatSortModule,
    MatTooltipModule,
    MatTabsModule,
    MatSnackBarModule,
    HttpClientModule,
    MatExpansionModule,
    Ng2GoogleChartsModule,
    NgApexchartsModule,
    DragDropModule
  ],
  providers: [
    {provide: MAT_DATE_FORMATS, useValue: formats},
    {provide: MAT_DATE_LOCALE, useValue: 'pt-br'}
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    ChartoscComponent,
    EdituserComponent,
    AddgroupsComponent,
    ForgetpassComponent,
    SessionexpiredalertComponent,
    EmailserviceComponent
  ]
})
export class AppModule { }
