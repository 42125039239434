import { Component, OnInit, ViewChild } from '@angular/core';
import { ServiceService } from './../service.service';
import * as moment from 'moment';
import {MatDialog} from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';

@Component({
  selector: 'app-historysystemoperation',
  templateUrl: './historysystemoperation.component.html',
  styleUrls: ['./historysystemoperation.component.css']
})
export class HistorysystemoperationComponent implements OnInit {

  constructor(
    public service: ServiceService
  ) { }

  displayedColumnsUsers = ['nome', 'description', 'dateoperation'];
  dataSource: MatTableDataSource<listoperation>;

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  listoperationtable: listoperation[] = [];
  listDicOperations = [];
  listDicOperationsExcel = [];
  loading = false;
  selectOperation;
  initialdate = '';
  finaldate = '';

  ngOnInit(): void {
    this.service.sessionExpired();
    this.listOperations();
    this.service.post_DictionaryOperations().subscribe((data: any) => {
      this.listDicOperations = data;
    });
  }

  listOperations() {
    this.loading = true;
    this.service.post_HistorySystemOperation().subscribe((data: any) => {
      for (let i = 0; i < data.length; i++) {
        this.listoperationtable.push(
          {
            'nome': data[i]['nome'],
            'description': data[i]['description'],
            'dateoperation': moment(data[i]['dateoperation']).format('DD/MM/YYYY HH:mm:SS'),
            'status': true,
            'id': data[i]['id']
          }
        );

        this.listDicOperationsExcel.push(
          {
            'Usuário': data[i]['nome'],
            'Operação': data[i]['description'],
            'Data da operação': moment(data[i]['dateoperation']).format('DD/MM/YYYY HH:mm:SS'),
          }
        );
        if (i == data.length - 1) {
          this.dataSource = new MatTableDataSource(this.listoperationtable);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
          this.loading = false;
        }
      }
    });
  }

  filterOperation(event) {
    let listfilter = [];
    this.listDicOperationsExcel = [];
    for (let i = 0; i < this.listoperationtable.length; i++) {
      if (this.listoperationtable[i]['id'] == this.selectOperation) {
        listfilter.push(
          {
            'nome': this.listoperationtable[i]['nome'],
            'description': this.listoperationtable[i]['description'],
            'dateoperation': moment(this.listoperationtable[i]['dateoperation']).format('MM/DD/YYYY HH:mm:SS'),
            'status': true,
            'id': this.listoperationtable[i]['id']
          }
        );

        this.listDicOperationsExcel.push(
          {
            'Usuário': this.listoperationtable[i]['nome'],
            'Operação': this.listoperationtable[i]['description'],
            'Data da operação': moment(this.listoperationtable[i]['dateoperation']).format('MM/DD/YYYY HH:mm:SS')
          }
        );
      }
    }
    this.dataSource = new MatTableDataSource(listfilter);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.loading = false;
  }

  filterByDate(event) {

    let listfilter = [];
    this.listDicOperationsExcel = [];
    let from_date = moment(this.initialdate).format('DD/MM/YYYY HH:mm:SS');
    let to_date = moment(this.finaldate).format('DD/MM/YYYY HH:mm:SS');


    this.listoperationtable.forEach((currentValue, index) => {
      // console.log(currentValue.dateoperation)
      //console.log(from_date)
      if (currentValue.dateoperation > from_date && currentValue.dateoperation < to_date) {
        listfilter.push(
          {
            'nome': currentValue.nome,
            'description': currentValue.description,
            'dateoperation': moment(currentValue.dateoperation).format('MM/DD/YYYY HH:mm:SS'),
            'status': true,
            'id': currentValue.id
          }
        );
        this.listDicOperationsExcel.push(
          {
            'Usuário': currentValue.nome,
            'Operação': currentValue.description,
            'Data da operação': moment(currentValue.dateoperation).format('MM/DD/YYYY HH:mm:SS')
          }
        );
      }
    });

    this.dataSource = new MatTableDataSource(listfilter);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.loading = false;
  }

  clearFilter() {
    this.initialdate = '';
    this.finaldate = '';
    this.selectOperation = '';
    this.dataSource = new MatTableDataSource(this.listoperationtable);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.loading = false;
  }

  ExportExcel() {
    this.service.exportAsExcelFile(this.listDicOperationsExcel, 'Historico Operações no Sistema', 'Historico Operações no Sistema');
  }

  refresh() {
    this.listoperationtable = [];
    this.listDicOperationsExcel = [];
    this.listOperations();
  }
}

export interface listoperation {
  nome: string;
  description: string;
  dateoperation: string;
  status: boolean;
  id: number;
}
