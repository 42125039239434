import { Component, OnInit } from '@angular/core';
import {FormControl} from '@angular/forms';
import { ServiceService } from './../service.service';
import { sha256 } from 'js-sha256';
import * as moment from 'moment';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import {MatDialog} from '@angular/material/dialog';
import { Subject } from 'rxjs';
@Component({
  selector: 'app-adduser',
  templateUrl: './adduser.component.html',
  styleUrls: ['./adduser.component.css']
})
export class AdduserComponent implements OnInit {

  constructor(
    public service: ServiceService,
    public snackBar: MatSnackBar,
    public router: Router,
    public dialog: MatDialog
  ) { }

  username = '';
  fullname = '';
  email = '';
  isadmin = false;
  changePass = false;
  warning = false;
  adduserOk = false;
  validateuser = false;
  warningmessage = '';
  listaGruposCombobox = [];
  selectedoptions;
  comboboxMultGrupos = new FormControl();
  warningDeleteUser = false;
  listRandompass = [];
  randompass = '';
  datecreateuser = new Date();
  dateToday = new Date();
  getOscByEmail;
  getNetworkFailByEmail;
  changeOscCheck = false;
  changeNetworkCheck = false;
  public scoreSubject = new Subject<boolean>();
  
  ngOnInit(): void {
    this.service.sessionExpired();
    this.listGroups();
  }

  listGroups() {
    this.service.post_ListGroups().subscribe((data: any) => {
      for (let i = 0; i < data.length; i++) {
        this.listaGruposCombobox.push(
          {
            'id': data[i]['id'],
            'groupname': data[i]['nome']
          }
        );
      }
    });
  }

  addUser() {
    this.generateRandomPass();

    if (this.username == '' || this.fullname == '' || this.email == '') {
      this.warning = true;
      this.warningmessage = 'Preencha todos os campos';
    } else {
      this.validateuser = true;
      this.randompass = this.listRandompass[0]['randompass'];
      this.service.post_AddUser(this.username, this.fullname, this.email, this.isadmin,
        this.listRandompass[0]['randompass'], this.listRandompass[0]['cededrandompass'],
        moment(this.datecreateuser).local().format('YYYY-MM-DD HH:mm:SS'), this.changeOscCheck
        , this.changeNetworkCheck).subscribe((data: any) => {
        if (data) {
          this.addGroupByUser(data[0]['id'], this.comboboxMultGrupos.value);
        }
      });
    }
  }

  checkExistUser(username, email) {
    this.service.post_CheckExistUser(username, email).subscribe((data: any) => {
      if (data[0]['quant'] == 1) {
        this.snackBar.open('Usuário já existe no sistema.', '', {
          duration: 5000,
          verticalPosition: 'top',
          horizontalPosition : 'center',
          panelClass: ['snackbarError']
        });
      } else {
        this.addUser();
      }
    });
  }

  addGroupByUser(idUser, group) {
    this.service.setrefresh = true;
    for (let i = 0; i < group.length; i++) {
      this.service.post_AddUserByGroups(idUser, group[i]).subscribe((data: any) => {
        if (i == group.length - 1) {
          this.validateuser = false;
          this.warning = true;
          this.adduserOk = true;
          this.warningmessage = 'Usuário cadastrado com sucesso. Código de acesso: ' + this.randompass;
          this.service.stoprefresh = true;
          this.scoreSubject.next(this.service.stoprefresh); //emit the scores result
          return this.service.stoprefresh;
        }
      });
    }
    this.service.post_AddToOperationHistory(this.service.idUser, 1)
      .subscribe((data: any) => {
        console.log(data);
    });
  }
  changeStatusOscEmail(event) {
    if (event.checked) {
      this.changeOscCheck = true;
    } else {
      this.changeOscCheck = false;
    }
  }

  changeStatusNetworkEmail(event) {
    if (event.checked) {
      this.changeNetworkCheck = true;
    } else {
      this.changeNetworkCheck = false;
    }
  }

  generateRandomPass() {

    let randompass = '';
    let cededrandompass = '';

    randompass = Math.random().toString(36).slice(-6);
    cededrandompass = sha256(randompass);

    this.listRandompass.push(
      {
        'randompass': randompass,
        'cededrandompass': cededrandompass
      }
    );
    return this.listRandompass;
  }
}
