<div class="mainDiv">
  <h2 class="example-h2">Administração de usuários</h2>
    <div class="example-container mat-elevation-z8" max-height=1000>
      <mat-tab-group class="demo-tab-group" >
        <mat-tab label="Tab 1">
          <ng-template mat-tab-label>
            <mat-icon style="display: inline-flex; vertical-align: middle;padding-bottom: 4px; padding-right: 6px;">account_box</mat-icon>
            <p style="display: inline-flex;">
              Usuários
            </p>
          </ng-template>
          <div class="demo-tab-content">
            <app-tableusers></app-tableusers>
          </div>
        </mat-tab>
        <mat-tab label="Tab 2">
          <ng-template mat-tab-label>
            <mat-icon style="display: inline-flex; vertical-align: middle;padding-bottom: 4px; padding-right: 6px;">group</mat-icon>
            <p style="display: inline-flex;">
              Grupos
            </p>
          </ng-template>
          <div class="demo-tab-content">
            <app-tablegroups></app-tablegroups>
          </div>
        </mat-tab>
      </mat-tab-group>
    </div>
</div>
