import { EdituserComponent } from './edituser/edituser.component';
import { EmailserviceComponent } from './emailservice/emailservice.component';
import { ServiceService } from './service.service';
import { Component, ViewChild, ChangeDetectorRef } from '@angular/core';
import {MatSidenav} from '@angular/material/sidenav';
import {MatDialog} from '@angular/material/dialog';
import { MediaMatcher } from '@angular/cdk/layout';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'taesaosc';
  @ViewChild('sidenav') sidenav: MatSidenav;
  mobileQuery: MediaQueryList;
  private _mobileQueryListener: () => void;

  listNetworkStatus = [];

  constructor(
    public service: ServiceService,
    public dialog: MatDialog,
    public router: Router,
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    ) { }


    mostraDetalhesUser(): void{
      let dialogRef = this.dialog.open(EdituserComponent, {
        width: '500px',
        data: { username: this.service.username, iduser: this.service.idUser, fullname: this.service.fullname,
          email: this.service.email, groups: [], type: 0 }
      });

      dialogRef.afterClosed().subscribe(result => {
        if (typeof (result) !== 'undefined') {
        }
      });

    }

    mostraEditEmailService(): void{
      let dialogRef = this.dialog.open(EmailserviceComponent, {
        width: '500px',
        data: {}
      });

      dialogRef.afterClosed().subscribe(result => {
        if (typeof (result) !== 'undefined') {
        }
      });

    }
    
    limpaLogin(){
      localStorage.clear();
      sessionStorage.clear();
      this.service.isLoged = false;
      location.reload();
    }

    goRouter(type) {
      if (type == 0) {
        this.router.navigateByUrl('/listoscilography', { skipLocationChange: true });
      } else if (type == 1) {
        this.router.navigateByUrl('/listusers', { skipLocationChange: true });
      } else if (type == 2) {
        this.router.navigateByUrl('/historysystemoperation', { skipLocationChange: true });
        /* this.router.navigateByUrl('/historygeneral', { skipLocationChange: true }); */
      } else if (type == 3) {
        this.router.navigateByUrl('/historyalarmevent', { skipLocationChange: true });
      } else if (type == 4) {
        this.router.navigateByUrl('/historyalnetwork', { skipLocationChange: true });
      } else {
        this.router.navigateByUrl('/editemailservice', { skipLocationChange: true });
      }
    }
}


