
<div class="grid-container">
  <h1 mat-dialog-title>
    <mat-icon style="display: inline-flex; vertical-align: middle;padding-bottom: 4px; padding-right: 6px;">person_add</mat-icon> Adicionar novo usuário
  </h1>
<mat-card>
  <mat-card-content>
    <table style="width: 100%;">
      <tr *ngIf="this.validateuser">
        <td style="border-bottom: 1px solid #E0E0E0;padding-bottom: 10px;">
          <mat-spinner  [diameter]="20" style="margin: auto"></mat-spinner> <b>Adicionando usuário</b>
        </td>
      </tr>
      <tr *ngIf="warning">
        <td style="border-bottom: 1px solid #E0E0E0;padding-bottom: 10px;">
          <b [ngClass]="{warningOn: warning == true, warningOff: this.adduserOk == true}">
            {{this.warningmessage}}
          </b>
        </td>
      </tr>
      <tr>
        <td style="text-align: left;">
          <mat-checkbox [(ngModel)]="isadmin">Administrador</mat-checkbox>
        </td>
      </tr>
      <tr>
        <td style="text-align: left;padding-top: 10px;">
          <mat-checkbox [(ngModel)]="getOscByEmail" (change)="changeStatusOscEmail($event)">Receber oscilografia por e-mail</mat-checkbox>
        </td>
      </tr>
      <tr>
        <td style="text-align: left;padding-top: 10px;">
          <mat-checkbox [(ngModel)]="getNetworkFailByEmail" (change)="changeStatusNetworkEmail($event)">Receber falha de comunicação por e-mail</mat-checkbox>
        </td>
      </tr>
      <tr>
        <mat-form-field class="full-width-input">
          <input class="inputcolor" matInput placeholder="Matrícula" [(ngModel)]="username" name="username">
        </mat-form-field>
      </tr>
      <tr>
        <mat-form-field class="full-width-input">
          <input class="inputcolor" matInput  placeholder="Nome completo" [(ngModel)]="fullname" name="fullname">
        </mat-form-field>
      </tr>
      <tr>
        <mat-form-field class="full-width-input">
          <input class="inputcolor" matInput  placeholder="E-mail" [(ngModel)]="email" name="email">
        </mat-form-field>
      </tr>
      <tr>
        <td>
          <mat-form-field class="full-width-input">
            <mat-select placeholder="Grupos" [(formControl)]="comboboxMultGrupos" multiple>
              <mat-option *ngFor="let grupo of listaGruposCombobox" [value]="grupo.id">{{grupo.groupname}}</mat-option>
            </mat-select>
          </mat-form-field>
        </td>
      </tr>
    </table>

    <!--<button mat-raised-button color="primary" type="submit" (click)="addUser()">Adicionar</button>-->
    <button mat-raised-button color="primary" type="submit" (click)="checkExistUser(this.username, this.email)">Adicionar</button>

  </mat-card-content>
</mat-card>
</div>


