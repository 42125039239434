import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { ServiceService } from './../service.service';
import * as moment from 'moment';
import {MatDialog} from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MediaMatcher } from '@angular/cdk/layout';

@Component({
  selector: 'app-historyalarmsevents',
  templateUrl: './historyalarmsevents.component.html',
  styleUrls: ['./historyalarmsevents.component.css']
})
export class HistoryalarmseventsComponent implements OnInit {

  screenSize = 1;
  constructor(
    public service: ServiceService,
    changeDetectorRef: ChangeDetectorRef, 
    media: MediaMatcher
  ) { 

    this.mdq = media.matchMedia('(max-width: 1600px)');

    this.mediaQueryListener = () => {
      changeDetectorRef.detectChanges();

      if (this.mdq.matches) {
        this.screenSize = 1;
      } else {
        this.screenSize = 3;
      }
     } 
     this.mdq.addListener(this.mediaQueryListener);
  }

  mdq: MediaQueryList;
  mediaQueryListener:()=>void;

  displayedColumnsUsers = ['InTimeAlm', 'OutTimeAlm', 'plant', 'Message'];
  dataSource: MatTableDataSource<listhistory>;

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  listhistorytable: listhistory[] = [];
  listDicOperations = [];
  listPlant = [];
  listplantexcel = [];
  loading = false;
  selectOperation;
  initialdate = '';
  finaldate = '';
  selectConcessao;

  ngOnInit(): void {
    this.service.sessionExpired();
    this.listHistory();
    this.listPlants();
  }

  listHistory() {
    this.loading = true;

    this.service.get_histalarmevents().subscribe((data: any) => {
      for (let i = 0; i < data.length; i++) {
        this.listplantexcel.push(
          {
            'Entrada do alarme': data[i]['InTimeAlm'],
            'Saída do alarme': data[i]['OutTimeAlm'],
            'Alarme': data[i]['Message']
          }
        )
      }
      this.listhistorytable = data;
        this.dataSource = new MatTableDataSource(this.listhistorytable);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.loading = false;
    });
  }

  listPlants() {
    this.service.post_ListFiltersByPlant(this.service.idUser).subscribe((data: any) => {
      for (let i = 0; i < data.length; i++) {
        this.listPlant.push(
          {
            'id': data[i]['id'],
            'plantname': data[i]['valor']
          }
        )
      }
    });
  }

  filterByDate(event) {
    let listfilter = [];
    this.listplantexcel = [];
    let from_date = moment(this.initialdate).format('YYYY-MM-DD HH:mm:SS');
    let to_date = moment(this.finaldate).format('YYYY-MM-DD HH:mm:SS');
    this.listhistorytable.forEach((currentValue, index) => {
      if (moment(currentValue.InTimeAlm).format('YYYY-DD-MM HH:mm:SS') > from_date && moment(currentValue.InTimeAlm).format('YYYY-DD-MM HH:mm:SS') < to_date) {
        listfilter.push(
          {
            'InTimeAlm': moment(currentValue.InTimeAlm).format('MM/DD/YYYY HH:mm:SS'),
            'OutTimeAlm': moment(currentValue.OutTimeAlm).format('MM/DD/YYYY HH:mm:SS'),
            'plant': currentValue.plant,
            'Message': currentValue.Message
          }
        );

        this.listplantexcel.push(
          {
            'Entrada do alarme': moment(currentValue.InTimeAlm).format('MM/DD/YYYY HH:mm:SS'),
            'Saída do alarme': moment(currentValue.OutTimeAlm).format('MM/DD/YYYY HH:mm:SS'),
            'Alarme': currentValue.Message
          }
        )
      }
    });
    this.dataSource = new MatTableDataSource(listfilter);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.loading = false;
  }

  filterByPlant(event) {
    let listfilter = [];
    this.listplantexcel = [];
    this.loading = true;
    this.service.post_histalarmeventsbyfilter(event.value).subscribe((data: any) => {
      for (let i = 0; i < data.length; i++) {
        listfilter.push(
          {
            'InTimeAlm': data[i]['InTimeAlm'],
            'OutTimeAlm': data[i]['OutTimeAlm'],
            'plant': event.value,
            'Message': data[i]['Message']
          }
        );

        this.listplantexcel.push(
          {
            'Entrada do alarme': data[i]['InTimeAlm'],
            'Saída do alarme': data[i]['OutTimeAlm'],
            'Alarme': data[i]['Message']
          }
        )
      }

      this.dataSource = new MatTableDataSource(listfilter);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.loading = false;
    });
  }

  clearFilter() {
    this.initialdate = '';
    this.finaldate = '';
    this.selectOperation = '';
    this.dataSource = new MatTableDataSource(this.listhistorytable);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.loading = false;
  }

  ExportExcel() {
    this.service.exportAsExcelFile(this.listplantexcel, 'Historico de Alarmes', 'Historico de Alarmes');
  }

  refresh() {
    this.listhistorytable = [];
    this.listplantexcel = [];
    this.listHistory();
  }

}

export interface listhistory {
  InTimeAlm: string;
  OutTimeAlm: string;
  plant: string;
  Message: boolean;
}
