
<div style="border-bottom: 1px solid #E0E0E0;width:100%">
  <!--<button style="margin: 5px;" mat-button (click)="openDialog(0, '', '', '', '')">
    <mat-icon style="padding: 3px">person_add</mat-icon> Novo usuário
  </button>

  <div style="width:100%;text-align:right" *ngIf="loading">
    Deletando usuário <img src="./../../assets/loading.gif">
  </div>-->

  <table style="width:100%">
    <tr>
      <td style="width:95%">
        <button style="margin: 5px;" mat-button (click)="openDialog(0, '', '', '', '')">
          <mat-icon style="padding: 3px">person_add</mat-icon> Novo usuário
        </button>
      </td>
      <td style="width:5%">
        <button style="margin: 5px;" mat-button (click)="refresh()">
          <mat-icon style="padding: 3px;cursor:pointer;" matTooltip="Atualizar página" matTooltipPosition="above">refresh</mat-icon>
        </button>
      </td>
    </tr>
  </table>
</div>

<div class="mainDiv">
  <table mat-table [dataSource]="dataSource" matSort style="width: 100%;">

    <ng-container matColumnDef="username">
      <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 10%;"> Usuário </th>
      <td mat-cell *matCellDef="let row" class="tbaleTDStatus" style="width: 10%;"> {{row.username}} </td>
    </ng-container>

    <ng-container matColumnDef="nome">
      <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 10%;"> Nome completo </th>
      <td mat-cell *matCellDef="let row" class="tbaleTDStatus" style="width: 10%;"> {{row.nome}} </td>
    </ng-container>

    <ng-container matColumnDef="email">
      <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 15%;"> E-mail </th>
      <td mat-cell *matCellDef="let row" class="tbaleTDStatus" style="width: 15%;"> {{row.email}} </td>
    </ng-container>

    <ng-container matColumnDef="isAdmin">
      <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 5%;"> Administrador </th>
      <td mat-cell *matCellDef="let row" class="tbaleTDStatus" style="width: 5%;"> {{row.isAdmin}} </td>
    </ng-container>

    <ng-container matColumnDef="lastlogin">
      <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 12%;"> Último acesso </th>
      <td mat-cell *matCellDef="let row" class="tbaleTDStatus" style="width: 12%;"> {{row.lastlogin}} </td>
    </ng-container>

    <ng-container matColumnDef="lastchangepass">
      <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 12%;"> Última troca de senha </th>
      <td mat-cell *matCellDef="let row" class="tbaleTDStatus" style="width: 12%;"> {{row.lastchangepass}} </td>
    </ng-container>

    <ng-container matColumnDef="edit">
      <th mat-header-cell *matHeaderCellDef style="width: 5%;"> </th>
      <td mat-cell *matCellDef="let row" class="tbaleTDStatus" style="width: 5%;">
        <button style="margin: 5px;" mat-button (click)="openDialog(1, row.username, row.iduser, row.nome, row.email)">
          <mat-icon style="cursor: pointer;" matTooltip="Editar usuário" matTooltipPosition="above">edit</mat-icon>
        </button>
      </td>
    </ng-container>

    <ng-container matColumnDef="delete">
      <th mat-header-cell *matHeaderCellDef style="width: 5%;"> </th>
      <td mat-cell *matCellDef="let row" class="tbaleTDStatus" style="width: 5%;">
        <button style="margin: 5px;" mat-button (click)="blockUser(row.iduser, row.status)">
          <mat-icon *ngIf="row.status" style="cursor: pointer;" matTooltip="Bloquear usuário" matTooltipPosition="above">lock_open</mat-icon>
          <mat-icon *ngIf="!row.status" style="cursor: pointer;" matTooltip="Desbloquear usuário" matTooltipPosition="above">lock</mat-icon>
        </button>
      </td>
    </ng-container>

    <ng-container matColumnDef="iduser">
      <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 5%;"></th>
      <td mat-cell *matCellDef="let row" class="tbaleTDStatus" style="width: 5%;"> 
        <button style="margin: 5px;" mat-button (click)="deleteUser(row.iduser)">
          <mat-icon style="cursor: pointer;" matTooltip="Deletar Usúario" matTooltipPosition="above">delete</mat-icon>
        </button> 
      </td>
    </ng-container>

    <ng-container matColumnDef="status" style="display: none;">
      <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 5%;" style="display: none;"></th>
      <td mat-cell *matCellDef="let row" class="tbaleTDStatus" style="width: 5%;" style="display: none;"> {{row.status}} </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumnsUsers"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumnsUsers;"></tr>

  </table>
</div>

<div class="footer">
  <mat-paginator [length]="10" [pageSize]="10" [pageSizeOptions]="[5, 10, 15, 100, 200, 500]"></mat-paginator>
</div>
