<div style="border-bottom: 1px solid #E0E0E0;">
  <button style="margin: 5px;" mat-button (click)="openDialog()">
    <mat-icon style="padding: 3px">group_add</mat-icon> Novo grupo
  </button>
</div>

<div>
  <table mat-table [dataSource]="dataSource" matSort style="width: 100%;">

    <ng-container matColumnDef="nome">
      <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 15%;"> Grupo </th>
      <td mat-cell *matCellDef="let row" class="tbaleTDStatus" style="width: 15%;"> {{row.nome}} </td>
    </ng-container>

    <ng-container matColumnDef="tensao">
      <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 15%;"> Tensão </th>
      <td mat-cell *matCellDef="let row" class="tbaleTDStatus" style="width: 15%;"> {{row.tensao}} </td>
    </ng-container>

    <ng-container matColumnDef="complinha">
      <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 15%;"> Comprimento da LT </th>
      <td mat-cell *matCellDef="let row" class="tbaleTDStatus" style="width: 15%;"> {{row.complinha}} </td>
    </ng-container>

    <ng-container matColumnDef="categoria">
      <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 15%;"> Categoria </th>
      <td mat-cell *matCellDef="let row" class="tbaleTDStatus" style="width: 15%;"> {{row.categoria}} </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumnsGroups"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumnsGroups;"></tr>

  </table>
</div>
