<div *ngIf="loading" style="width: 100%;">
  <table style="width: 100%;">
    <tr>
      <td style="width: 90%;color:#1D2C67">
        <b>Enviando e-mail, aguarde.</b>
      </td>
      <td>
        <img src="./../../assets/loading.gif">
      </td>
    </tr>
  </table>
</div>
<!-- <div *ngIf="loading" style="margin-left: 300px;">
  <img src="./../../assets/loading.gif">
</div> -->
<div class="grid-container">
  <mat-card>
    <mat-card-content>
      <table style="width: 100%;">
        <tr *ngIf="warning">
          <td style="border-bottom: 1px solid #E0E0E0;padding-bottom: 10px;">
            <b [ngClass]="{warningOn: !this.warningok, warningOff: this.warningok}">
              {{this.warningmessage}}
            </b>
          </td>
        </tr>
        <tr>
          <mat-form-field class="full-width-input">
            <input matInput placeholder="E-mail cadastrado no sistema" [(ngModel)]="email" name="email" style="color:#969696;">
          </mat-form-field>
        </tr>
      </table>
      <button mat-raised-button color="primary" type="submit" (click)="alterPass()">Enviar</button>
    </mat-card-content>
  </mat-card>
</div>


