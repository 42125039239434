import { Component, OnInit, ChangeDetectorRef  } from '@angular/core';
import { ServiceService } from './../service.service';
import { sha256 } from 'js-sha256';
import { Router } from '@angular/router';
import {MatDialog} from '@angular/material/dialog';
import { NewpassComponent } from './../newpass/newpass.component';
import { ForgetpassComponent } from './../forgetpass/forgetpass.component';
import * as moment from 'moment';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  constructor(
    public service: ServiceService,
    public router: Router,
    public dialog: MatDialog,
    private cdr:ChangeDetectorRef) { }

  hideTab1: Boolean = true;
  hideTab2: Boolean = false;

  PermanecerLogado = false;
  username = '';
  password = '';
  warning = '';
  flagwarning = false;
  carregando = false;
  counterWrongPass: number = 0;
  //dateNow = new Date();
  loading = false;
  blockButton = false;
  showUsername = false;
  fullname = '';
  lastlogin = moment(Date()).format('YYYY-MM-DD HH:mm:SS');

  ngOnInit(): void {
    this.service.mostraPagina = true;
  }

  routeToPage(isadmin, iduser, username) {
    if (isadmin) {
      this.service.isAdmin = true;
    }
    this.service.idUser = iduser;
    this.service.user = username;
    this.service.mostraPagina = false;
    this.carregando = false;
    this.service.isLoged = true;
    this.router.navigateByUrl('/listoscilography', { skipLocationChange: true });
  }

  compareDate(dateIni, dateFin) {
    const date1 = moment(dateIni).format('YYYY-MM-DD HH:mm');
    const date2 = moment(dateFin).format('YYYY-MM-DD HH:mm');
    const startTime = new Date(date2);
    const endTime = new Date(date1);
    const difference = endTime.getTime() - startTime.getTime();
    const resultInMinutes = Math.round(difference / 60000);

    console.log(date1)
    console.log(date2)

    console.log(resultInMinutes)

    return resultInMinutes;
  }

  openDialogNewPass() {
    let dialogRef;

    dialogRef = this.dialog.open(ForgetpassComponent, {
      width: '400px'
    });
  }

  showTab(tab) {
    let dateNow = new Date();
    if (tab == 0) {
      if (this.username == '') {
        this.flagwarning = true;
        this.warning = 'Digite sua matrícula.';
      } else {
        this.flagwarning = false;
        this.loading = true;
        this.service.post_VerifyUser(this.username).subscribe((data: any) => {
          if (data.length > 0) {
            if (!data[0]['inproduction']) {
              this.loading = false;
              let dialogRef;
              this.service.idUser = data[0]['id'];
              dialogRef = this.dialog.open(NewpassComponent, {
                width: '400px',
                data: { 'username': this.username, 'isadmin': data[0]['isAdmin'], 'changepass': data[0]['changepass'], 'type': 0}
              });
            } else if (!data[0]['status']) {
              if (this.compareDate(moment(dateNow).format('YYYY-MM-DDTHH:mm:ss'), data[0]['datetimelogin']) >= 15) {
                this.service.post_BlockUser(this.username, moment(dateNow).format('YYYY-MM-DD HH:mm:ss'), true).subscribe();
                this.service.idUser = data[0]['id'];
                this.loading = false;
                this.hideTab1 = false;
                this.hideTab2 = true;
                this.showUsername = true;
                this.fullname = data[0]['nome']
                this.service.fullname = this.fullname;
                this.service.username = this.username;
                this.blockButton = false;
              } else {
                this.flagwarning = true;
                this.carregando = false;
                this.blockButton = true;
                this.loading = false;
                this.warning = 'Usuário bloqueado.';
              }
            } else {
              this.service.idUser = data[0]['id'];
              if (this.compareDate(moment(dateNow).format('YYYY-MM-DDTHH:mm:ss'), data[0]['lastchangepass']) >= 131400) {
                let dialogRef;
                dialogRef = this.dialog.open(NewpassComponent, {
                  width: '400px',
                  data: { 'username': this.username, 'isadmin': data[0]['isAdmin'], 'changepass': true, 'type': 1}
                });
              } else {
                this.service.idUser = data[0]['id'];
                this.loading = false;
                this.hideTab1 = false;
                this.hideTab2 = true;
                this.showUsername = true;
                this.fullname = data[0]['nome']
                this.service.fullname = this.fullname;
                this.service.username = this.username;
              }
            }
          } else {
            this.loading = false;
            this.hideTab1 = false;
            this.hideTab2 = true;
            this.showUsername = true;
            this.fullname = this.username;
            this.service.user = this.fullname;
            this.service.username = this.username;
          }
        });
      }
    } else if (tab == 1) {
      this.loading = true;
      this.service.post_VerifyPass(sha256(this.password)).subscribe((data: any) => {
        if (data.length > 0) {
          if (!data[0]['inproduction']) {
            let dialogRef;
            this.service.idUser = data[0]['id'];
            dialogRef = this.dialog.open(NewpassComponent, {
              width: '400px',
              data: { 'username': this.username, 'isadmin': data[0]['isAdmin']}
            });
          } else {
            if (data[0]['status'] == false) {
              if (this.compareDate(moment(dateNow).format('YYYY-MM-DDTHH:mm:ss'), data[0]['datetimelogin']) >= 15) {
                this.service.post_BlockUser(this.username, moment(dateNow).format('YYYY-MM-DD HH:mm:ss'), true).subscribe();
                this.routeToPage(data[0]['isAdmin'], data[0]['id'], data[0]['nome']);
              } else {
                this.flagwarning = true;
                this.carregando = false;
                this.blockButton = true;
                this.loading = false;
                this.warning = 'Usuário bloqueado.';
              }
            } else {
              this.fullname = data[0]['nome'];
              this.service.fullname = this.fullname;
              this.service.username = this.username;
              this.service.email = data[0]['email'];
              this.service.post_UpdateLastLogin(this.username, this.fullname, this.lastlogin).subscribe();
              this.service.post_AddToOperationHistory(this.service.idUser, 7).subscribe();
              this.routeToPage(data[0]['isAdmin'], data[0]['id'], data[0]['nome']);
              this.service.counterexpiredsession();
            }
          }
        } else {
          this.counterWrongPass = this.counterWrongPass + 1;
          this.loading = false;
          if (this.counterWrongPass == 5) {
            this.flagwarning = true;
            this.warning = 'Número máximo de tentativas atingido.';
            this.carregando = false;
            this.blockButton = true;
            this.service.post_BlockUser(this.username, moment(dateNow).format('YYYY-MM-DD HH:mm:ss'), false).subscribe();
            this.service.post_AddToOperationHistory(this.service.idUser, 9).subscribe();
          } else {
            this.flagwarning = true;
            this.warning = 'Senha incorreta.';
            this.carregando = false;
          }
        }
      });
    } else {
      this.hideTab1 = true;
      this.hideTab2 = false;
      this.loading = false;
    }
  }
}

