import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { ServiceService } from './../service.service';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { FormControl } from '@angular/forms';
import {MatAccordion} from '@angular/material/expansion';
import * as moment from 'moment';
import * as echarts from 'echarts';
/* import * as jspdf from 'jspdf'; */
import html2canvas from 'html2canvas';
import { jsPDF } from "jspdf";
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';

import {
  ChartComponent,
  ApexAxisChartSeries,
  ApexChart,
  ApexPlotOptions,
  ApexXAxis,
  ApexDataLabels,
  ApexStroke,
  ApexGrid,
  ApexMarkers,
  ApexTitleSubtitle,
  ApexYAxis,
  ApexLegend
} from 'ng-apexcharts';

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  xaxis: ApexXAxis;
  plotOptions: ApexPlotOptions;
  colors: string[];
  dataLabels: ApexDataLabels;
  stroke: ApexStroke;
  grid: ApexGrid;
  markers: ApexMarkers;
  title: ApexTitleSubtitle;
  yaxis: ApexYAxis;
  legend: ApexLegend;
};


export interface DialogData {
  concessao: string;
  subestacao: string;
  equipamento: string;
  arquivo: string;
}

@Component({
  selector: 'app-chartosc',
  templateUrl: './chartosc.component.html',
  styleUrls: ['./chartosc.component.css']
})
export class ChartoscComponent implements OnInit {

  @ViewChild("chartline") chartline: ChartComponent;
  @ViewChild("chart") chart: ChartComponent;
  @ViewChild("chartNotOn") chartNotOn: ChartComponent;

  public chartLineOptions: Partial<ChartOptions>;
  public chartOptions: Partial<ChartOptions>;
  public chartOptionsNotOn: Partial<ChartOptions>;

  constructor(
    public service: ServiceService,
    @Inject(MAT_DIALOG_DATA) public datadialog: DialogData,
    public dialogRef: MatDialogRef<ChartoscComponent>
  ) { }

  @ViewChild(MatAccordion) accordion: MatAccordion;

  toppings = new FormControl();
  toppingList: string[] = [];
  chartosc = [];
  lineChart: any;
  options: any = {};
  optionschartbyvariable: any = {};
  chartListTimestamp = [];
  chartVariablesName = [];
  chartVariablesNameByTag = [];
  chartData = [];
  loading = false;
  triggertime = 0;
  chartprincipalflag = false;
  chartbyvariableflag = false;
  selectctedVariables = [];
  listteste = [];
  selectedlegends: any =  {};
  selectedlegendsfinal: any =  {};
  listselectedlegend = [];
  listselectedlegendDigital = [];
  listselectedDatadDigital = [];
  showFlagError = false;
  infoOscNameSubest = '';
  infoOscFrequency = '';
  infoOscStartTime = '';
  infoOscComments = '';
  optionsdigital: any = {};
  listvalueschartdigital = [];
  listafinal = [];
  showDigitalChart = false;
  moreInfo = '';
  listDigitalPoints = [];
  sizeChartDigitalNoData = 800;
  loadingPdf = false;

  ngOnInit(): void {
    this.service.sessionExpired();
    this.chartprincipalflag = true;
    this.loading = true;
    this.getFilesFromFolder();
  }

  getFilesFromFolder() {
    this.service.post_addFilesToChart(this.datadialog.concessao, this.datadialog.subestacao,
      this.datadialog.equipamento, this.datadialog.arquivo).subscribe((data: any) => {
      if (data) {
        this.saveFiles();
      }
    });
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.chartVariablesName, event.previousIndex, event.currentIndex);
  }

  saveFiles() {
    this.service.post_downloadFileToChart({'info': 'ok'}).subscribe((data: any) => {
      if (data) {
        this.getVariablesName();
      }
    });
  }

  getVariablesName() {

    let indexanalog = 0;
    let digitalindex = 0;
    let duration = 0;
    let namevariabledigital = '';
    let typeBit;

    this.service.post_ChartOsc(this.datadialog.concessao, this.datadialog.subestacao,
      this.datadialog.equipamento, this.datadialog.arquivo).subscribe((data: any) => {
        if (data) {


          for (let i = 0; i < data[0]['analog'].length; i++) {
            this.listselectedlegend.push(data[0]['analog'][i]['legend']);
          }

          for (let i = 0; i < data[1]['digital'].length; i++) {
            this.listselectedlegendDigital.push(data[1]['digital'][i]['legend']);

            if (i == data[1]['digital'].length - 1) {

              this.moreInfo = data[0]['analog'][0]['coments'];
              this.triggertime = data[0]['analog'][0]['triggertime'];
              this.chartData = data[0]['analog'][0]['data'];
              this.infoOscNameSubest = data[0]['analog'][0]['namesubest'];
              this.infoOscFrequency = data[0]['analog'][0]['frequency'] + ' Hz';
              this.infoOscStartTime = moment(data[0]['analog'][0]['starttimestamp']).locale('pt-BR')
              .format('dddd, DD/MM/YY [às] HH:mm:ss').toString();
              this.infoOscComments = data[0]['analog'][0]['coments'];


              for (let i = 0; i < data[0]['analog'][0]['time'].length; i++) {
                this.chartListTimestamp.push(data[0]['analog'][0]['time'][i].toFixed(3));
              }

              // ================= PONTOS ANALOGICOS =======
              for (let i = 0; i < data[0]['analog'].length; i++) {

                this.chartVariablesName.push({
                  name:  data[0]['analog'][i]['legend'],
                  status: false,
                  listvariables: []
                });

                this.chartVariablesNameByTag.push({
                  name:  data[0]['analog'][i]['legend']
                });

                this.selectedTag(i);

                this.chartosc.push(
                  {
                    name:  data[0]['analog'][i]['legend'],
                    type: 'line',
                    connectNulls: false,
                    smooth: true,
                    hoverAnimation: false,
                    animation: false,
                    data:  data[0]['analog'][i]['data'],
                    markArea: {
                      itemStyle: {
                        normal: {
                            color: '#FF96A6',
                        }
                      }
                    }
                  }
                );

                this.createChartByVariable(this.chartosc, i);

                this.selectedlegendsfinal = {};

                if (i == data[0]['analog'].length - 1) {
                  this.toppingList = this.chartVariablesName;
                  this.createChart();
                }
              }

              // ================= PONTOS DIGITAIS =======
              for (let i = 0; i < data[1]['digital'].length; i++) {
                for (let v = 0; v < data[1]['digital'][i]['data'].length; v++) {
                  if (v == 0) {
                    indexanalog = data[1]['digital'][i]['data'][v];

                    if (indexanalog == 1) {
                      typeBit = 1;
                    } else {
                      typeBit = 0;
                    }
                  }

                  if (typeBit == 1) {
                    if (indexanalog == data[1]['digital'][i]['data'][v]) {
                      if (v == 0) {

                        this.listvalueschartdigital.push(
                          {
                            'date': data[0]['analog'][0]['time'][v],
                            'datefin': data[0]['analog'][0]['time'][data[1]['digital'][i]['data'].length - 1]
                          }
                        );

                        if (data[1]['digital'][i]['data'][v] == typeBit) {


                          this.listafinal.push(
                            {
                              'x': data[1]['digital'][i]['legend'],
                              'y': [this.listvalueschartdigital[0]['date'], this.listvalueschartdigital[0]['datefin']],
                              'fillColor': '#FEB019'
                            }
                          );
                          digitalindex = digitalindex + 1;
                          this.listvalueschartdigital = [];
                        }
                      }
                    }
                  } else {
                    if (indexanalog != data[1]['digital'][i]['data'][v]) {

                      this.listvalueschartdigital.push(
                        {
                          'date': data[0]['analog'][0]['time'][v]
                        }
                      );

                      if (data[1]['digital'][i]['data'][v] == typeBit) {

                        duration = parseFloat(this.listvalueschartdigital[1]['date']) - parseFloat(this.listvalueschartdigital[0]['date']);

                        this.listafinal.push(
                          {
                            'x': data[1]['digital'][i]['legend'],
                            'y': [this.listvalueschartdigital[0]['date'], this.listvalueschartdigital[1]['date']],
                            'fillColor': '#FEB019'
                          }
                        );
                        digitalindex = digitalindex + 1;
                        this.listvalueschartdigital = [];
                      }

                    }
                  }
                  indexanalog = data[1]['digital'][i]['data'][v];
                  namevariabledigital = data[1]['digital'][i]['legend'];
                }

                if (digitalindex == 0) {
                  this.listDigitalPoints.push(
                    {
                      'x': data[1]['digital'][i]['legend'],
                      'y': [0, 0],
                      'fillColor': '#FEB019'
                    }
                  );
                }

                digitalindex = 0;
                this.listselectedDatadDigital.push(data[1]['digital'][i]['data']);

                if (i == data[1]['digital'].length - 1) {
                  this.digitalChart();
                  this.digitalChartOff();
                  this.showDigitalChart = true;
                }
              }
            }
          }
        } else {
          this.showFlagError = true;
          this.loading = false;
        }
      });
  }

  closeDialog() {
    this.dialogRef.close(true);
  }

  changeTag(e) {
    this.selectctedVariables = [];
    if (e.source.selected.length > 0) {
      for (let i = 0; i< e.source.selected.length; i++) {
        this.selectctedVariables.push(e.source.selected[i].value);
      }
    } else {
      this.selectctedVariables = [];
    }
  }

  showChartByVariable() {
    this.loading = true;

    this.chartVariablesName.forEach((statusoff) => {
      statusoff.status = false;
    });

    if (this.selectctedVariables.length > 0) {
      for (let u = 0; u < this.selectctedVariables.length; u++) {
        for (let v = 0; v < this.chartVariablesName.length; v++) {
          if (this.selectctedVariables[u] == this.chartVariablesName[v]['name']) {
            this.chartVariablesName[v]['status'] = true;
          }
        }
        if (u == this.selectctedVariables.length - 1) {
          this.loading = false;
        }
      }
      this.chartprincipalflag = false;
    } else {
      this.chartprincipalflag = true;
      this.loading = false;
    }
  }

  changeTypeChart() {
    this.chartprincipalflag = true;
  }

  exportToExcel() {

    let arrayListExcel = [];

    for (let i = 0; i < this.chartosc.length; i++) {
      arrayListExcel.push(
        {
          [this.chartosc[i]['name']]: ''
        }
      );
    }
  }

  createChart() {
    this.options = {
      tooltip: {
        trigger: 'axis',
        backgroundColor: 'rgba(229, 230, 236, 1)',
        axisPointer: {
          type: 'cross',
          axis: 'auto',
          animation: false,
        },
        textStyle: {
          fontSize: 10,
        },
      },
      dataZoom: [
        {
          type: 'inside',
          show: true
        }
      ],
      toolbox: {
        show: true,
        orient: 'vertical',
          feature: {
            saveAsImage: {
              type: 'png',
              show: true,
              title: 'Download',
            },
            restore: {
              show: true,
              title: 'Restaurar'
            },
            dataZoom: {
              show: true,
              title: {
                zoom: 'Zoom',
                back: 'Restaurar zoom'
              },
            },
            magicType: {
              show: true,
              type: 'line'
            }
          },
      },
      legend: {
        data: this.chartVariablesName,
        orient: 'horizontal',
        align: 'left',
        right: 'auto',
        top: '50',
        backgroundColor: 'transparent',
        icon: 'roundRect',
        width: '1500',
        height: 'auto',
        type: 'scroll',
        textStyle: {
          color: '#969696'
        },
      },
      grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          top: '20%',
          containLabel: true
      },
      xAxis: {
          title: 't/s',
          type: 'category',
          boundaryGap: false,
          data: this.chartListTimestamp
      },
      yAxis: {
        type: "value",
        splitLine: {
          show: true,
          lineStyle: {
            color: '#383838'
          }
        }
      },
      textStyle: {
        color: '#969696'
      },
      series: this.chartosc
    };
    this.loading = false;
  }

  digitalChart() {
    this.chartOptions = {
      series: [
        {
          data: this.listafinal
        }
      ],
      chart: {
        height: 800,
        type: "rangeBar"
      },
      plotOptions: {
        bar: {
          horizontal: true
        }
      },
      xaxis: {
        type: "datetime",
        tooltip: {
          enabled: false
        }
      },
      legend: {
        position: 'top'
      },
    };
  }

  digitalChartOff() {

    if (this.listDigitalPoints.length <= 200) {
      this.sizeChartDigitalNoData = 800;
    } else {
      this.sizeChartDigitalNoData = 4000;
    }

    this.chartOptionsNotOn = {
      series: [
        {
          data: this.listDigitalPoints
        }
      ],
      chart: {
        height: this.sizeChartDigitalNoData,
        type: "rangeBar"
      },
      plotOptions: {
        bar: {
          horizontal: true
        }
      },
      xaxis: {
        type: "datetime"
      },
      legend: {
        position: 'top'
      },
    };
  }

  createChartByVariable(chartddata, index) {

    this.optionschartbyvariable = {
      tooltip: {
        trigger: 'axis',
        backgroundColor: 'rgba(229, 230, 236, 1)',
        axisPointer: {
          type: 'cross',
          animation: false,
        },
        textStyle: {
          fontSize: 10,
        },
      },
      dataZoom: [
        {
          type: 'inside',
          show: true
        }
      ],
      toolbox: {
        show: true,
        orient: 'vertical',
          feature: {
            saveAsImage: {
              type: 'png',
              show: true,
              title: 'Download',
            },
            restore: {
              show: true,
              title: 'Restaurar'
            },
            dataZoom: {
              show: true,
              title: {
                zoom: 'Zoom',
                back: 'Restaurar zoom'
              },
            },
            magicType: {
              show: true,
              type: 'line'
            }
          },
      },
      legend: {
        data: this.chartVariablesNameByTag,
        orient: 'horizontal',
        align: 'left',
        right: 'auto',
        backgroundColor: 'transparent',
        icon: 'roundRect',
        width: '1500',
        height: 'auto',
        type: 'scroll',
        textStyle: {
          color: '#969696'
        },
        selected: this.selectedlegendsfinal
      },
      grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          top: '20%',
          containLabel: true
      },
      xAxis: {
          type: 'category',
          boundaryGap: false,
          data: this.chartListTimestamp
      },
      yAxis: {
        type: "value",
        splitLine: {
          show: true,
          lineStyle: {
            color: '#383838'
          }
        }
      },
      textStyle: {
        color: '#969696'
      },
      series: chartddata
    };
    this.chartVariablesName[index]['listvariables'].push(this.optionschartbyvariable);
    this.loading = false;
  }

  selectedTag(index) {

    let a = false; let b = false; let c = false; let d = false; let e = false;
    let f = false; let g = false; let h = false; let i = false; let j = false;
    let k = false; let l = false; let m = false; let n = false; let o = false;
    let p = false; let q = false; let r = false; let s = false; let t = false;
    let u = false; let v = false; let x = false; let y = false; let z = false;
    let a1 = false; let a2 = false; let a3 = false; let a4 = false; let a5 = false;
    let a6 = false; let a7 = false; let a8 = false; let a9 = false; let a10 = false;
    let a11 = false; let a12 = false; let a13 = false; let a14 = false;
    let a15 = false; let a16 = false; let a17 = false; let a18 = false;
    let a19 = false; let a20 = false;

    if (index == 0) {
      a = true; b = false; c = false; d = false; e = false; f = false; g = false; h = false; i = false; j = false; k = false;
      l = false; m = false; n = false; o = false; p = false; q = false; r = false; s = false; t = false; u = false; v = false;
      x = false; y = false; z = false; a1 = false; a2 = false; a3 = false; a4 = false; a5 = false; a6 = false; a7 = false; a8 = false;
      a9 = false; a10 = false; a11 = false; a12 = false; a13 = false; a14 = false; a15 = false; a16 = false; a17 = false; a18 = false;
      a19 = false; a20 = false;
    } else if (index == 1) {
      a = false; b = true; c = false; d = false; e = false; f = false; g = false; h = false; i = false; j = false; k = false;
      l = false; m = false; n = false; o = false; p = false; q = false; r = false; s = false; t = false; u = false; v = false;
      x = false; y = false; z = false; a1 = false; a2 = false; a3 = false; a4 = false; a5 = false; a6 = false; a7 = false; a8 = false;
      a9 = false; a10 = false; a11 = false; a12 = false; a13 = false; a14 = false; a15 = false; a16 = false; a17 = false; a18 = false;
      a19 = false; a20 = false;
    } else if (index == 2) {
      a = false; b = false; c = true; d = false; e = false; f = false; g = false; h = false; i = false; j = false; k = false;
      l = false; m = false; n = false; o = false; p = false; q = false; r = false; s = false; t = false; u = false; v = false;
      x = false; y = false; z = false; a1 = false; a2 = false; a3 = false; a4 = false; a5 = false; a6 = false; a7 = false; a8 = false;
      a9 = false; a10 = false; a11 = false; a12 = false; a13 = false; a14 = false; a15 = false; a16 = false; a17 = false; a18 = false;
      a19 = false; a20 = false;
    } else if (index == 3) {
      a = false; b = false; c = false; d = true; e = false; f = false; g = false; h = false; i = false; j = false; k = false;
      l = false; m = false; n = false; o = false; p = false; q = false; r = false; s = false; t = false; u = false; v = false;
      x = false; y = false; z = false; a1 = false; a2 = false; a3 = false; a4 = false; a5 = false; a6 = false; a7 = false; a8 = false;
      a9 = false; a10 = false; a11 = false; a12 = false; a13 = false; a14 = false; a15 = false; a16 = false; a17 = false; a18 = false;
      a19 = false; a20 = false;
    } else if (index == 4) {
      a = false; b = false; c = false; d = false; e = true; f = false; g = false; h = false; i = false; j = false; k = false;
      l = false; m = false; n = false; o = false; p = false; q = false; r = false; s = false; t = false; u = false; v = false;
      x = false; y = false; z = false; a1 = false; a2 = false; a3 = false; a4 = false; a5 = false; a6 = false; a7 = false; a8 = false;
      a9 = false; a10 = false; a11 = false; a12 = false; a13 = false; a14 = false; a15 = false; a16 = false; a17 = false; a18 = false;
      a19 = false; a20 = false;
    } else if (index == 5) {
      a = false; b = false; c = false; d = false; e = false; f = true; g = false; h = false; i = false; j = false; k = false;
      l = false; m = false; n = false; o = false; p = false; q = false; r = false; s = false; t = false; u = false; v = false;
      x = false; y = false; z = false; a1 = false; a2 = false; a3 = false; a4 = false; a5 = false; a6 = false; a7 = false; a8 = false;
      a9 = false; a10 = false; a11 = false; a12 = false; a13 = false; a14 = false; a15 = false; a16 = false; a17 = false; a18 = false;
      a19 = false; a20 = false;
    } else if (index == 6) {
      a = false; b = false; c = false; d = false; e = false; f = false; g = true; h = false; i = false; j = false; k = false;
      l = false; m = false; n = false; o = false; p = false; q = false; r = false; s = false; t = false; u = false; v = false;
      x = false; y = false; z = false; a1 = false; a2 = false; a3 = false; a4 = false; a5 = false; a6 = false; a7 = false; a8 = false;
      a9 = false; a10 = false; a11 = false; a12 = false; a13 = false; a14 = false; a15 = false; a16 = false; a17 = false; a18 = false;
      a19 = false; a20 = false;
    } else if (index == 7) {
      a = false; b = false; c = false; d = false; e = false; f = false; g = false; h = true; i = false; j = false; k = false;
      l = false; m = false; n = false; o = false; p = false; q = false; r = false; s = false; t = false; u = false; v = false;
      x = false; y = false; z = false; a1 = false; a2 = false; a3 = false; a4 = false; a5 = false; a6 = false; a7 = false; a8 = false;
      a9 = false; a10 = false; a11 = false; a12 = false; a13 = false; a14 = false; a15 = false; a16 = false; a17 = false; a18 = false;
      a19 = false; a20 = false;
    } else if (index == 8) {
      a = false; b = false; c = false; d = false; e = false; f = false; g = false; h = false; i = true; j = false; k = false;
      l = false; m = false; n = false; o = false; p = false; q = false; r = false; s = false; t = false; u = false; v = false;
      x = false; y = false; z = false; a1 = false; a2 = false; a3 = false; a4 = false; a5 = false; a6 = false; a7 = false; a8 = false;
      a9 = false; a10 = false; a11 = false; a12 = false; a13 = false; a14 = false; a15 = false; a16 = false; a17 = false; a18 = false;
      a19 = false; a20 = false;
    } else if (index == 9) {
      a = false; b = false; c = false; d = false; e = false; f = false; g = false; h = false; i = false; j = true; k = false;
      l = false; m = false; n = false; o = false; p = false; q = false; r = false; s = false; t = false; u = false; v = false;
      x = false; y = false; z = false; a1 = false; a2 = false; a3 = false; a4 = false; a5 = false; a6 = false; a7 = false; a8 = false;
      a9 = false; a10 = false; a11 = false; a12 = false; a13 = false; a14 = false; a15 = false; a16 = false; a17 = false; a18 = false;
      a19 = false; a20 = false;
    } else if (index == 10) {
      a = false; b = false; c = false; d = false; e = false; f = false; g = false; h = false; i = false; j = false; k = true;
      l = false; m = false; n = false; o = false; p = false; q = false; r = false; s = false; t = false; u = false; v = false;
      x = false; y = false; z = false; a1 = false; a2 = false; a3 = false; a4 = false; a5 = false; a6 = false; a7 = false; a8 = false;
      a9 = false; a10 = false; a11 = false; a12 = false; a13 = false; a14 = false; a15 = false; a16 = false; a17 = false; a18 = false;
      a19 = false; a20 = false;
    } else if (index == 11) {
      a = false; b = false; c = false; d = false; e = false; f = false; g = false; h = false; i = false; j = false; k = false;
      l = true; m = false; n = false; o = false; p = false; q = false; r = false; s = false; t = false; u = false; v = false;
      x = false; y = false; z = false; a1 = false; a2 = false; a3 = false; a4 = false; a5 = false; a6 = false; a7 = false; a8 = false;
      a9 = false; a10 = false; a11 = false; a12 = false; a13 = false; a14 = false; a15 = false; a16 = false; a17 = false; a18 = false;
      a19 = false; a20 = false;
    } else if (index == 12) {
      a = false; b = false; c = false; d = false; e = false; f = false; g = false; h = false; i = false; j = false; k = false;
      l = false; m = false; n = false; o = false; p = false; q = false; r = false; s = false; t = false; u = false; v = false;
      x = false; y = false; z = false; a1 = false; a2 = false; a3 = false; a4 = false; a5 = false; a6 = false; a7 = false; a8 = false;
      a9 = false; a10 = false; a11 = false; a12 = false; a13 = false; a14 = false; a15 = false; a16 = false; a17 = false; a18 = false;
      a19 = false; a20 = false;
    } else if (index == 13) {
      a = false; b = false; c = false; d = false; e = false; f = false; g = false; h = false; i = false; j = false; k = false;
      l = false; m = false; n = true; o = false; p = false; q = false; r = false; s = false; t = false; u = false; v = false;
      x = false; y = false; z = false; a1 = false; a2 = false; a3 = false; a4 = false; a5 = false; a6 = false; a7 = false; a8 = false;
      a9 = false; a10 = false; a11 = false; a12 = false; a13 = false; a14 = false; a15 = false; a16 = false; a17 = false; a18 = false;
      a19 = false; a20 = false;
    } else if (index == 14) {
      a = false; b = false; c = false; d = false; e = false; f = false; g = false; h = false; i = false; j = false; k = false;
      l = false; m = false; n = false; o = true; p = false; q = false; r = false; s = false; t = false; u = false; v = false;
      x = false; y = false; z = false; a1 = false; a2 = false; a3 = false; a4 = false; a5 = false; a6 = false; a7 = false; a8 = false;
      a9 = false; a10 = false; a11 = false; a12 = false; a13 = false; a14 = false; a15 = false; a16 = false; a17 = false; a18 = false;
      a19 = false; a20 = false;
    } else if (index == 15) {
      a = false; b = false; c = false; d = false; e = false; f = false; g = false; h = false; i = false; j = false; k = false;
      l = false; m = false; n = false; o = false; p = true; q = false; r = false; s = false; t = false; u = false; v = false;
      x = false; y = false; z = false; a1 = false; a2 = false; a3 = false; a4 = false; a5 = false; a6 = false; a7 = false; a8 = false;
      a9 = false; a10 = false; a11 = false; a12 = false; a13 = false; a14 = false; a15 = false; a16 = false; a17 = false; a18 = false;
      a19 = false; a20 = false;
    } else if (index == 16) {
      a = false; b = false; c = false; d = false; e = false; f = false; g = false; h = false; i = false; j = false; k = false;
      l = false; m = false; n = false; o = false; p = false; q = true; r = false; s = false; t = false; u = false; v = false;
      x = false; y = false; z = false; a1 = false; a2 = false; a3 = false; a4 = false; a5 = false; a6 = false; a7 = false; a8 = false;
      a9 = false; a10 = false; a11 = false; a12 = false; a13 = false; a14 = false; a15 = false; a16 = false; a17 = false; a18 = false;
      a19 = false; a20 = false;
    } else if (index == 17) {
      a = false; b = false; c = false; d = false; e = false; f = false; g = false; h = false; i = false; j = false; k = false;
      l = false; m = false; n = false; o = false; p = false; q = false; r = true; s = false; t = false; u = false; v = false;
      x = false; y = false; z = false; a1 = false; a2 = false; a3 = false; a4 = false; a5 = false; a6 = false; a7 = false; a8 = false;
      a9 = false; a10 = false; a11 = false; a12 = false; a13 = false; a14 = false; a15 = false; a16 = false; a17 = false; a18 = false;
      a19 = false; a20 = false;
    } else if (index == 18) {
      a = false; b = false; c = false; d = false; e = false; f = false; g = false; h = false; i = false; j = false; k = false;
      l = false; m = false; n = false; o = false; p = false; q = false; r = false; s = true; t = false; u = false; v = false;
      x = false; y = false; z = false; a1 = false; a2 = false; a3 = false; a4 = false; a5 = false; a6 = false; a7 = false; a8 = false;
      a9 = false; a10 = false; a11 = false; a12 = false; a13 = false; a14 = false; a15 = false; a16 = false; a17 = false; a18 = false;
      a19 = false; a20 = false;
    } else if (index == 19) {
      a = false; b = false; c = false; d = false; e = false; f = false; g = false; h = false; i = false; j = false; k = false;
      l = false; m = false; n = false; o = false; p = false; q = false; r = false; s = false; t = true; u = false; v = false;
      x = false; y = false; z = false; a1 = false; a2 = false; a3 = false; a4 = false; a5 = false; a6 = false; a7 = false; a8 = false;
      a9 = false; a10 = false; a11 = false; a12 = false; a13 = false; a14 = false; a15 = false; a16 = false; a17 = false; a18 = false;
      a19 = false; a20 = false;
    } else if (index == 20) {
      a = false; b = false; c = false; d = false; e = false; f = false; g = false; h = false; i = false; j = false; k = false;
      l = false; m = false; n = false; o = false; p = false; q = false; r = false; s = false; t = false; u = true; v = false;
      x = false; y = false; z = false; a1 = false; a2 = false; a3 = false; a4 = false; a5 = false; a6 = false; a7 = false; a8 = false;
      a9 = false; a10 = false; a11 = false; a12 = false; a13 = false; a14 = false; a15 = false; a16 = false; a17 = false; a18 = false;
      a19 = false; a20 = false;
    } else if (index == 21) {
      a = false; b = false; c = false; d = false; e = false; f = false; g = false; h = false; i = false; j = false; k = false;
      l = false; m = false; n = false; o = false; p = false; q = false; r = false; s = false; t = false; u = false; v = true;
      x = false; y = false; z = false; a1 = false; a2 = false; a3 = false; a4 = false; a5 = false; a6 = false; a7 = false; a8 = false;
      a9 = false; a10 = false; a11 = false; a12 = false; a13 = false; a14 = false; a15 = false; a16 = false; a17 = false; a18 = false;
      a19 = false; a20 = false;
    } else if (index == 22) {
      a = false; b = false; c = false; d = false; e = false; f = false; g = false; h = false; i = false; j = false; k = false;
      l = false; m = false; n = false; o = false; p = false; q = false; r = false; s = false; t = false; u = false; v = false;
      x = true; y = false; z = false; a1 = false; a2 = false; a3 = false; a4 = false; a5 = false; a6 = false; a7 = false; a8 = false;
      a9 = false; a10 = false; a11 = false; a12 = false; a13 = false; a14 = false; a15 = false; a16 = false; a17 = false; a18 = false;
      a19 = false; a20 = false;
    } else if (index == 23) {
      a = false; b = false; c = false; d = false; e = false; f = false; g = false; h = false; i = false; j = false; k = false;
      l = false; m = false; n = false; o = false; p = false; q = false; r = false; s = false; t = false; u = false; v = false;
      x = false; y = true; z = false; a1 = false; a2 = false; a3 = false; a4 = false; a5 = false; a6 = false; a7 = false; a8 = false;
      a9 = false; a10 = false; a11 = false; a12 = false; a13 = false; a14 = false; a15 = false; a16 = false; a17 = false; a18 = false;
      a19 = false; a20 = false;
    } else if (index == 24) {
      a = false; b = false; c = false; d = false; e = false; f = false; g = false; h = false; i = false; j = false; k = false;
      l = false; m = false; n = false; o = false; p = false; q = false; r = false; s = false; t = false; u = false; v = false;
      x = false; y = false; z = true; a1 = false; a2 = false; a3 = false; a4 = false; a5 = false; a6 = false; a7 = false; a8 = false;
      a9 = false; a10 = false; a11 = false; a12 = false; a13 = false; a14 = false; a15 = false; a16 = false; a17 = false; a18 = false;
      a19 = false; a20 = false;
    } else if (index == 25) {
      a = false; b = false; c = false; d = false; e = false; f = false; g = false; h = false; i = false; j = false; k = false;
      l = false; m = false; n = false; o = false; p = false; q = false; r = false; s = false; t = false; u = false; v = false;
      x = false; y = false; z = true; a1 = true; a2 = false; a3 = false; a4 = false; a5 = false; a6 = false; a7 = false; a8 = false;
      a9 = false; a10 = false; a11 = false; a12 = false; a13 = false; a14 = false; a15 = false; a16 = false; a17 = false; a18 = false;
      a19 = false; a20 = false;
    } else if (index == 26) {
      a = false; b = false; c = false; d = false; e = false; f = false; g = false; h = false; i = false; j = false; k = false;
      l = false; m = false; n = false; o = false; p = false; q = false; r = false; s = false; t = false; u = false; v = false;
      x = false; y = false; z = true; a1 = false; a2 = true; a3 = false; a4 = false; a5 = false; a6 = false; a7 = false; a8 = false;
      a9 = false; a10 = false; a11 = false; a12 = false; a13 = false; a14 = false; a15 = false; a16 = false; a17 = false; a18 = false;
      a19 = false; a20 = false;
    } else if (index == 27) {
      a = false; b = false; c = false; d = false; e = false; f = false; g = false; h = false; i = false; j = false; k = false;
      l = false; m = false; n = false; o = false; p = false; q = false; r = false; s = false; t = false; u = false; v = false;
      x = false; y = false; z = true; a1 = false; a2 = false; a3 = true; a4 = false; a5 = false; a6 = false; a7 = false; a8 = false;
      a9 = false; a10 = false; a11 = false; a12 = false; a13 = false; a14 = false; a15 = false; a16 = false; a17 = false; a18 = false;
      a19 = false; a20 = false;
    } else if (index == 28) {
      a = false; b = false; c = false; d = false; e = false; f = false; g = false; h = false; i = false; j = false; k = false;
      l = false; m = false; n = false; o = false; p = false; q = false; r = false; s = false; t = false; u = false; v = false;
      x = false; y = false; z = true; a1 = false; a2 = false; a3 = false; a4 = true; a5 = false; a6 = false; a7 = false; a8 = false;
      a9 = false; a10 = false; a11 = false; a12 = false; a13 = false; a14 = false; a15 = false; a16 = false; a17 = false; a18 = false;
      a19 = false; a20 = false;
    } else if (index == 29) {
      a = false; b = false; c = false; d = false; e = false; f = false; g = false; h = false; i = false; j = false; k = false;
      l = false; m = false; n = false; o = false; p = false; q = false; r = false; s = false; t = false; u = false; v = false;
      x = false; y = false; z = true; a1 = false; a2 = false; a3 = false; a4 = false; a5 = true; a6 = false; a7 = false; a8 = false;
      a9 = false; a10 = false; a11 = false; a12 = false; a13 = false; a14 = false; a15 = false; a16 = false; a17 = false; a18 = false;
      a19 = false; a20 = false;
    } else if (index == 30) {
      a = false; b = false; c = false; d = false; e = false; f = false; g = false; h = false; i = false; j = false; k = false;
      l = false; m = false; n = false; o = false; p = false; q = false; r = false; s = false; t = false; u = false; v = false;
      x = false; y = false; z = true; a1 = false; a2 = false; a3 = false; a4 = false; a5 = false; a6 = true; a7 = false; a8 = false;
      a9 = false; a10 = false; a11 = false; a12 = false; a13 = false; a14 = false; a15 = false; a16 = false; a17 = false; a18 = false;
      a19 = false; a20 = false;
    } else if (index == 31) {
      a = false; b = false; c = false; d = false; e = false; f = false; g = false; h = false; i = false; j = false; k = false;
      l = false; m = false; n = false; o = false; p = false; q = false; r = false; s = false; t = false; u = false; v = false;
      x = false; y = false; z = true; a1 = false; a2 = false; a3 = false; a4 = false; a5 = false; a6 = false; a7 = true; a8 = false;
      a9 = false; a10 = false; a11 = false; a12 = false; a13 = false; a14 = false; a15 = false; a16 = false; a17 = false; a18 = false;
      a19 = false; a20 = false;
    } else if (index == 32) {
      a = false; b = false; c = false; d = false; e = false; f = false; g = false; h = false; i = false; j = false; k = false;
      l = false; m = false; n = false; o = false; p = false; q = false; r = false; s = false; t = false; u = false; v = false;
      x = false; y = false; z = true; a1 = false; a2 = false; a3 = false; a4 = false; a5 = false; a6 = false; a7 = false; a8 = true;
      a9 = false; a10 = false; a11 = false; a12 = false; a13 = false; a14 = false; a15 = false; a16 = false; a17 = false; a18 = false;
      a19 = false; a20 = false;
    } else if (index == 33) {
      a = false; b = false; c = false; d = false; e = false; f = false; g = false; h = false; i = false; j = false; k = false;
      l = false; m = false; n = false; o = false; p = false; q = false; r = false; s = false; t = false; u = false; v = false;
      x = false; y = false; z = true; a1 = false; a2 = false; a3 = false; a4 = false; a5 = false; a6 = false; a7 = false; a8 = false;
      a9 = true; a10 = false; a11 = false; a12 = false; a13 = false; a14 = false; a15 = false; a16 = false; a17 = false; a18 = false;
      a19 = false; a20 = false;
    } else if (index == 34) {
      a = false; b = false; c = false; d = false; e = false; f = false; g = false; h = false; i = false; j = false; k = false;
      l = false; m = false; n = false; o = false; p = false; q = false; r = false; s = false; t = false; u = false; v = false;
      x = false; y = false; z = true; a1 = false; a2 = false; a3 = false; a4 = false; a5 = false; a6 = false; a7 = false; a8 = false;
      a9 = false; a10 = true; a11 = false; a12 = false; a13 = false; a14 = false; a15 = false; a16 = false; a17 = false; a18 = false;
      a19 = false; a20 = false;
    } else if (index == 35) {
      a = false; b = false; c = false; d = false; e = false; f = false; g = false; h = false; i = false; j = false; k = false;
      l = false; m = false; n = false; o = false; p = false; q = false; r = false; s = false; t = false; u = false; v = false;
      x = false; y = false; z = true; a1 = false; a2 = false; a3 = false; a4 = false; a5 = false; a6 = false; a7 = false; a8 = false;
      a9 = false; a10 = false; a11 = true; a12 = false; a13 = false; a14 = false; a15 = false; a16 = false; a17 = false; a18 = false;
      a19 = false; a20 = false;
    } else if (index == 36) {
      a = false; b = false; c = false; d = false; e = false; f = false; g = false; h = false; i = false; j = false; k = false;
      l = false; m = false; n = false; o = false; p = false; q = false; r = false; s = false; t = false; u = false; v = false;
      x = false; y = false; z = true; a1 = false; a2 = false; a3 = false; a4 = false; a5 = false; a6 = false; a7 = false; a8 = false;
      a9 = false; a10 = false; a11 = false; a12 = true; a13 = false; a14 = false; a15 = false; a16 = false; a17 = false; a18 = false;
      a19 = false; a20 = false;
    } else if (index == 37) {
      a = false; b = false; c = false; d = false; e = false; f = false; g = false; h = false; i = false; j = false; k = false;
      l = false; m = false; n = false; o = false; p = false; q = false; r = false; s = false; t = false; u = false; v = false;
      x = false; y = false; z = true; a1 = false; a2 = false; a3 = false; a4 = false; a5 = false; a6 = false; a7 = false; a8 = false;
      a9 = false; a10 = false; a11 = false; a12 = false; a13 = true; a14 = false; a15 = false; a16 = false; a17 = false; a18 = false;
      a19 = false; a20 = false;
    } else if (index == 38) {
      a = false; b = false; c = false; d = false; e = false; f = false; g = false; h = false; i = false; j = false; k = false;
      l = false; m = false; n = false; o = false; p = false; q = false; r = false; s = false; t = false; u = false; v = false;
      x = false; y = false; z = true; a1 = false; a2 = false; a3 = false; a4 = false; a5 = false; a6 = false; a7 = false; a8 = false;
      a9 = false; a10 = false; a11 = false; a12 = false; a13 = false; a14 = true; a15 = false; a16 = false; a17 = false; a18 = false;
      a19 = false; a20 = false;
    } else if (index == 39) {
      a = false; b = false; c = false; d = false; e = false; f = false; g = false; h = false; i = false; j = false; k = false;
      l = false; m = false; n = false; o = false; p = false; q = false; r = false; s = false; t = false; u = false; v = false;
      x = false; y = false; z = true; a1 = false; a2 = false; a3 = false; a4 = false; a5 = false; a6 = false; a7 = false; a8 = false;
      a9 = false; a10 = false; a11 = false; a12 = false; a13 = false; a14 = false; a15 = true; a16 = false; a17 = false; a18 = false;
      a19 = false; a20 = false;
    } else if (index == 40) {
      a = false; b = false; c = false; d = false; e = false; f = false; g = false; h = false; i = false; j = false; k = false;
      l = false; m = false; n = false; o = false; p = false; q = false; r = false; s = false; t = false; u = false; v = false;
      x = false; y = false; z = true; a1 = false; a2 = false; a3 = false; a4 = false; a5 = false; a6 = false; a7 = false; a8 = false;
      a9 = false; a10 = false; a11 = false; a12 = false; a13 = false; a14 = false; a15 = false; a16 = true; a17 = false; a18 = false;
      a19 = false; a20 = false;
    } else if (index == 41) {
      a = false; b = false; c = false; d = false; e = false; f = false; g = false; h = false; i = false; j = false; k = false;
      l = false; m = false; n = false; o = false; p = false; q = false; r = false; s = false; t = false; u = false; v = false;
      x = false; y = false; z = true; a1 = false; a2 = false; a3 = false; a4 = false; a5 = false; a6 = false; a7 = false; a8 = false;
      a9 = false; a10 = false; a11 = false; a12 = false; a13 = false; a14 = false; a15 = false; a16 = false; a17 = true; a18 = false;
      a19 = false; a20 = false;
    } else if (index == 42) {
      a = false; b = false; c = false; d = false; e = false; f = false; g = false; h = false; i = false; j = false; k = false;
      l = false; m = false; n = false; o = false; p = false; q = false; r = false; s = false; t = false; u = false; v = false;
      x = false; y = false; z = true; a1 = false; a2 = false; a3 = false; a4 = false; a5 = false; a6 = false; a7 = false; a8 = false;
      a9 = false; a10 = false; a11 = false; a12 = false; a13 = false; a14 = false; a15 = false; a16 = false; a17 = false; a18 = true;
      a19 = false; a20 = false;
    } else if (index == 43) {
      a = false; b = false; c = false; d = false; e = false; f = false; g = false; h = false; i = false; j = false; k = false;
      l = false; m = false; n = false; o = false; p = false; q = false; r = false; s = false; t = false; u = false; v = false;
      x = false; y = false; z = true; a1 = false; a2 = false; a3 = false; a4 = false; a5 = false; a6 = false; a7 = false; a8 = false;
      a9 = false; a10 = false; a11 = false; a12 = false; a13 = false; a14 = false; a15 = false; a16 = false; a17 = false; a18 = false;
      a19 = true; a20 = false;
    } else if (index == 44) {
      a = false; b = false; c = false; d = false; e = false; f = false; g = false; h = false; i = false; j = false; k = false;
      l = false; m = false; n = false; o = false; p = false; q = false; r = false; s = false; t = false; u = false; v = false;
      x = false; y = false; z = true; a1 = false; a2 = false; a3 = false; a4 = false; a5 = false; a6 = false; a7 = false; a8 = false;
      a9 = false; a10 = false; a11 = false; a12 = false; a13 = false; a14 = false; a15 = false; a16 = false; a17 = false; a18 = false;
      a19 = false; a20 = true;
    }

    this.selectedlegendsfinal = {
      [this.listselectedlegend[0]]: a,
      [this.listselectedlegend[1]]: b,
      [this.listselectedlegend[2]]: c,
      [this.listselectedlegend[3]]: d,
      [this.listselectedlegend[4]]: e,
      [this.listselectedlegend[5]]: f,
      [this.listselectedlegend[6]]: g,
      [this.listselectedlegend[7]]: h,
      [this.listselectedlegend[8]]: i,
      [this.listselectedlegend[9]]: j,
      [this.listselectedlegend[10]]: k,
      [this.listselectedlegend[11]]: l,
      [this.listselectedlegend[12]]: m,
      [this.listselectedlegend[13]]: n,
      [this.listselectedlegend[14]]: o,
      [this.listselectedlegend[15]]: p,
      [this.listselectedlegend[16]]: q,
      [this.listselectedlegend[17]]: r,
      [this.listselectedlegend[18]]: s,
      [this.listselectedlegend[19]]: t,
      [this.listselectedlegend[20]]: u,
      [this.listselectedlegend[21]]: v,
      [this.listselectedlegend[22]]: x,
      [this.listselectedlegend[23]]: y,
      [this.listselectedlegend[24]]: z,
      [this.listselectedlegend[25]]: a1,
      [this.listselectedlegend[26]]: a2,
      [this.listselectedlegend[27]]: a3,
      [this.listselectedlegend[28]]: a4,
      [this.listselectedlegend[29]]: a5,
      [this.listselectedlegend[30]]: a6,
      [this.listselectedlegend[31]]: a7,
      [this.listselectedlegend[32]]: a8,
      [this.listselectedlegend[33]]: a9,
      [this.listselectedlegend[34]]: a10,
      [this.listselectedlegend[35]]: a11,
      [this.listselectedlegend[36]]: a12,
      [this.listselectedlegend[37]]: a13,
      [this.listselectedlegend[38]]: a14,
      [this.listselectedlegend[39]]: a15,
      [this.listselectedlegend[40]]: a16,
      [this.listselectedlegend[41]]: a17,
      [this.listselectedlegend[42]]: a18,
      [this.listselectedlegend[43]]: a19,
      [this.listselectedlegend[44]]: a20
    };
  }

  exportAsPDF(divId) {

    this.loadingPdf = true;
    let filename = this.datadialog.concessao + '_' + this.datadialog.subestacao + '_' + this.datadialog.equipamento;
    let data = document.getElementById(divId);

    html2canvas(data).then(canvas => {
      let HTML_Width = canvas.width;
      let HTML_Height = canvas.height;
      let top_left_margin = 15;
      let PDF_Width = HTML_Width + (top_left_margin * 2);
      let PDF_Height = (PDF_Width * 1.5) + (top_left_margin * 2);
      let canvas_image_width = HTML_Width;
      let canvas_image_height = HTML_Height;
      let totalPDFPages = Math.ceil(HTML_Height / PDF_Height) - 1;
      let pdf = new jsPDF('p', 'pt', [PDF_Width, PDF_Height]);
      const contentDataURL = canvas.toDataURL('image/png')
      pdf.addImage(contentDataURL, 'JPG', top_left_margin, top_left_margin, canvas_image_width, canvas_image_height);

      for (let i = 1; i <= totalPDFPages; i++) {
        pdf.addPage([PDF_Width, PDF_Height], 'p');
        pdf.addImage(contentDataURL, 'PNG', top_left_margin, -(PDF_Height * i) + (top_left_margin * 4), canvas_image_width, canvas_image_height);
      }
      pdf.save(filename + '.pdf');
      this.loadingPdf = false;
    });
    }

}

function renderItem(params, api) {
  var categoryIndex = api.value(0);
  var start = api.coord([api.value(1), categoryIndex]);
  var end = api.coord([api.value(2), categoryIndex]);
  var height = api.size([0, 1])[1] * 0.6;

  var rectShape = echarts.graphic.clipRectByRect({
      x: start[0],
      y: start[1] - height / 2,
      width: end[0] - start[0],
      height: height
  }, {
      x: params.coordSys.x,
      y: params.coordSys.y,
      width: params.coordSys.width,
      height: params.coordSys.height
  });

  return rectShape && {
      type: 'rect',
      transition: ['shape'],
      shape: rectShape,
      style: api.style()
  };
}
