<div class="mainDiv">
    <h2 class="example-h2">Histórico</h2>
      <div class="example-container mat-elevation-z8" max-height=1000>
        <mat-tab-group class="demo-tab-group" >
          <mat-tab label="Tab 1">
            <ng-template mat-tab-label>
              <mat-icon style="display: inline-flex; vertical-align: middle;padding-bottom: 4px; padding-right: 6px;">assignment</mat-icon>
              <p style="display: inline-flex;">
                Histórico de operações do sistema
              </p>
            </ng-template>
            <div class="demo-tab-content">
              <app-historysystemoperation></app-historysystemoperation>
            </div>
          </mat-tab>
          <mat-tab label="Tab 2">
            <ng-template mat-tab-label>
              <mat-icon style="display: inline-flex; vertical-align: middle;padding-bottom: 4px; padding-right: 6px;">assignment</mat-icon>
              <p style="display: inline-flex;">
                Histórico de alarmes
              </p>
            </ng-template>
            <div class="demo-tab-content">
              <app-historyalarmsevents></app-historyalarmsevents>
            </div>
          </mat-tab>
        </mat-tab-group>
      </div>
  </div>