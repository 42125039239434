import { Component, OnInit, ViewChild } from '@angular/core';
import { ServiceService } from './../service.service';

@Component({
  selector: 'app-listusers',
  templateUrl: './listusers.component.html',
  styleUrls: ['./listusers.component.css']
})
export class ListusersComponent implements OnInit {

  constructor(public service: ServiceService) { }

  ngOnInit(): void {
    this.service.sessionExpired();
  }

}
