import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ServiceService } from './../service.service';

@Component({
  selector: 'app-sessionexpiredalert',
  templateUrl: './sessionexpiredalert.component.html',
  styleUrls: ['./sessionexpiredalert.component.css']
})
export class SessionexpiredalertComponent implements OnInit {

  constructor(
    public router: Router,
    public service: ServiceService) { }

  ngOnInit(): void {
    /* this.router.navigateByUrl('/login', { skipLocationChange: true }); */
  }

}
