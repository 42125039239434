<div style="position:relative;background-color: #f2f2f2;" *ngIf="this.service.isLoged">
  <mat-toolbar style="position: fixed; top: 0; z-index: 1000; " color="primary" class="example-toolbar" >
    <mat-toolbar-row>
      <div class="logo">
        <img  height="50" src="./assets/logoTaesaSicarf.png">
      </div>

      <div class="logosolo">
        <img  height="30" src="./assets/logoSolo.png">
      </div>

      <span class="example-spacer"></span>
      &nbsp;

      <div class="menuRight" *ngIf="this.service.isLoged">
        <button [matMenuTriggerFor]="filtervariables" mat-button style='margin-right: 0px;' matTooltip="Status rede concessões" matTooltipPosition="above" matTooltipClass="tooltip">
          <img *ngIf="this.service.networkcountoff.length == 0" src="./assets/wi-fi.png">
          <img *ngIf="this.service.networkcountoff.length != 0" src="./assets/wifi-off.png">
        </button>
        <mat-menu #filtervariables="matMenu" yPosition="below" xPosition="before">
          <app-networkstatus></app-networkstatus>
        </mat-menu>

        <button mat-button [matMenuTriggerFor]="menu" class="menuApps">
          <mat-icon style="background: transparent;">apps</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          <button mat-menu-item class="menuFilterItem" (click)="goRouter(0)">
            <mat-icon>get_app</mat-icon>
            <span>Download oscilografia</span>
          </button>
          <button mat-menu-item class="menuFilterItem" (click)="goRouter(1)" *ngIf="this.service.isAdmin">
            <mat-icon>account_box</mat-icon>
            <span>Usuários</span>
          </button>
          <button mat-menu-item class="menuFilterItem" (click)="mostraEditEmailService()" *ngIf="this.service.isAdmin">
            <mat-icon>edit</mat-icon>
            <span>Editar Serviço de E-mail</span>
          </button>
          <button mat-menu-item class="menuFilterItem" (click)="goRouter(2)" *ngIf="this.service.isAdmin">
            <mat-icon>assignment</mat-icon>
            <span>Histórico de Operações do Sistema</span>
          </button>
          <button mat-menu-item class="menuFilterItem" (click)="goRouter(3)" *ngIf="this.service.isAdmin">
            <mat-icon>assignment</mat-icon>
            <span>Histórico de Alarmes</span>
          </button>
          <button mat-menu-item class="menuFilterItem" (click)="goRouter(4)" *ngIf="this.service.isAdmin">
            <mat-icon>assignment</mat-icon>
            <span>Histórico de Rede</span>
          </button>
        </mat-menu>

        <button mat-button [matMenuTriggerFor]="belowMenu">
          <mat-icon style="font-size: 30px; padding: 3px; padding-left: 0px;padding-right: 6px">account_box</mat-icon>&nbsp;
          <span>{{this.service.user}}</span>
        </button>
        <mat-menu #belowMenu="matMenu" yPosition="below">
          <button mat-menu-item (click)="mostraDetalhesUser()">
            <mat-icon style="padding-left: 10px; padding-right: 10px;padding-top: 2px;padding-bottom: 2px">
              <font color='gray'>person_add</font>
            </mat-icon>Alterar meus dados
          </button>
          <button mat-menu-item (click)="limpaLogin()" routerLink="/login">
            <mat-icon style="padding-left: 10px; padding-right: 10px;padding-top: 2px;padding-bottom: 2px">
              <font color='gray'>exit_to_app</font>
            </mat-icon>Logoff
          </button>
        </mat-menu>
      </div>
    </mat-toolbar-row>
  </mat-toolbar>
</div>
<div style="height: 50px;" *ngIf="this.service.isLoged">
  <footer class="footer" style="z-index: 5000">
    <a href="http://www.automalogica.com.br" target="_blank"><img style="padding-top: 6px; padding-bottom: 2px" height="25" src="./assets/automalogicaBranco.svg"></a>
  </footer>
</div>

<app-login></app-login>
<router-outlet></router-outlet>
