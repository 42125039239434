<div class="grid-container" *ngIf="this.showNewPass">
  <mat-card>
    <mat-card-content>
      <table style="width: 100%;">
        <tr *ngIf="warning">
          <td style="border-bottom: 1px solid #E0E0E0;padding-bottom: 10px;">
            <b [ngClass]="{warningOn: !this.warningok, warningOff: this.warningok}">
              {{this.warningmessage}}
            </b>
          </td>
        </tr>
        <tr>
          <mat-form-field class="full-width-input">
            <input matInput placeholder="Nova senha" [type]="fieldTextType ? 'text' : 'password'" [(ngModel)]="newpass" name="newpass" style="color:#969696;">
            <span class="lnr-eye">
              <i
                class="fa"
                [ngClass]="{
                  'fa-eye-slash': !fieldTextType,
                  'fa-eye': fieldTextType
                }"
                (click)="toggleFieldTextType(0)"
              ></i>
            </span>
          </mat-form-field>
        </tr>
        <tr>
          <mat-form-field class="full-width-input">
            <input matInput placeholder="Confirmar nova senha" [type]="fieldTextType ? 'text' : 'password'" [(ngModel)]="newpassconfirm" name="newpassconfirm" style="color:#969696;">
            <span class="lnr-eye">
              <i
                class="fa"
                [ngClass]="{
                  'fa-eye-slash': !fieldTextType,
                  'fa-eye': fieldTextType
                }"
                (click)="toggleFieldTextType(0)"
              ></i>
            </span>
          </mat-form-field>
        </tr>
      </table>
      <button mat-raised-button color="primary" type="submit" (click)="alterPass()">Enviar</button>
    </mat-card-content>
  </mat-card>
</div>
