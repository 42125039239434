<!-- FILTRO PARA TELAS MENORES -->
<div class="filterSmall">
  <button [matMenuTriggerFor]="filtervariables" #menuTrigger="matMenuTrigger" mat-button style='margin-right: 0px;' matTooltip="Filtro" matTooltipPosition="above" matTooltipClass="tooltip">
    <mat-icon>filter_list</mat-icon> Filtro
  </button>
  <!-- <button style="margin: 5px;float:right;" mat-button (click)="refresh()">
    <mat-icon style="padding: 3px;cursor:pointer;" matTooltip="Atualizar página" matTooltipPosition="above">refresh</mat-icon>
  </button> -->
  <mat-menu #filtervariables="matMenu">
      <div style="width: 100%;padding-left: 0%;" (click)="stopPropagation($event)">
        <table style="width: 100%;padding-left: 0%;padding: 15px;">
          <tr>
            <td>
              Data inicial
            </td>
            <td>
              <mat-form-field class="example-full-width1">
                <input #dateIn [(ngModel)]="dataInicial" (dateChange)="filtroDataInicial($event)" matInput
                  [matDatepicker]="picker" placeholder="Data inicial">
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
              </mat-form-field>&nbsp;&nbsp;&nbsp;&nbsp;
              
            </td>
            <td>
              <mat-form-field>
                <input matInput (ngModelChange)="filtroDataInicial($event)" step="1" type="time" placeholder="Hora inicial(opcional)"
                  [(ngModel)]="hora_inicial">
              </mat-form-field>
            </td>
          </tr>
          <tr>
            <td>
              Data final
            </td>
            <td>
              <mat-form-field class="example-full-width1">
                <input #dateFin [(ngModel)]="dataFinal" (dateChange)="filtroDataFinal($event)" matInput
                  [matDatepicker]="picker1" placeholder="Data final">
                <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                <mat-datepicker #picker1></mat-datepicker>
              </mat-form-field>&nbsp;&nbsp;&nbsp;&nbsp;
              
            </td>
            <td>
              <mat-form-field>
                <input matInput (ngModelChange)="filtroDataFinal($event)" step="1" type="time" placeholder="Hora final(opcional)"
                  [(ngModel)]="hora_final">
              </mat-form-field>
            </td>
          </tr>
          <tr>
            <td>
              Filtro
            </td>
            <td colspan="2">
              <mat-form-field appearance="fill" style="width: 100%;">
                <mat-label>Filtro por palavra</mat-label>
                <input matInput (keyup)="applyFilter($event)" placeholder="Ex: Nome Concessão, Subestação" style="color: #969696;width: 260px;"  [(ngModel)]="filtropalavra">
                <mat-icon class="submit-lente">search</mat-icon>
              </mat-form-field>
            </td>
          </tr>
          <tr>
            <td>
              Concessão
            </td>
            <td colspan="2">
              <mat-form-field appearance="fill" style="width: 100%;">
                <mat-label>{{this.labelfilterloading0}} <mat-spinner *ngIf="loadingfilters" diameter=20></mat-spinner></mat-label>
                <mat-select (selectionChange)="filtroConcessao($event)" [(ngModel)]="selectConcessao">
                  <mat-option value="All">Todos</mat-option>
                  <mat-option *ngFor="let c of listConcessao" [value]="c.valor">{{c.valor}}</mat-option>
                </mat-select>
              </mat-form-field>
            </td>
          </tr>
          <tr>
            <td>
              Subestação
            </td>
            <td colspan="2">
              <mat-form-field appearance="fill" style="width: 100%;">
                <mat-label>{{this.labelfilterloading1}} <mat-spinner *ngIf="loadingfilters" diameter=20></mat-spinner></mat-label>
                <mat-select (selectionChange)="filtroSubest($event)" [(ngModel)]="selectSubestacao">
                  <mat-option value="All">Todos</mat-option>
                  <mat-option *ngFor="let c of listSubest" [value]="c.valor">{{c.valor}}</mat-option>
                </mat-select>
              </mat-form-field>
            </td>
          </tr>
          <tr>
            <td>
              Linha
            </td>
            <td colspan="2">
              <mat-form-field appearance="fill" style="width: 100%;">
                <mat-label>{{this.labelfilterloading2}} <mat-spinner *ngIf="loadingfilters" diameter=20></mat-spinner></mat-label>
                <mat-select (selectionChange)="filtroBay($event)" [(ngModel)]="selectLinha">
                  <mat-option value="All">Todos</mat-option>
                  <mat-option *ngFor="let c of listBay" [value]="c.valor">{{c.valor}}</mat-option>
                </mat-select>
              </mat-form-field>
            </td>
          </tr>
          <tr>
            <td>
              Relé/RDP
            </td>
            <td colspan="2">
              <mat-form-field appearance="fill" style="width: 100%;">
                <mat-label>{{this.labelfilterloading3}} <mat-spinner *ngIf="loadingfilters" diameter=20></mat-spinner></mat-label>
                <mat-select (selectionChange)="filtroRele($event)" [(ngModel)]="selectRele">
                  <mat-option value="All">Todos</mat-option>
                  <mat-option *ngFor="let c of listRele" [value]="c.valor">{{c.valor}}</mat-option>
                </mat-select>
              </mat-form-field>
            </td>
          </tr>
        </table>
        <div style="text-align: center;">
          <button mat-raised-button color="primary" (click)="clearFilter()">
            Limpar filtro
          </button>
        </div>
      </div>    
  </mat-menu>
</div>
<!-- ========================================================================================================================== -->

<div class="filter">
  <mat-card style="background-color: white;">
  <div style="width:100%;text-align:right">
    <img *ngIf="!this.service.generalloading" style="cursor: pointer;text-align:right" matTooltip="Baixar para excel" matTooltipPosition="above" src="./../../assets/iconExcel.png" (click)="ExportExcel()">
    <img *ngIf="this.service.generalloading" style="margin: auto;padding-left: 65px;" src="./../../assets/loading.gif">
  </div>
    <mat-grid-list cols="4" rowHeight="150px">
      <mat-grid-tile [colspan]="1" [rowspan]="1">
        <div>
          <table>
            <tr>
              <h2>Data-Hora</h2>
            </tr>
            <tr>
              <mat-form-field class="example-full-width1">
                <input #dateIn [(ngModel)]="dataInicial" (dateChange)="filtroDataInicial($event)" matInput
                  [matDatepicker]="picker" placeholder="Data inicial">
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
              </mat-form-field>&nbsp;&nbsp;&nbsp;&nbsp;
              <mat-form-field>
                <input matInput (ngModelChange)="filtroDataInicial($event)" step="1" type="time" placeholder="Hora inicial(opcional)"
                  [(ngModel)]="hora_inicial">
              </mat-form-field>
            </tr>

            <tr>
              <mat-form-field class="example-full-width1">
                <input #dateFin [(ngModel)]="dataFinal" (dateChange)="filtroDataFinal($event)" matInput
                  [matDatepicker]="picker1" placeholder="Data final">
                <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                <mat-datepicker #picker1></mat-datepicker>
              </mat-form-field>&nbsp;&nbsp;&nbsp;&nbsp;
              <mat-form-field>
                <input matInput (ngModelChange)="filtroDataFinal($event)" step="1" type="time" placeholder="Hora final(opcional)"
                  [(ngModel)]="hora_final">
              </mat-form-field>
            </tr>
          </table>
        </div>
      </mat-grid-tile>
      <mat-grid-tile [colspan]="3" [rowspan]="1">

        <div style="display: inline;">
          <div>
            <h2>Filtro</h2>
          </div>
          <div>
            <mat-form-field appearance="fill" style="width: 260px;">
              <mat-label>Filtro por palavra</mat-label>
              <input matInput (keyup)="applyFilter($event)" placeholder="Ex: Nome Concessão, Subestação" style="color: #969696;width: 260px;"  [(ngModel)]="filtropalavra">
              <mat-icon class="submit-lente">search</mat-icon>
            </mat-form-field>
          </div>
        </div>
        &nbsp;&nbsp;&nbsp;&nbsp;
        <div style="display: inline;">
          <div>
            <h2>Concessão</h2>
          </div>
          <div>
            <mat-form-field appearance="fill">
              <mat-label>{{this.labelfilterloading0}} <mat-spinner *ngIf="loadingfilters" diameter=20></mat-spinner></mat-label>
              <mat-select (selectionChange)="filtroConcessao($event)" [(ngModel)]="selectConcessao">
                <mat-option value="All">Todos</mat-option>
                <mat-option *ngFor="let c of listConcessao" [value]="c.valor">{{c.valor}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        &nbsp;&nbsp;&nbsp;&nbsp;
        <div style="display: inline;">
          <div>
            <h2>Subestação</h2>
          </div>
          <div>
            <mat-form-field appearance="fill">
              <mat-label>{{this.labelfilterloading1}} <mat-spinner *ngIf="loadingfilters" diameter=20></mat-spinner></mat-label>
              <mat-select (selectionChange)="filtroSubest($event)" [(ngModel)]="selectSubestacao">
                <mat-option value="All">Todos</mat-option>
                <mat-option *ngFor="let c of listSubest" [value]="c.valor">{{c.valor}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>

        &nbsp;&nbsp;&nbsp;&nbsp;
        <div style="display: inline;">
          <div>
            <h2>Linha</h2>
          </div>
          <div>
            <mat-form-field appearance="fill">
              <mat-label>{{this.labelfilterloading2}} <mat-spinner *ngIf="loadingfilters" diameter=20></mat-spinner></mat-label>
              <mat-select (selectionChange)="filtroBay($event)" [(ngModel)]="selectLinha">
                <mat-option value="All">Todos</mat-option>
                <mat-option *ngFor="let c of listBay" [value]="c.valor">{{c.valor}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>

        &nbsp;&nbsp;&nbsp;&nbsp;
        <div style="display: inline;">
          <div>
            <h2>Relé/RDP</h2>
          </div>
          <div>
            <mat-form-field appearance="fill">
              <mat-label>{{this.labelfilterloading3}} <mat-spinner *ngIf="loadingfilters" diameter=20></mat-spinner></mat-label>
              <mat-select (selectionChange)="filtroRele($event)" [(ngModel)]="selectRele">
                <mat-option value="All">Todos</mat-option>
                <mat-option *ngFor="let c of listRele" [value]="c.valor">{{c.valor}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div style="display: inline;padding-left: 20px;padding-top: 25px;" *ngIf="showButtonClenaFilter">
          <div>
          </div>
          <div>
            <button mat-raised-button color="primary" (click)="clearFilter()">
              Limpar filtro
            </button>
          </div>
          <div>
          </div>
        </div>
      </mat-grid-tile>
    </mat-grid-list>
  </mat-card>
</div>

<div style="position: absolute;top:50%;left: 45%;background-color: transparent;z-index: 6000;" *ngIf="this.loading">
  <img style="margin: auto;padding-left: 65px;" src="./../../assets/loading.gif">
  <p style="color:#1D2C67;font-weight: bold;">Carrregando oscilografias</p>
</div>

<div style="position: absolute;top:50%;left: 45%;background-color: transparent" *ngIf="this.warningNosOscMessage">
  <p style="color:#1D2C67;font-weight: bold;">Não há oscilografias para o filtro realizado</p>
</div>


  <div class="example-container mat-elevation-z8">
    <div class="example-table-container">
      <table mat-table [dataSource]="dataSource" class="example-table"
             matSort matSortActive="created" matSortDisableClear matSortDirection="desc" [ngClass]="{onLoading: this.loading == true}" >
             <ng-container matColumnDef="select">
              <th mat-header-cell *matHeaderCellDef style="width: 5%;">
                <mat-checkbox [checked]="selection.hasValue() && isAllSelected()"
                 (change)="masterToggle($event)">
                </mat-checkbox>
              </th>
              <td mat-cell *matCellDef="let row" class="tbaleTDStatus" style="width: 5%;">
                <mat-checkbox (click)="stopPropagation($event) ;" (change)="$event ? selection.toggle(row) : null;"
                 [checked]="row.select">
                </mat-checkbox>
              </td>
            </ng-container>
        
            <ng-container matColumnDef="favorite">
              <th mat-header-cell *matHeaderCellDef style="width: 5%;">
                <mat-icon (click)="addAllFavorite(0)" style="cursor:pointer;">star_border</mat-icon>
              </th>
              <td mat-cell *matCellDef="let row" class="tbaleTDStatus" style="width: 5%;">
                <mat-icon *ngIf="!row.favorite" (click)="addFavorite(0, row)" style="cursor:pointer;">star_border</mat-icon>
                <mat-icon (click)="addFavorite(1, row)" style="margin: auto;color: rgb(29, 44, 103,0.8);cursor:pointer;" matPrefix *ngIf="row.favorite">star</mat-icon>
              </td>
            </ng-container>
        
            <ng-container matColumnDef="DataHoraOcorrencia">
              <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 15%;"> Data e Hora </th>
              <td mat-cell *matCellDef="let row" class="tbaleTDStatus" style="width: 15%;"> {{row.DataHoraOcorrencia}} </td>
            </ng-container>
        
            <ng-container matColumnDef="Concessao">
              <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 10%;"> Concessão </th>
              <td mat-cell *matCellDef="let row" class="tbaleTDStatus" style="width: 10%;"> {{row.Concessao}} </td>
            </ng-container>
        
            <ng-container matColumnDef="Subestacao">
              <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 10%;"> Subestacão </th>
              <td mat-cell *matCellDef="let row" class="tbaleTDStatus" style="width: 10%;"> {{row.Subestacao}} </td>
            </ng-container>
        
            <ng-container matColumnDef="Linha">
              <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 10%;"> Linha </th>
              <td mat-cell *matCellDef="let row" class="tbaleTDStatus" style="width: 10%;"> {{row.Linha}} </td>
            </ng-container>
        
            <ng-container matColumnDef="Equipamento">
              <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 10%;"> Equipamento </th>
              <td mat-cell *matCellDef="let row" class="tbaleTDStatus" style="width: 10%;"> {{row.Equipamento}} </td>
            </ng-container>
        
            <ng-container matColumnDef="Arquivo" style="display: none;">
              <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 10%;" style="display: none;"> </th>
              <td mat-cell *matCellDef="let row" class="tbaleTDStatus" style="width: 10%;" style="display: none;"> {{row.Arquivo}} </td>
            </ng-container>
        
            <ng-container matColumnDef="download">
              <th mat-header-cell *matHeaderCellDef style="width: 5%;">  </th>
              <td mat-cell *matCellDef="let row" class="tbaleTDStatus" style="width: 5%;">
                <mat-icon class="buttons" matPrefix (click)="downloadOscByOneFile(row)"
                  matTooltip="Download" matTooltipPosition="above" matTooltipClass="tooltip">file_download</mat-icon>
              </td>
            </ng-container>
        
            <ng-container matColumnDef="grafico">
              <th mat-header-cell *matHeaderCellDef style="width: 5%;">  </th>
              <td mat-cell *matCellDef="let row" class="tbaleTDStatus" style="width: 5%;">
                <mat-icon class="buttons" matPrefix (click)="openChart(row.Concessao, row.Subestacao, row.Equipamento, row.Arquivo)"
                  matTooltip="Abrir gráfico" matTooltipPosition="above" matTooltipClass="tooltip">show_chart</mat-icon>
              </td>
            </ng-container>

            <ng-container matColumnDef="updatetable">
              <th mat-header-cell *matHeaderCellDef style="width: 2%;">
                <button style="margin: 5px;float:right;" mat-button (click)="refresh()">
                  <mat-icon style="padding: 3px;cursor:pointer;" matTooltip="Atualizar página" matTooltipPosition="above">refresh</mat-icon>
                </button>
              </th>
              <td mat-cell *matCellDef="let row" class="tbaleTDStatus" style="width: 2%;">
              </td>
            </ng-container>
        
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </div>

    <mat-paginator [length]="10" [pageSize]="10" [pageSizeOptions]="[5, 10, 15, 100, 200, 500]" class="mat-paginator-sticky"></mat-paginator>
  </div>



<!-- <div class="mainDiv" [ngClass]="{onLoading: this.loading == true}" *ngIf="flagNoData">
  <table mat-table [dataSource]="dataSource" matSort style="width: 100%;">

    <ng-container matColumnDef="select">
      <th mat-header-cell *matHeaderCellDef style="width: 5%;">
        <mat-checkbox [checked]="selection.hasValue() && isAllSelected()"
         (change)="masterToggle($event)">
        </mat-checkbox>
      </th>
      <td mat-cell *matCellDef="let row" class="tbaleTDStatus" style="width: 5%;">
        <mat-checkbox (click)="stopPropagation($event) ;" (change)="$event ? selection.toggle(row) : null;"
         [checked]="row.select">
        </mat-checkbox>
      </td>
    </ng-container>

    <ng-container matColumnDef="favorite">
      <th mat-header-cell *matHeaderCellDef style="width: 5%;">
        <mat-icon (click)="addAllFavorite(0)" style="cursor:pointer;">star_border</mat-icon>
      </th>
      <td mat-cell *matCellDef="let row" class="tbaleTDStatus" style="width: 5%;">
        <mat-icon *ngIf="!row.favorite" (click)="addFavorite(0, row)" style="cursor:pointer;">star_border</mat-icon>
        <mat-icon (click)="addFavorite(1, row)" style="margin: auto;color: rgb(29, 44, 103,0.8);cursor:pointer;" matPrefix *ngIf="row.favorite">star</mat-icon>
      </td>
    </ng-container>

    <ng-container matColumnDef="DataHoraOcorrencia">
      <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 15%;"> Data e Hora </th>
      <td mat-cell *matCellDef="let row" class="tbaleTDStatus" style="width: 15%;"> {{row.DataHoraOcorrencia}} </td>
    </ng-container>

    <ng-container matColumnDef="Concessao">
      <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 10%;"> Concessão </th>
      <td mat-cell *matCellDef="let row" class="tbaleTDStatus" style="width: 10%;"> {{row.Concessao}} </td>
    </ng-container>

    <ng-container matColumnDef="Subestacao">
      <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 10%;"> Subestacão </th>
      <td mat-cell *matCellDef="let row" class="tbaleTDStatus" style="width: 10%;"> {{row.Subestacao}} </td>
    </ng-container>

    <ng-container matColumnDef="Linha">
      <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 10%;"> Linha </th>
      <td mat-cell *matCellDef="let row" class="tbaleTDStatus" style="width: 10%;"> {{row.Linha}} </td>
    </ng-container>

    <ng-container matColumnDef="Equipamento">
      <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 10%;"> Equipamento </th>
      <td mat-cell *matCellDef="let row" class="tbaleTDStatus" style="width: 10%;"> {{row.Equipamento}} </td>
    </ng-container>

    <ng-container matColumnDef="Arquivo" style="display: none;">
      <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 10%;" style="display: none;"> </th>
      <td mat-cell *matCellDef="let row" class="tbaleTDStatus" style="width: 10%;" style="display: none;"> {{row.Arquivo}} </td>
    </ng-container>

    <ng-container matColumnDef="download">
      <th mat-header-cell *matHeaderCellDef style="width: 5%;">  </th>
      <td mat-cell *matCellDef="let row" class="tbaleTDStatus" style="width: 5%;">
        <mat-icon class="buttons" matPrefix (click)="downloadOscByOneFile(row)"
          matTooltip="Download" matTooltipPosition="above" matTooltipClass="tooltip">file_download</mat-icon>
      </td>
    </ng-container>

    <ng-container matColumnDef="grafico">
      <th mat-header-cell *matHeaderCellDef style="width: 5%;">  </th>
      <td mat-cell *matCellDef="let row" class="tbaleTDStatus" style="width: 5%;">
        <mat-icon class="buttons" matPrefix (click)="openChart(row.Concessao, row.Subestacao, row.Equipamento, row.Arquivo)"
          matTooltip="Abrir gráfico" matTooltipPosition="above" matTooltipClass="tooltip">show_chart</mat-icon>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

  </table>

</div> -->

<!-- <div>
  <mat-paginator [length]="10" [pageSize]="10" [pageSizeOptions]="[5, 10, 15, 100, 200, 500]" class="mat-paginator-sticky"></mat-paginator>
</div> -->

<div class="footer" *ngIf="!this.loading">
  <div style="position: absolute;bottom: 8px;left: 110px;width: auto;">
    <table style="width: auto;">
      <tr>
        <td>
          <button mat-raised-button color="primary" matTooltip="Selecione as oscilografias da lista e clique aqui para fazer o Download"
          matTooltipPosition="below" (click)="downloadOsc()">
            Download
          </button>

        </td>
        <td *ngIf="flagNoOscSelected" style="color:red;padding-left: 10px;">
          <b>Nenhuma oscilografia selecionada</b>
        </td>
        <td *ngIf="flagHasOsc" style="color:#1D2C67;padding-left: 10px;">
          <b>Realizando download de {{this.counterFilesToDownload}} oscilografia(s), aguarde</b>
        </td>
        <td  *ngIf="flagHasOsc">
          <mat-spinner diameter=30></mat-spinner>
        </td>
        <td *ngIf="flagDownloadOk" style="color:#1D2C67;padding-left: 10px;">
          <b>Download realizado com sucesso</b>
        </td>
        <td  *ngIf="flagAddFavoriteAll">
          <mat-spinner diameter=30></mat-spinner>
        </td>
        <td *ngIf="flagAddFavoriteAll" style="color:#1D2C67;padding-left: 10px;">
          <b>{{nameFileFavorite}}</b>
        </td>
      </tr>
    </table>
  </div>
</div>


