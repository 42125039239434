<div>
<h1 mat-dialog-title>Sessão Expirada</h1>
<div mat-dialog-content>
  <table>
    <tr>
      <td>
        <img src="./../../assets/alerta.png">
      </td>
      <td>
        Sua sessão expirou. Faça login novamente.
      </td>
    </tr>
  </table>
  <button style="float: right;" mat-button mat-dialog-close>Ok</button>
</div>
</div>
