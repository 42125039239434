import { Component, OnInit, Inject } from '@angular/core';
import { ServiceService } from './../service.service';
import { sha256 } from 'js-sha256';
import { Router } from '@angular/router';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import * as moment from 'moment';
import { MatSnackBar } from '@angular/material/snack-bar';

export interface DialogData {
  username: string;
  isadmin: boolean;
  changepass: boolean;
  type: number;
}

@Component({
  selector: 'app-newpass',
  templateUrl: './newpass.component.html',
  styleUrls: ['./newpass.component.css']
})
export class NewpassComponent implements OnInit {

  constructor(
    public service: ServiceService,
    public router: Router,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<NewpassComponent>,
  ) { }

  password = '';
  passwordconfirm = '';
  codeacess;
  validatepass = false;
  warningmessage = '';
  warning = false;
  username = '';
  intervalo;
  sessionNagTimeout;
  fieldTextType: boolean;
  fieldTextTypeConfirm: boolean;
  showInputPass = true;
  loading = false;
  txtCodAcess = '';
  passorcodeacess = '';
  dateuserupdatepass = moment(Date()).format('YYYY-DD-MM HH:mm:SS');
  txt3monthexpired = '';

  ngOnInit(): void {
    this.username = this.data.username;
    if (this.data.changepass) {
      this.txtCodAcess = 'Senha atual';
    } else {
      this.txtCodAcess = 'Código de acesso';
    }

    if (this.data.type == 1) {
      this.txt3monthexpired = 'O período de 3 meses de sua senha expirou. Cadastre uma nova senha por favor.';
    } else {
      this.txt3monthexpired = '';
    }
  }

  alterPass() {

    this.warning = false;
    this.validatepass = true;
    this.loading = true;
    this.txt3monthexpired = '';
    this.warningmessage = 'Criando nova senha';

    if (this.codeacess == undefined || this.password == '' || this.passwordconfirm == '') {
      this.warning = true;
      this.validatepass = false;
      this.warningmessage = 'Preencha os campos abaixo';
    } else {

      if (this.data.changepass) {
        this.passorcodeacess = sha256(this.codeacess);
      } else {
        this.passorcodeacess = this.codeacess;
      }


      this.service.post_CheckAccessCode(this.passorcodeacess, this.username, this.data.changepass).subscribe((data: any) => {
        if (data) {
          this.saveNewPass();
        } else {
          this.warning = true;
          this.validatepass = false;
          if (this.data.changepass) {
            this.warningmessage = 'Senha atual inválida.';
          } else {
            this.warningmessage = 'Código de acesso inválido.';
          }
        }
      });
    }
  }

  saveNewPass() {

      if (this.password != this.passwordconfirm) {
        this.warning = true;
        this.validatepass = false;
        this.warningmessage = 'Essas senhas não coincidem. Tente novamente.';
      } else {
        this.warning = false;
        this.validatepass = true;
        this.warningmessage = 'Criando nova senha';

        this.service.post_updatePass(this.username, sha256(this.password).toString(),
        this.passorcodeacess, this.data.changepass, this.dateuserupdatepass).subscribe((data: any) => {
          if (data) {
            if (this.data.isadmin) {
              this.service.isAdmin = true;
            } else {
              this.service.isAdmin = false;
            }
            this.warningmessage = 'Senha criada com sucesso.';
            this.loading = false;
            this.validatepass = true;
            this.warning = false;
            this.dialogRef.close(true);
            this.snackBar.open('Senha criada com sucesso.', '', {
              duration: 5000,
              verticalPosition: 'top',
              horizontalPosition : 'center',
              panelClass: ['snackbarOk']
            });
          } else {
            this.validatepass = false;
            this.warning = true;
            this.warningmessage = 'Código inválido.';
          }
        });
      }

      if (this.data.type == 1) {
        this.service.post_AddToOperationHistory(this.service.idUser, 4).subscribe();
      } else {
        this.service.post_AddToOperationHistory(this.service.idUser, 8).subscribe();
      }
  }

  toggleFieldTextType(type) {
    if (type == 0) {
      this.fieldTextType = !this.fieldTextType;
    } else {
      this.fieldTextTypeConfirm = !this.fieldTextTypeConfirm;
    }
  }

}
