import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './../app/login/login.component';
import { ListoscComponent } from './listosc/listosc.component';
import { ListusersComponent } from './listusers/listusers.component';
import { NewforgetpassComponent } from './../app/newforgetpass/newforgetpass.component';
import { HistorysystemoperationComponent } from './../app/historysystemoperation/historysystemoperation.component';
import { HistoryalarmseventsComponent } from './../app/historyalarmsevents/historyalarmsevents.component';
import { HistorynetworkComponent } from './../app/historynetwork/historynetwork.component';
import { HistorydataComponent } from './../app/historydata/historydata.component';
import { EmailserviceComponent } from './../app/emailservice/emailservice.component';

const routes: Routes = [
  { path: 'login',
    component: LoginComponent
  },
  { path: 'listoscilography',
    component: ListoscComponent
  },
  { path: 'listusers',
    component: ListusersComponent
  },
  { path: 'newpass',
    component: NewforgetpassComponent
  },
  { path: 'historysystemoperation',
    component: HistorysystemoperationComponent
  },
  { path: 'historyalarmevent',
    component: HistoryalarmseventsComponent
  },
  { path: 'historyalnetwork',
    component: HistorynetworkComponent
  },
  { path: 'historygeneral',
    component: HistorydataComponent
  },
  { path: 'editemailservice',
    component: EmailserviceComponent
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
