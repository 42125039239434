<div class="grid-container">
  <mat-icon style="display: inline-flex; vertical-align: middle;padding-bottom: 4px; padding-right: 6px;">add_to_photos</mat-icon>
  <span style="font-weight: bold;font-size: 20px;">Nova senha</span>
  <mat-card>
    <mat-card-content>
      <table style="width: 100%;">
        <tr>
          <td style="border-bottom: 1px solid #E0E0E0;padding-bottom: 10px;">
            <b style="color:red;">
              {{this.txt3monthexpired}}
            </b>
          </td>
        </tr>
        <tr *ngIf="this.validatepass">
          <td style="border-bottom: 1px solid #E0E0E0;padding-bottom: 10px;">
            <img *ngIf="loading" src="./../../assets/loading.gif"> <b style="color:green;">{{this.warningmessage}}</b>
          </td>
        </tr>
        <tr *ngIf="warning">
          <td style="border-bottom: 1px solid #E0E0E0;padding-bottom: 10px;">
            <b style="color:red;">
              {{this.warningmessage}}
            </b>
          </td>
        </tr>
        <tr>
          <mat-form-field class="full-width-input">
            <input matInput [placeholder]="this.txtCodAcess" [type]="!this.data.changepass ? 'text' : 'password'" [(ngModel)]="codeacess" name="codeacess" style="color:#969696;">
          </mat-form-field>
        </tr>
        <tr>
          <mat-form-field class="full-width-input">
            <input matInput placeholder="Nova senha" [type]="fieldTextType ? 'text' : 'password'" [(ngModel)]="password" name="password" style="color:#969696;">
            <span class="lnr-eye">
              <i
                class="fa"
                [ngClass]="{
                  'fa-eye-slash': !fieldTextType,
                  'fa-eye': fieldTextType
                }"
                (click)="toggleFieldTextType(0)"
              ></i>
            </span>
          </mat-form-field>
        </tr>
        <tr>
          <mat-form-field class="full-width-input">
            <input matInput placeholder="Confirmar nova senha" [type]="fieldTextTypeConfirm ? 'text' : 'password'" [(ngModel)]="passwordconfirm" name="passwordconfirm" style="color:#969696;">
            <span class="lnr-eye">
              <i
                class="fa"
                [ngClass]="{
                  'fa-eye-slash': !fieldTextTypeConfirm,
                  'fa-eye': fieldTextTypeConfirm
                }"
                (click)="toggleFieldTextType(1)"
              ></i>
            </span>
          </mat-form-field>
        </tr>
      </table>
      <button mat-raised-button color="primary" type="submit" (click)="alterPass()">Alterar</button>
    </mat-card-content>
  </mat-card>

</div>
