import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef} from '@angular/material/dialog';
import { ServiceService } from './../service.service';
import { sha256 } from 'js-sha256';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-forgetpass',
  templateUrl: './forgetpass.component.html',
  styleUrls: ['./forgetpass.component.css']
})
export class ForgetpassComponent implements OnInit {

  constructor(
    public service: ServiceService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<ForgetpassComponent>,
    public snackBar: MatSnackBar
  ) { }

  email;
  newpass = '';
  warningmessage = '';
  warning = false;
  warningok = false;
  fieldTextType: boolean;
  fieldTextTypeConfirm: boolean;
  loading = false;
  listRandompass = [];
  dateToday = new Date();
  interval;

  ngOnInit(): void {
  }

  alterPass() {

    this.generateRandomPass();

    if (this.email == undefined) {
      this.warning = true;
      this.warningmessage = 'Preencha o campo abaixo.';
    } else {
      localStorage.setItem('email', this.email);
      localStorage.getItem('email');
      this.loading = true;

      this.service.post_forgetPass(this.email, this.listRandompass[0]['randompass'],
      this.listRandompass[0]['cededrandompass']).subscribe((data: any) => {
        if (data) {
          this.responseEmailService();
        } else {
          this.warning = true;
          this.warningok = false;
          this.loading = false;
          this.warningmessage = 'E-mail não esta cadastrado no sistema. Entre em contato com o administrador.';
        }
      });
      // this.service.post_AddToOperationHistory(this.service.idUser, 3).subscribe();
    }
  }

  responseEmailService() {
    this.interval = setInterval(() => {
      this.service.post_EmailServiceResponse(this.email).subscribe((data: any) => {
        console.log(data)
        if (data) {
          this.warning = true;
          this.warningok = true;
          this.loading = false;
          this.dialogRef.close(true);
          this.snackBar.open('E-mail enviado com sucesso.', '', {
            duration: 2000,
            verticalPosition: 'top',
            horizontalPosition : 'center',
            panelClass: ['snackbarOk']
          });
          this.stopInterval();
          this.service.post_UpdateEmailServiceResponse(this.email).subscribe((data: any) => {
            console.log(data)
          });
        } else {
          this.dialogRef.close(true);
          this.warning = true;
          this.warningok = false;
          this.loading = false;
          this.snackBar.open('Ocorreu um erro ao enviar o e-mail. Entre em contato com o administrador do sistema.', '', {
            duration: 2000,
            verticalPosition: 'top',
            horizontalPosition : 'center',
            panelClass: ['snackbarError']
          });
          this.stopInterval();
        }
      });
    }, 2000);
  }

  stopInterval() {
    clearInterval(this.interval);
  }
  toggleFieldTextType(type) {
    if (type == 0) {
      this.fieldTextType = !this.fieldTextType;
    } else {
      this.fieldTextTypeConfirm = !this.fieldTextTypeConfirm;
    }
  }

  generateRandomPass() {

    let randompass = '';
    let cededrandompass = '';

    randompass = Math.random().toString(36).slice(-6);
    cededrandompass = sha256(randompass);

    this.listRandompass.push(
      {
        'randompass': randompass,
        'cededrandompass': cededrandompass
      }
    );
    return this.listRandompass;
  }

}
