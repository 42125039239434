import { Component, OnInit } from '@angular/core';
import { ServiceService } from './../service.service';
import { sha256 } from 'js-sha256';
import { Router } from '@angular/router';

@Component({
  selector: 'app-newforgetpass',
  templateUrl: './newforgetpass.component.html',
  styleUrls: ['./newforgetpass.component.css']
})
export class NewforgetpassComponent implements OnInit {

  constructor(
    public service: ServiceService,
    public router: Router
  ) { }

  newpass = '';
  newpassconfirm = '';
  warningmessage = '';
  warning = false;
  warningok = false;
  fieldTextType: boolean;
  fieldTextTypeConfirm: boolean;
  showNewPass = false;

  ngOnInit(): void {
    this.service.mostraPagina = false;
    this.showNewPass = true;
  }

  toggleFieldTextType(type) {
    if (type == 0) {
      this.fieldTextType = !this.fieldTextType;
    } else {
      this.fieldTextTypeConfirm = !this.fieldTextTypeConfirm;
    }
  }

  alterPass() {
    if (this.newpass == '' || this.newpassconfirm == '') {
      this.warning = true;
      this.warningmessage = 'Preencha os campos abaixo';
    } else {
      if (this.newpass != this.newpassconfirm) {
        this.warning = true;
        this.warningmessage = 'Essas senhas não coincidem. Tente novamente.';
      } else {
        this.warning = true;
        this.warningmessage = 'Criando nova senha';

        this.service.post_updatePassForget(localStorage['email'], sha256(this.newpass)).subscribe((data: any) => {
          if (data) {
            this.showNewPass = false;
            localStorage.removeItem('email');
            this.warningmessage = 'Nova senha criada com sucesso.';
            this.router.navigateByUrl('/login', { skipLocationChange: true });
          }
        });
      }
    }
  }

}
