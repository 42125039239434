<div class="mainDiv">
    <h2 class="example-h2">Histórico de Rede</h2>

    <div class="filter">
        <mat-card style="background-color: white;">
        <div style="width:100%;text-align:right">
          <img style="cursor: pointer;" matTooltip="Baixar para excel" matTooltipPosition="above" src="./../../assets/iconExcel.png" (click)="ExportExcel()">
        </div>
        <div class="group">
          <div id="DivA">
            <table>
                  <tr>
                    <h2>Filtro Data-Hora</h2>
                  </tr>
                  <tr>
                    <td>
                      <mat-form-field class="example-full-width1">
                        <input #dateIn  matInput
                          [matDatepicker]="picker" placeholder="Data inicial" [(ngModel)]="initialdate">
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                      </mat-form-field>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </td>
                    <td>
                      <mat-form-field class="example-full-width1">
                        <input #dateFin  matInput
                          [matDatepicker]="picker1" placeholder="Data final" [(ngModel)]="finaldate" (dateChange)="filterByDate()">
                        <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                        <mat-datepicker #picker1></mat-datepicker>
                      </mat-form-field>&nbsp;&nbsp;&nbsp;&nbsp;
                    </td>
                  </tr>
                </table>
          </div>
          <div id="DivLateral">

          <table>
            <tr><h2>Concessão</h2></tr>
            <tr>
              <td>
                <mat-form-field appearance="fill">
                    <mat-label>Filtro Concessão</mat-label>
                    <mat-select (selectionChange)="filterbyplant()"  [(ngModel)]="selectOperation">
                      <mat-option *ngFor="let c of listDicOperations" [value]="c.valor">{{c.valor}}</mat-option>
                    </mat-select>
                  </mat-form-field>
              </td>
              <td style="padding-left:10px;">
                <button mat-raised-button color="primary" (click)="clearFilter()">
                    Limpar filtro
                  </button>
              </td>
            </tr>
          </table>
          </div>
        </div>  
        </mat-card>
      </div>

      <div class="refresh">
      <table style="width:100%">
        <tr>
          <td style="width:98%">
          </td>
          <td style="width:2%">
            <button style="margin: 5px;" mat-button (click)="refresh()">
              <mat-icon style="padding: 3px;cursor:pointer;" matTooltip="Atualizar página" matTooltipPosition="above">refresh</mat-icon>
            </button>
          </td>
        </tr>
      </table>
    </div>

    <div class="mainTable">
        <table mat-table [dataSource]="dataSource" matSort style="width: 100%;">
            <ng-container matColumnDef="timestamp">
                <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 5%;"> Última checagem </th>
                <td mat-cell *matCellDef="let row" class="tbaleTDStatus" style="width: 5%;"> {{row.timestamp}} </td>
            </ng-container>
        
            <ng-container matColumnDef="lastvalidconection">
                <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 5%;"> Última conexão válida </th>
                <td mat-cell *matCellDef="let row" class="tbaleTDStatus" style="width: 5%;"> {{row.lastvalidconection}} </td>
            </ng-container>
        
            <ng-container matColumnDef="concession">
                <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 5%;"> Concessão </th>
                <td mat-cell *matCellDef="let row" class="tbaleTDStatus" style="width: 5%;"> {{row.concession}} </td>
            </ng-container>
        
            <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 5%;"> Status </th>
                <td mat-cell *matCellDef="let row" class="tbaleTDStatus"> 
                    <img *ngIf="row.statuscolor==true" src="./assets/wifion.png">
                    <img *ngIf="row.statuscolor==false" src="./assets/wifi-off.png">    
                </td>
            </ng-container>
        
            <tr mat-header-row *matHeaderRowDef="displayedColumnsUsers"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumnsUsers;"></tr>
        </table>
    </div>
</div>

<div class="footer">
    <mat-paginator [length]="10" [pageSize]="10" [pageSizeOptions]="[5, 10, 15, 100, 200, 500]"></mat-paginator>
</div>

<div style="position: absolute;top:50%;left: 45%;" *ngIf="this.loading">
    <img style="margin: auto;padding-left: 65px;" src="./../../assets/loading.gif">
    <p style="color:#1D2C67;font-weight: bold;">Carrregando histórico</p>
  </div>