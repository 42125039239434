
<div class="grid-container">
  <h1 mat-dialog-title>
    <mat-icon style="display: inline-flex; vertical-align: middle;padding-bottom: 4px; padding-right: 6px;">group_add</mat-icon> Adicionar novo grupo
  </h1>
<mat-card>
  <mat-card-content>
    <table style="width: 100%;">
      <tr *ngIf="this.validategroup">
        <td style="border-bottom: 1px solid #E0E0E0;padding-bottom: 10px;">
          <mat-spinner  [diameter]="20" style="margin: auto"></mat-spinner> <b>Adicionando grupo</b>
        </td>
      </tr>
      <tr *ngIf="warning">
        <td style="border-bottom: 1px solid #E0E0E0;padding-bottom: 10px;">
          <b [ngClass]="{warningOn: addgroupError == true, warningOff: this.addgroupOk == true}">
            {{this.warningmessage}}
          </b>
        </td>
      </tr>
      <tr>
        <mat-form-field class="full-width-input">
          <input class="inputcolor" matInput placeholder="Nome grupo" [(ngModel)]="groupname" name="groupname">
        </mat-form-field>
      </tr>
      <tr>
        <mat-form-field class="full-width-input">
          <input class="inputcolor" matInput placeholder="Tensão" [(ngModel)]="tension" name="tension">
        </mat-form-field>
      </tr>
      <tr>
        <mat-form-field class="full-width-input">
          <input class="inputcolor" matInput placeholder="Comprimento LT" [(ngModel)]="ltcomp" name="ltcomp">
        </mat-form-field>
      </tr>
      <tr>
        <mat-form-field class="full-width-input">
          <input class="inputcolor" matInput placeholder="Categoria" [(ngModel)]="category" name="category">
        </mat-form-field>
      </tr>
    </table>

    <button mat-raised-button color="primary" type="submit" (click)="addGroup()">Adicionar</button>

  </mat-card-content>
</mat-card>
</div>

