import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';
import * as moment from 'moment';
import 'moment/locale/pt-br';
import {MatDialog} from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from 'src/environments/environment';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Injectable({
  providedIn: 'root'
})

export class ServiceService {

  public isLoged = false;
  public idUser;
  public fullname = '';
  public email = '';
  public user = '';
  public username = '';
  public isAdmin = false;
  public mostraPagina = true;
  public networkcountoff = [];
  public generalloading = false;
  public stoprefresh = false;
  public setrefresh = false;
  public settime = sessionStorage.setItem('time', moment(Date()).format('YYYY-MM-DD HH:mm:SS'));
  dateToday = moment(Date()).format('YYYY-DD-MM HH:mm:SS');
  interval;
  intervalsnackbar;
  counterinterval = 0;
  env = environment;

  constructor(
    private httpClient: HttpClient,
    public dialog: MatDialog,
    public router: Router,
    public snackBar: MatSnackBar) { }

  /* endPointDownload = 'http://localhost:81/';
  endPoint = 'http://localhost:82/'; */


  endPointDownload = 'http://172.20.186.10:81/';
  endPoint = this.env['endPoint'];
  endpointRoute = '';
  endpointBackend = 'backend/';
  /* endpointBackend = ''; */

  post_VerifyUser(username) {
    return new Observable(observer => {
      this.endpointRoute = this.endpointBackend + 'verifyuser';
      var json = JSON.stringify({
        username: username
      });
      var xhr = new XMLHttpRequest();
      xhr.open("POST", this.endpointRoute, true);
      xhr.setRequestHeader('Content-type', 'application/json; charset=utf-8');
      xhr.setRequestHeader('Authorization', 'Basic ' + this.env['hashback']);
      xhr.onload = function () {
        if (xhr.readyState == 4 && xhr.status == 200) {
          // console.table(xhr.readyState);
        } else {
          // console.error(xhr.readyState);
        }
        observer.next(JSON.parse(xhr.response));
        observer.complete();
      };
      xhr.send(json);
    });
  }

  post_VerifyPass(password) {
    return new Observable(observer => {
      this.endpointRoute = this.endpointBackend + 'verifypass';
      var json = JSON.stringify({
        password: password
      });
      var xhr = new XMLHttpRequest();
      xhr.open("POST", this.endpointRoute, true);
      xhr.setRequestHeader('Content-type', 'application/json; charset=utf-8');
      xhr.setRequestHeader('Authorization', 'Basic ' + this.env['hashback']);
      xhr.onload = function () {
        if (xhr.readyState == 4 && xhr.status == 200) {
          // console.table(xhr.readyState);
        } else {
          // console.error(xhr.readyState);
        }
        observer.next(JSON.parse(xhr.response));
        observer.complete();
      };
      xhr.send(json);
    });
  }

  post_updatePass(username, newpass, codeacess, changepass, dateuserupdatepass) {
    return new Observable(observer => {
      this.endpointRoute = this.endpointBackend + 'updatepass';
      var json = JSON.stringify({
        username: username,
        newpass: newpass,
        codeacess: codeacess,
        changepass: changepass,
        dateuserupdatepass: dateuserupdatepass
      });
      var xhr = new XMLHttpRequest();
      xhr.open("POST", this.endpointRoute, true);
      xhr.setRequestHeader('Content-type', 'application/json; charset=utf-8');
      xhr.setRequestHeader('Authorization', 'Basic ' + this.env['hashback']);
      xhr.onload = function () {
        if (xhr.readyState == 4 && xhr.status == 200) {
          //console.table(users);
        } else {
          // console.error(users);
        }
        observer.next(JSON.parse(xhr.response));
        observer.complete();
      }
      xhr.send(json);
    });
  }

  post_updatePassForget(email, newpass) {
    return new Observable(observer => {
      this.endpointRoute = this.endpointBackend + 'updatepassforget';
      var json = JSON.stringify({
        email: email,
        newpass: newpass
      });
      var xhr = new XMLHttpRequest();
      xhr.open("POST", this.endpointRoute, true);
      xhr.setRequestHeader('Content-type', 'application/json; charset=utf-8');
      xhr.setRequestHeader('Authorization', 'Basic ' + this.env['hashback']);
      xhr.onload = function () {
        if (xhr.readyState == 4 && xhr.status == 200) {
          //console.table(users);
        } else {
          // console.error(users);
        }
        observer.next(JSON.parse(xhr.response));
        observer.complete();
      }
      xhr.send(json);
    });
  }

  post_ListOsc(favorite, initialdate, finaldate, equip, subest, concessao, linha, user) {
    return new Observable(observer => {
      this.endpointRoute = this.endpointBackend + 'listaOscilografias';
      var json = JSON.stringify({
        favoritos: favorite,
        dataInicial: initialdate,
        dataFinal: finaldate,
        Equipamento: equip,
        Subestacao: subest,
        Concessao: concessao,
        Linha: linha,
        user: user
      });
      var xhr = new XMLHttpRequest();
      xhr.open("POST", this.endpointRoute, true);
      xhr.setRequestHeader('Content-type', 'application/json; charset=utf-8');
      xhr.setRequestHeader('Authorization', 'Basic ' + this.env['hashback']);
      xhr.onload = function () {
        if (xhr.readyState == 4 && xhr.status == 200) {
          //console.table(users);
        } else {
          // console.error(users);
        }
        observer.next(JSON.parse(xhr.response));
        observer.complete();
      }
      xhr.send(json);
    });
  }

  post_ChartOsc(concessao, subestacao, equipamento, arquivo) {
    return new Observable(observer => {
      this.endpointRoute = this.endpointBackend + 'convertosc';
      var json = JSON.stringify({
        concessao: concessao,
        subestacao: subestacao,
        equipamento: equipamento,
        arquivo: arquivo
      });
      var xhr = new XMLHttpRequest();
      xhr.open("POST", this.endpointRoute, true);
      xhr.setRequestHeader('Content-type', 'application/json; charset=utf-8');
      xhr.setRequestHeader('Authorization', 'Basic ' + this.env['hashback']);
      xhr.onload = function () {
        if (xhr.readyState == 4 && xhr.status == 200) {
          //console.table(users);
        } else {
          // console.error(users);
        }
        observer.next(JSON.parse(xhr.response));
        observer.complete();
      }
      xhr.send(json);
    });
  }

  post_AddUser(username, fullname, email, isadmin, randompass, randompasscoded, datecreateuser, oscbyemail, networkbyemail) {
    return new Observable(observer => {
      this.endpointRoute = this.endpointBackend + 'adduser';
      var json = JSON.stringify({
        username: username,
        fullname: fullname,
        email: email,
        isadmin: isadmin,
        randompass: randompass,
        randompasscoded: randompasscoded,
        datecreateuser: datecreateuser,
        oscbyemail: oscbyemail,
        networkbyemail: networkbyemail
      });
      console.log(json)
      var xhr = new XMLHttpRequest();
      xhr.open("POST", this.endpointRoute, true);
      xhr.setRequestHeader('Content-type', 'application/json; charset=utf-8');
      xhr.setRequestHeader('Authorization', 'Basic ' + this.env['hashback']);
      xhr.onload = function () {
        if (xhr.readyState == 4 && xhr.status == 200) {
          //console.table(users);
        } else {
          // console.error(users);
        }
        observer.next(JSON.parse(xhr.response));
        observer.complete();
      }
      xhr.send(json);
    });
  }

  post_EditUser(iduser, username, fullname, email, password, groups, changepass, oscbyemail, networkbyemail) {
    return new Observable(observer => {
      this.endpointRoute = this.endpointBackend + 'edituser';
      var json = JSON.stringify({
        iduser: iduser,
        username: username,
        fullname: fullname,
        email: email,
        password: password,
        groups: groups,
        changepass: changepass,
        oscbyemail: oscbyemail,
        networkbyemail: networkbyemail
      });
      var xhr = new XMLHttpRequest();
      xhr.open("POST", this.endpointRoute, true);
      xhr.setRequestHeader('Content-type', 'application/json; charset=utf-8');
      xhr.setRequestHeader('Authorization', 'Basic ' + this.env['hashback']);
      xhr.onload = function () {
        if (xhr.readyState == 4 && xhr.status == 200) {
          //console.table(users);
        } else {
          // console.error(users);
        }
        observer.next(JSON.parse(xhr.response));
        observer.complete();
      }
      xhr.send(json);
    });
  }

  post_CheckExistUser(username, email) {
    return new Observable(observer => {
      this.endpointRoute = this.endpointBackend + 'checkexistuser';
      var json = JSON.stringify({
        username: username,
        email: email
      });
      var xhr = new XMLHttpRequest();
      xhr.open("POST", this.endpointRoute, true);
      xhr.setRequestHeader('Content-type', 'application/json; charset=utf-8');
      xhr.setRequestHeader('Authorization', 'Basic ' + this.env['hashback']);
      xhr.onload = function () {
        if (xhr.readyState == 4 && xhr.status == 200) {
          //console.table(users);
        } else {
          // console.error(users);
        }
        observer.next(JSON.parse(xhr.response));
        observer.complete();
      }
      xhr.send(json);
    });
  }

  post_DeleteUser(iduser, statususer) {
    return new Observable(observer => {
      this.endpointRoute = this.endpointBackend + 'deleteUser';
      var json = JSON.stringify({
        iduser: iduser,
        statususer: statususer
      });
      var xhr = new XMLHttpRequest();
      xhr.open("POST", this.endpointRoute, true);
      xhr.setRequestHeader('Content-type', 'application/json; charset=utf-8');
      xhr.setRequestHeader('Authorization', 'Basic ' + this.env['hashback']);
      xhr.onload = function () {
        if (xhr.readyState == 4 && xhr.status == 200) {
          //console.table(users);
        } else {
          // console.error(users);
        }
        observer.next(JSON.parse(xhr.response));
        observer.complete();
      }
      xhr.send(json);
    });
  }

  post_DeleteUserAdm(iduser) {
    return new Observable(observer => {
      this.endpointRoute = this.endpointBackend + 'deleteuseradm';
      var json = JSON.stringify({
        iduser: iduser
      });
      var xhr = new XMLHttpRequest();
      xhr.open("POST", this.endpointRoute, true);
      xhr.setRequestHeader('Content-type', 'application/json; charset=utf-8');
      xhr.setRequestHeader('Authorization', 'Basic ' + this.env['hashback']);
      xhr.onload = function () {
        if (xhr.readyState == 4 && xhr.status == 200) {
          //console.table(users);
        } else {
          // console.error(users);
        }
        observer.next(JSON.parse(xhr.response));
        observer.complete();
      }
      xhr.send(json);
    });
  }

  post_BlockUser(username, currentdate, status) {
    return new Observable(observer => {
      this.endpointRoute = this.endpointBackend + 'blockuser';
      var json = JSON.stringify({
        username: username,
        currentdate: currentdate,
        status: status
      });
      var xhr = new XMLHttpRequest();
      xhr.open("POST", this.endpointRoute, true);
      xhr.setRequestHeader('Content-type', 'application/json; charset=utf-8');
      xhr.setRequestHeader('Authorization', 'Basic ' + this.env['hashback']);
      xhr.onload = function () {
        if (xhr.readyState == 4 && xhr.status == 200) {
          //console.table(users);
        } else {
          // console.error(users);
        }
        observer.next(JSON.parse(xhr.response));
        observer.complete();
      }
      xhr.send(json);
    });
  }

  post_AddUserByGroups(iduser, idgroup) {
    return new Observable(observer => {
      this.endpointRoute = this.endpointBackend + 'addgroupbyuser';
      var json = JSON.stringify({
        iduser: iduser,
        idgroup: idgroup
      });
      var xhr = new XMLHttpRequest();
      xhr.open("POST", this.endpointRoute, true);
      xhr.setRequestHeader('Content-type', 'application/json; charset=utf-8');
      xhr.setRequestHeader('Authorization', 'Basic ' + this.env['hashback']);
      xhr.onload = function () {
        if (xhr.readyState == 4 && xhr.status == 200) {
          //console.table(users);
        } else {
          // console.error(users);
        }
        observer.next(JSON.parse(xhr.response));
        observer.complete();
      }
      xhr.send(json);
    });
  }

  post_ListUsers() {
    return new Observable(observer => {
      this.endpointRoute = this.endpointBackend + 'listaUsers';
      var json = JSON.stringify({
        username: ''
      });
      var xhr = new XMLHttpRequest();
      xhr.open("POST", this.endpointRoute, true);
      xhr.setRequestHeader('Content-type', 'application/json; charset=utf-8');
      xhr.setRequestHeader('Authorization', 'Basic ' + this.env['hashback']);
      xhr.onload = function () {
        if (xhr.readyState == 4 && xhr.status == 200) {
          console.table(xhr.readyState);
        } else {
          console.error(xhr.readyState);
        }
        observer.next(JSON.parse(xhr.response));
        observer.complete();
      };
      xhr.send(json);
    });
  }

  post_ListGroups() {
    return new Observable(observer => {
      this.endpointRoute = this.endpointBackend + 'listaGroups';
      var json = JSON.stringify({
        username: ''
      });
      var xhr = new XMLHttpRequest();
      xhr.open("POST", this.endpointRoute, true);
      xhr.setRequestHeader('Content-type', 'application/json; charset=utf-8');
      xhr.setRequestHeader('Authorization', 'Basic ' + this.env['hashback']);
      xhr.onload = function () {
        if (xhr.readyState == 4 && xhr.status == 200) {
          console.table(xhr.readyState);
        } else {
          console.error(xhr.readyState);
        }
        observer.next(JSON.parse(xhr.response));
        observer.complete();
      };
      xhr.send(json);
    });
  }

  post_ListFiltersByPlant(iduser) {
    return new Observable(observer => {
      this.endpointRoute = this.endpointBackend + 'listfilterbyplant';
      var json = JSON.stringify({
        iduser: iduser
      });
      var xhr = new XMLHttpRequest();
      xhr.open("POST", this.endpointRoute, true);
      xhr.setRequestHeader('Content-type', 'application/json; charset=utf-8');
      xhr.setRequestHeader('Authorization', 'Basic ' + this.env['hashback']);
      xhr.onload = function () {
        if (xhr.readyState == 4 && xhr.status == 200) {
          //console.table(users);
        } else {
          // console.error(users);
        }
        observer.next(JSON.parse(xhr.response));
        observer.complete();
      }
      xhr.send(json);
    });
  }

  post_ListAllFilters(iduser, type, concessaofiltered) {
    return new Observable(observer => {
      this.endpointRoute = this.endpointBackend + 'listAllFilters';
      var json = JSON.stringify({
        iduser: iduser,
        type: type,
        concessaofiltered: concessaofiltered
      });
      var xhr = new XMLHttpRequest();
      xhr.open("POST", this.endpointRoute, true);
      xhr.setRequestHeader('Content-type', 'application/json; charset=utf-8');
      xhr.setRequestHeader('Authorization', 'Basic ' + this.env['hashback']);
      xhr.onload = function () {
        if (xhr.readyState == 4 && xhr.status == 200) {
          //console.table(users);
        } else {
          // console.error(users);
        }
        observer.next(JSON.parse(xhr.response));
        observer.complete();
      }
      xhr.send(json);
    });
  }

  post_ListGroupByUser(iduser) {
    return new Observable(observer => {
      this.endpointRoute = this.endpointBackend + 'listaGroupsByUser';
      var json = JSON.stringify({
        iduser: iduser
      });
      var xhr = new XMLHttpRequest();
      xhr.open("POST", this.endpointRoute, true);
      xhr.setRequestHeader('Content-type', 'application/json; charset=utf-8');
      xhr.setRequestHeader('Authorization', 'Basic ' + this.env['hashback']);
      xhr.onload = function () {
        if (xhr.readyState == 4 && xhr.status == 200) {
          //console.table(users);
        } else {
          // console.error(users);
        }
        observer.next(JSON.parse(xhr.response));
        observer.complete();
      }
      xhr.send(json);
    });
  }

  post_AddGroup(groupname, tension, ltcomp, category) {
    return new Observable(observer => {
      this.endpointRoute = this.endpointBackend + 'addgroup';
      var json = JSON.stringify({
        groupname: groupname,
        tension: tension,
        ltcomp: ltcomp,
        category: category
      });
      var xhr = new XMLHttpRequest();
      xhr.open("POST", this.endpointRoute, true);
      xhr.setRequestHeader('Content-type', 'application/json; charset=utf-8');
      xhr.setRequestHeader('Authorization', 'Basic ' + this.env['hashback']);
      xhr.onload = function () {
        if (xhr.readyState == 4 && xhr.status == 200) {
          //console.table(users);
        } else {
          // console.error(users);
        }
        observer.next(JSON.parse(xhr.response));
        observer.complete();
      }
      xhr.send(json);
    });
  }

  post_addFavorite(iduser, file, typerequest) {
    return new Observable(observer => {
      this.endpointRoute = this.endpointBackend + 'addfavorite';
      var json = JSON.stringify({
        iduser: iduser,
        file: file,
        typerequest
      });
      var xhr = new XMLHttpRequest();
      xhr.open("POST", this.endpointRoute, true);
      xhr.setRequestHeader('Content-type', 'application/json; charset=utf-8');
      xhr.setRequestHeader('Authorization', 'Basic ' + this.env['hashback']);
      xhr.onload = function () {
        if (xhr.readyState == 4 && xhr.status == 200) {
          //console.table(users);
        } else {
          // console.error(users);
        }
        observer.next(JSON.parse(xhr.response));
        observer.complete();
      }
      xhr.send(json);
    });
  }

  post_VerificaDownload(obj) {
    return new Observable(observer => {
      this.endpointRoute = this.endpointBackend + 'VerificaDownload';
      var json = JSON.stringify({
        info: obj
      });
      var xhr = new XMLHttpRequest();
      xhr.open("POST", this.endPointDownload + this.endpointRoute, true);
      xhr.setRequestHeader('Content-type', 'application/json; charset=utf-8');
      xhr.setRequestHeader('Authorization', 'Basic ' + this.env['hashback']);
      xhr.onload = function () {
        if (xhr.readyState == 4 && xhr.status == 200) {
          //console.table(users);
        } else {
          // console.error(users);
        }
        observer.next(JSON.parse(xhr.response));
        observer.complete();
      }
      xhr.send(json);
    });
  }

  post_addFilesToDownload(obj) {
    return new Observable(observer => {
      this.endpointRoute = this.endpointBackend + 'addfilestodownload';
      var json = JSON.stringify({
        info: obj
      });
      var xhr = new XMLHttpRequest();
      xhr.open("POST", this.endpointRoute, true);
      xhr.setRequestHeader('Content-type', 'application/json; charset=utf-8');
      xhr.setRequestHeader('Authorization', 'Basic ' + this.env['hashback']);
      xhr.onload = function () {
        if (xhr.readyState == 4 && xhr.status == 200) {
          // console.table('PASSEI');
        } else {
          // console.error('DEU ERRO');
        }
        observer.next(JSON.parse(xhr.response));
        observer.complete();
      }
      xhr.send(json);
    });
  }

  post_downloadFileToChart(obj) {
    return new Observable(observer => {
      this.endpointRoute = this.endpointBackend + 'downloadfiletochart';
      var json = JSON.stringify({
        info: obj
      });
      var xhr = new XMLHttpRequest();
      xhr.open("POST", this.endpointRoute, true);
      xhr.setRequestHeader('Content-type', 'application/json; charset=utf-8');
      xhr.setRequestHeader('Authorization', 'Basic ' + this.env['hashback']);
      xhr.onload = function () {
        if (xhr.readyState == 4 && xhr.status == 200) {
          // console.table('PASSEI');
        } else {
          // console.error('DEU ERRO');
        }
        observer.next(JSON.parse(xhr.response));
        observer.complete();
      }
      xhr.send(json);
    });
  }

  post_addFilesToChart(concessao, subestacao, equipamento, arquivo) {
    return new Observable(observer => {
      this.endpointRoute = this.endpointBackend + 'addfilestochart';
      var json = JSON.stringify({
        concessao: concessao,
        subestacao: subestacao,
        equipamento: equipamento,
        arquivo: arquivo
      });
      var xhr = new XMLHttpRequest();
      xhr.open("POST", this.endpointRoute, true);
      xhr.setRequestHeader('Content-type', 'application/json; charset=utf-8');
      xhr.setRequestHeader('Authorization', 'Basic ' + this.env['hashback']);
      xhr.onload = function () {
        if (xhr.readyState == 4 && xhr.status == 200) {
          //console.table('PASSEI');
        } else {
          //console.error('DEU ERRO');
        }
        observer.next(JSON.parse(xhr.response));
        observer.complete();
      }
      xhr.send(json);
    });
  }

  post_forgetPass(email, randompass, randompasscoded) {
    return new Observable(observer => {
      this.endpointRoute = this.endpointBackend + 'forgetpass';
      var json = JSON.stringify({
        email: email,
        randompass: randompass,
        randompasscoded: randompasscoded
      });
      var xhr = new XMLHttpRequest();
      xhr.open("POST", this.endpointRoute, true);
      xhr.setRequestHeader('Content-type', 'application/json; charset=utf-8');
      xhr.setRequestHeader('Authorization', 'Basic ' + this.env['hashback']);
      xhr.onload = function () {
        if (xhr.readyState == 4 && xhr.status == 200) {
          //console.table('PASSEI');
        } else {
          //console.error('DEU ERRO');
        }
        observer.next(JSON.parse(xhr.response));
        observer.complete();
      }
      xhr.send(json);
    });
  }

  post_CheckAccessCode(accesscode, username, changepass) {
    return new Observable(observer => {
      this.endpointRoute = this.endpointBackend + 'checkcode';
      var json = JSON.stringify({
        accesscode: accesscode,
        username: username,
        changepass: changepass
      });

      var xhr = new XMLHttpRequest();
      xhr.open("POST", this.endpointRoute, true);
      xhr.setRequestHeader('Content-type', 'application/json; charset=utf-8');
      xhr.setRequestHeader('Authorization', 'Basic ' + this.env['hashback']);
      xhr.onload = function () {
        if (xhr.readyState == 4 && xhr.status == 200) {
          //console.table('PASSEI');
        } else {
          //console.error('DEU ERRO');
        }
        observer.next(JSON.parse(xhr.response));
        observer.complete();
      }
      xhr.send(json);
    });
  }

  post_PassUser(username, fullname, email) {
    return new Observable(observer => {
      this.endpointRoute = this.endpointBackend + 'passuser';
      var json = JSON.stringify({
        username: username,
        fullname: fullname,
        email: email
      });
      var xhr = new XMLHttpRequest();
      xhr.open("POST", this.endpointRoute, true);
      xhr.setRequestHeader('Content-type', 'application/json; charset=utf-8');
      xhr.setRequestHeader('Authorization', 'Basic ' + this.env['hashback']);
      xhr.onload = function () {
        if (xhr.readyState == 4 && xhr.status == 200) {
          // console.table(xhr.readyState);
        } else {
          // console.error(xhr.readyState);
        }
        observer.next(JSON.parse(xhr.response));
        observer.complete();
      };
      xhr.send(json);
    });
  }

  post_UpdateLastLogin(username, fullname, lastlogin) {
    return new Observable(observer => {
      this.endpointRoute = this.endpointBackend + 'updatelastlogin';
      var json = JSON.stringify({
        username: username,
        fullname: fullname,
        lastlogin: lastlogin
      });
      var xhr = new XMLHttpRequest();
      xhr.open("POST", this.endpointRoute, true);
      xhr.setRequestHeader('Content-type', 'application/json; charset=utf-8');
      xhr.setRequestHeader('Authorization', 'Basic ' + this.env['hashback']);
      xhr.onload = function () {
        if (xhr.readyState == 4 && xhr.status == 200) {
          // console.table(xhr.readyState);
        } else {
          // console.error(xhr.readyState);
        }
        observer.next(JSON.parse(xhr.response));
        observer.complete();
      };
      xhr.send(json);
    });
  }

  post_AddToOperationHistory(iduser, idoperation) {
    return new Observable(observer => {
      this.endpointRoute = this.endpointBackend + 'addtooperationhistory';
      var json = JSON.stringify({
        iduser: iduser,
        idoperation: idoperation,
        dateoperation: this.dateToday
      });
      var xhr = new XMLHttpRequest();
      xhr.open("POST", this.endpointRoute, true);
      xhr.setRequestHeader('Content-type', 'application/json; charset=utf-8');
      xhr.setRequestHeader('Authorization', 'Basic ' + this.env['hashback']);
      xhr.onload = function () {
        if (xhr.readyState == 4 && xhr.status == 200) {
          // console.table(xhr.readyState);
        } else {
          // console.error(xhr.readyState);
        }
        observer.next(JSON.parse(xhr.response));
        observer.complete();
      };
      xhr.send(json);
    });
  }

  post_HistorySystemOperation() {
    return new Observable(observer => {
      this.endpointRoute = this.endpointBackend + 'historysystemoperation';
      var json = JSON.stringify({
        username: ''
      });
      var xhr = new XMLHttpRequest();
      xhr.open("POST", this.endpointRoute, true);
      xhr.setRequestHeader('Content-type', 'application/json; charset=utf-8');
      xhr.setRequestHeader('Authorization', 'Basic ' + this.env['hashback']);
      xhr.onload = function () {
        if (xhr.readyState == 4 && xhr.status == 200) {
          // console.table(xhr.readyState);
        } else {
          // console.error(xhr.readyState);
        }
        observer.next(JSON.parse(xhr.response));
        observer.complete();
      };
      xhr.send(json);
    });
  }

  post_DictionaryOperations() {
    return new Observable(observer => {
      this.endpointRoute = this.endpointBackend + 'dictionaryoperation';
      var json = JSON.stringify({
        username: ''
      });
      var xhr = new XMLHttpRequest();
      xhr.open("POST", this.endpointRoute, true);
      xhr.setRequestHeader('Content-type', 'application/json; charset=utf-8');
      xhr.setRequestHeader('Authorization', 'Basic ' + this.env['hashback']);
      xhr.onload = function () {
        if (xhr.readyState == 4 && xhr.status == 200) {
          console.table(xhr.readyState);
        } else {
          console.error(xhr.readyState);
        }
        observer.next(JSON.parse(xhr.response));
        observer.complete();
      };
      xhr.send(json);
    });
  }

  post_EmailServiceResponse(email) {
    return new Observable(observer => {
      this.endpointRoute = this.endpointBackend + 'emailserviceresponse';
      var json = JSON.stringify({
        email: email
      });
      var xhr = new XMLHttpRequest();
      xhr.open("POST", this.endpointRoute, true);
      xhr.setRequestHeader('Content-type', 'application/json; charset=utf-8');
      xhr.setRequestHeader('Authorization', 'Basic ' + this.env['hashback']);
      xhr.onload = function () {
        if (xhr.readyState == 4 && xhr.status == 200) {
          console.table(xhr.readyState);
        } else {
          console.error(xhr.readyState);
        }
        observer.next(JSON.parse(xhr.response));
        observer.complete();
      };
      xhr.send(json);
    });
  }

  post_UpdateEmailServiceResponse(email) {
    return new Observable(observer => {
      this.endpointRoute = this.endpointBackend + 'updateemailserviceresponse';
      var json = JSON.stringify({
        email: email
      });
      var xhr = new XMLHttpRequest();
      xhr.open("POST", this.endpointRoute, true);
      xhr.setRequestHeader('Content-type', 'application/json; charset=utf-8');
      xhr.setRequestHeader('Authorization', 'Basic ' + this.env['hashback']);
      xhr.onload = function () {
        if (xhr.readyState == 4 && xhr.status == 200) {
          console.table(xhr.readyState);
        } else {
          console.error(xhr.readyState);
        }
        observer.next(JSON.parse(xhr.response));
        observer.complete();
      };
      xhr.send(json);
    });
  }

  get_histalarmevents() {
    return new Observable(observer => {
      this.endpointRoute = this.endpointBackend + 'historicalarmevents';
      const xhr = new XMLHttpRequest();
      xhr.open("GET", this.endpointRoute, true);
      xhr.setRequestHeader('Content-type', 'application/json; charset=utf-8');
      xhr.setRequestHeader('Authorization', 'Basic ' + this.env['hashback']);
      xhr.onload = () => {
        if (xhr.status == 200) {
            // console.log(JSON.parse(xhr.response));
        } else {
            console.error('Error!');
        }
        observer.next(JSON.parse(xhr.response));
        observer.complete();
      };
      //xhr.open('GET', this.endpointRoute);
      xhr.send();
    });
  }

  get_serveremailcredential() {
    return new Observable(observer => {
      this.endpointRoute = this.endpointBackend + 'serveremailcredential';
      const xhr = new XMLHttpRequest();
      xhr.open("GET", this.endpointRoute, true);
      xhr.setRequestHeader('Content-type', 'application/json; charset=utf-8');
      xhr.setRequestHeader('Authorization', 'Basic ' + this.env['hashback']);
      xhr.onload = () => {
        if (xhr.status == 200) {
            // console.log(JSON.parse(xhr.response));
        } else {
            console.error('Error!');
        }
        observer.next(JSON.parse(xhr.response));
        observer.complete();
      };
      //xhr.open('GET', this.endpointRoute);
      xhr.send();
    });
  }

  post_serveremailcredential(server, emailclient, emailport, emailuser, emailpass, emailssl) {
    return new Observable(observer => {
      this.endpointRoute = this.endpointBackend + 'editserveremailcredential';
      var json = JSON.stringify({
        server: server,
        emailclient: emailclient,
        emailport: emailport,
        emailuser: emailuser,
        emailpass: emailpass, 
        emailssl: emailssl
      });
      var xhr = new XMLHttpRequest();
      xhr.open("POST", this.endpointRoute, true);
      xhr.setRequestHeader('Content-type', 'application/json; charset=utf-8');
      xhr.setRequestHeader('Authorization', 'Basic ' + this.env['hashback']);
      xhr.onload = function () {
        if (xhr.readyState == 4 && xhr.status == 200) {
          console.table(xhr.readyState);
        } else {
          console.error(xhr.readyState);
        }
        observer.next(JSON.parse(xhr.response));
        observer.complete();
      };
      xhr.send(json);
    });
  }


  post_histalarmeventsbyfilter(plant) {
    return new Observable(observer => {
      this.endpointRoute = this.endpointBackend + 'historicalarmeventsbyfilter';
      var json = JSON.stringify({
        plant: plant
      });
      var xhr = new XMLHttpRequest();
      xhr.open("POST", this.endpointRoute, true);
      xhr.setRequestHeader('Content-type', 'application/json; charset=utf-8');
      xhr.setRequestHeader('Authorization', 'Basic ' + this.env['hashback']);
      xhr.onload = function () {
        if (xhr.readyState == 4 && xhr.status == 200) {
          console.table(xhr.readyState);
        } else {
          console.error(xhr.readyState);
        }
        observer.next(JSON.parse(xhr.response));
        observer.complete();
      };
      xhr.send(json);
    });
  }

  post_HistoryNetwork(plant) {
    return new Observable(observer => {
      this.endpointRoute = this.endpointBackend + 'historicalnetwork';
      var json = JSON.stringify({
        plant: plant
      });
      var xhr = new XMLHttpRequest();
      xhr.open("POST", this.endpointRoute, true);
      xhr.setRequestHeader('Content-type', 'application/json; charset=utf-8');
      xhr.setRequestHeader('Authorization', 'Basic ' + this.env['hashback']);
      xhr.onload = function () {
        if (xhr.readyState == 4 && xhr.status == 200) {
          console.table(xhr.readyState);
        } else {
          console.error(xhr.readyState);
        }
        observer.next(JSON.parse(xhr.response));
        observer.complete();
      };
      xhr.send(json);
    });
  }

  post_userConditionToEmail(iduser) {
    return new Observable(observer => {
      this.endpointRoute = this.endpointBackend + 'userconditiontoemail';
      var json = JSON.stringify({
        iduser: iduser
      });
      var xhr = new XMLHttpRequest();
      xhr.open("POST", this.endpointRoute, true);
      xhr.setRequestHeader('Content-type', 'application/json; charset=utf-8');
      xhr.setRequestHeader('Authorization', 'Basic ' + this.env['hashback']);
      xhr.onload = function () {
        if (xhr.readyState == 4 && xhr.status == 200) {
          console.table(xhr.readyState);
        } else {
          console.error(xhr.readyState);
        }
        observer.next(JSON.parse(xhr.response));
        observer.complete();
      };
      xhr.send(json);
    });
  }

  post_NetworkStatus(iduser) {
    return new Observable(observer => {
      this.endpointRoute = this.endpointBackend + 'networkstatus';
      var json = JSON.stringify({
        iduser: iduser
      });
      var xhr = new XMLHttpRequest();
      xhr.open("POST", this.endpointRoute, true);
      xhr.setRequestHeader('Content-type', 'application/json; charset=utf-8');
      xhr.setRequestHeader('Authorization', 'Basic ' + this.env['hashback']);
      xhr.onload = function () {
        if (xhr.readyState == 4 && xhr.status == 200) {
          console.table(xhr.readyState);
        } else {
          console.error(xhr.readyState);
        }
        observer.next(JSON.parse(xhr.response));
        observer.complete();
      };
      xhr.send(json);
    });
  }

  get_NetworkStatus() {
    return new Observable(observer => {
      this.endpointRoute = this.endpointBackend + 'networkstatus';
      const xhr = new XMLHttpRequest();
      xhr.open("GET", this.endpointRoute, true);
      xhr.onload = () => {
        if (xhr.status == 200) {
            // console.log(JSON.parse(xhr.response));
        } else {
            console.error('Error!');
        }
        observer.next(JSON.parse(xhr.response));
        observer.complete();
      };
      //xhr.open('GET', this.endpointRoute);
      xhr.send();
    });
  }

  public counterexpiredsession() {
    this.interval = setInterval(() => {
      this.counterinterval = this.counterinterval + 1;
    }, 1000);
  }

  public sessionExpired() {
    console.log(this.counterinterval);
    if (this.counterinterval > 900) {
      this.snackBar.open('Sessão expirada. Efetue o login novamente.', '', {
        duration: 5000,
        verticalPosition: 'top',
        horizontalPosition : 'center',
        panelClass: ['snackbarError']
      });

      this.intervalsnackbar = setInterval(() => {
        location.reload();
        clearInterval(this.intervalsnackbar);
      }, 2000);
            
    }
  }

  stopInterval() {
    console.log('Atingi a session');
    clearInterval(this.interval);
  }

public exportAsExcelFile(json: any[], excelFileName: string, sheetname: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const workbook: XLSX.WorkBook = { Sheets: { [sheetname]: worksheet }, SheetNames: [sheetname] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, excelFileName, sheetname);
  }

  private saveAsExcelFile(buffer: any, fileName: string, sheetname: string): void {
    const data: Blob = new Blob([buffer], {type: EXCEL_TYPE});
    FileSaver.saveAs(data, fileName + '_' + this.dateToday + EXCEL_EXTENSION);
    this.generalloading = false;
  }
}
