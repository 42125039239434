
<div *ngIf="this.service.mostraPagina" class="mainDiv">
  <div class="login">
    <mat-tab-group style="border-radius: 10px;">
      <mat-tab *ngIf="hideTab1">
        <div style="width: auto;height: 350px;overflow: hidden;">
          <div class="user">
            <mat-form-field style="width: 70%;overflow: hidden;">
              <mat-label>Matrícula</mat-label>
              <input matInput style="width: 200px" [value]="username" (input)="username=$event.target.value" (keydown.enter)="showTab(0)">
            </mat-form-field>
          </div>
          <div class="lostpass">
            <span class="forgetPass">
              <a class="forgetPassBtn" (click)="openDialogNewPass()">
                Esqueci minha senha
              </a>
            </span>
          </div>
          <div class="buttonnext">
            <button mat-raised-button color="primary" type="submit" (click)="showTab(0)">Próximo</button>
          </div>
        </div>
      </mat-tab>
      <mat-tab *ngIf="hideTab2">
        <ng-template mat-tab-label>
          <mat-icon (click)="showTab(2)" class="example-tab-icon">keyboard_backspace</mat-icon>
        </ng-template>
        <div style="width: auto;height: 350px;overflow: hidden;">
          <div class="user">
            <mat-form-field style="width: 70%;;overflow: hidden;">
              <mat-label>Senha</mat-label>
              <input matInput style="width: 200px" [value]="password" type="password" (input)="password=$event.target.value" (keydown.enter)="showTab(1)" autofocus>
            </mat-form-field>
          </div>
          <div class="lostpass">
            <span class="forgetPass">
              <a class="forgetPassBtn" (click)="openDialogNewPass()">
                Esqueci minha senha
              </a>
            </span>
          </div>
          <div class="buttonnext">
            <button [disabled]="this.blockButton" mat-raised-button color="primary" type="submit" (click)="showTab(1)">Próximo</button>
          </div>
        </div>
      </mat-tab>
    </mat-tab-group>

    <div class="logo">
      <p>
        <img src="./../../assets/sicarflogin.png">
      </p>
    </div>

    <div *ngIf="showUsername" style="position: absolute;width: 100%;left: 32%;right: 10%;top:35%;">
      <table>
        <tr>
          <td>
            <mat-icon>person_outline</mat-icon>
          </td>
          <td>
            <p class="userName">
              {{this.fullname}}
            </p>
          </td>
        </tr>
      </table>
    </div>

    <div *ngIf="this.loading" style="position: absolute;width: auto;right: 1%;top:13%;">
      <img src="./../../assets/loading.gif">
    </div>

    <div class="warning" *ngIf="this.flagwarning">
      <span class="alertmessage">{{this.warning}}</span>
    </div>
  </div>

</div>
