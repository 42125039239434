 <div *ngIf="loading" class="divLoading">
  <br><br><br>
  <img style="margin: auto;padding-left: 50px;" src="./../../assets/loading.gif">
  <p style="color:#1D2C67;font-weight: bold;">Carregando gráfico</p>
</div>

<div class="divLoading" *ngIf="this.showFlagError">
  <br><br><br>
  <p style="color:#1D2C67;font-weight: bold;">Não foi possível carregar o gráfico.</p>
</div>

<div id="chartOsc" style="width: 100%;">
  <table style="width: 100%;">
    <tr>
      <td class="title">
        <div style="position: absolute;left: 20px;top:20px;width: 100%;" *ngIf="!loading">
          <table style="font-size: 12px;width: auto;">
            <tr>
              <td><b>Concessão:</b></td>
              <td>{{this.datadialog.concessao}}</td>
            </tr>
            <tr>
              <td><b>Subestação: </b></td>
              <td>{{this.datadialog.subestacao}}</td>
            </tr>
            <tr>
              <td><b>Equipamento: </b></td>
              <td>{{this.datadialog.equipamento}}</td>
            </tr>
          </table>
        </div>
      </td>
      <td>
        <div style="position: absolute;left: 240px;top:20px;width: 100%;z-index: 2000;" *ngIf="!loading">
          <table style="font-size: 12px;width: auto;">
            <tr>
              <td><b>Data da oscilografia: </b></td>
              <td>{{this.infoOscStartTime}}</td>
            </tr>
            <tr>
              <td><b>Trigger time:</b></td>
              <td>{{this.triggertime}} s</td>
            </tr>
            <tr>
              <td><b>Frequência: </b></td>
              <td>{{this.infoOscFrequency}}</td>
            </tr>
          </table>
        </div>
      </td>
    </tr>
    <br><br><br><br><br><br>
    <tr>
      <td style="width: 100%;">
        <div *ngIf="this.chartprincipalflag" echarts [options]="options" class="chart"></div>

        <div *ngIf="!this.chartprincipalflag" cdkDropList class="example-list" (cdkDropListDropped)="drop($event)">
          <div *ngFor="let variable of this.chartVariablesName, let i = index" cdkDrag>
            <div *ngIf="variable.status" echarts [options]="variable.listvariables[0]" class="chartbytag">
            </div>
          </div>
          <br>
        </div>
        <br>
        <div id="chart" *ngIf="this.showDigitalChart">
          <apx-chart
            [series]="chartOptions.series"
            [chart]="chartOptions.chart"
            [plotOptions]="chartOptions.plotOptions"
            [xaxis]="chartOptions.xaxis"
          ></apx-chart>
        </div>

        <div id="chartNotOn" *ngIf="this.showDigitalChart">
          <apx-chart
          [series]="chartOptionsNotOn.series"
          [chart]="chartOptionsNotOn.chart"
          [plotOptions]="chartOptionsNotOn.plotOptions"
          [xaxis]="chartOptionsNotOn.xaxis"
        ></apx-chart>
        </div>
      </td>
    </tr>
  </table>
</div>

<div style="position: absolute;top:15px;right: 180px;width: 25%;z-index: 2000;" *ngIf="!loading">
  <table style="width: 100%;">
    <tr>
      <td>
        <mat-accordion class="example-headers-align" multi>
          <mat-expansion-panel #mep="matExpansionPanel">
            <mat-expansion-panel-header>
              <mat-panel-title>
                Filtro
              </mat-panel-title>
              <mat-panel-description>
                <mat-icon *ngIf="!loading" matTooltip="Filto" matTooltipPosition="above" matTooltipClass="tooltip" style="position: absolute;right: 50px;">filter_list</mat-icon>
              </mat-panel-description>
            </mat-expansion-panel-header>

            <div *ngIf="!loading" style="z-index: 2000px;">
              <table>
                <tr>
                  <td>
                    <img matTooltip="Gráfico inicial" matTooltipPosition="above" matTooltipClass="tooltip" src="assets/icon_chart.png"
                    style="cursor: pointer;" (click)="changeTypeChart(); mep.expanded = false;">
                  </td>
                  <td style="padding-left: 10px;padding-right: 50px;">

                  </td>
                  <td>
                    <mat-form-field appearance="fill">
                      <mat-label>Selecionar por variável</mat-label>
                      <mat-select [formControl]="toppings" multiple (selectionChange)="changeTag($event)">
                        <mat-select-trigger>
                          {{toppings.value ? toppings.value[0] : ''}}
                          <span *ngIf="toppings.value?.length > 1" class="example-additional-selection">
                            (+{{toppings.value.length - 1}} {{toppings.value?.length === 2 ? 'outro' : 'outros'}})
                          </span>
                        </mat-select-trigger>
                        <mat-option *ngFor="let topping of toppingList" [value]="topping['name']">{{topping['name']}}</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </td>
                  <td>
                    <button mat-raised-button color="primary" (click)="showChartByVariable(); mep.expanded = false;">Pesquisar</button>
                  </td>
                </tr>
              </table>
            </div>
          </mat-expansion-panel>
        </mat-accordion>
      </td>
      <td style="position: fixed;padding-top: 10px;padding-left: 25px;">
        <button mat-menu-item class="menuFilterItem" (click)="exportAsPDF('chartOsc')" matTooltip="Exportar para PDF" matTooltipPosition="above" matTooltipClass="tooltip">
          <img src="./../../assets/pdf.png">
        </button>
      </td>
      <td *ngIf="loadingPdf" style="padding-left: 70px;">
        <img src="./../../assets/loading.gif">
      </td>
    </tr>
  </table>
</div>



<div style="position: absolute;top:0px;right: 5px;z-index: 2000;">
  <a style="cursor: pointer;" mat-dialog-close (click)="closeDialog()"><img src="assets/icon_closechart.png"></a>
</div>

