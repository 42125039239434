import { Component, OnInit } from '@angular/core';
import { ServiceService } from './../service.service';

@Component({
  selector: 'app-addgroups',
  templateUrl: './addgroups.component.html',
  styleUrls: ['./addgroups.component.css']
})
export class AddgroupsComponent implements OnInit {

  constructor(
    public service: ServiceService
  ) { }

  validategroup = false;
  warning = false;
  warningmessage = '';
  groupname = '';
  tension = '';
  ltcomp = '';
  category = '';
  addgroupOk = false;
  addgroupError = false;

  ngOnInit(): void {
    this.service.sessionExpired();
  }

  addGroup() {
    if (this.groupname == '') {
      this.warning = true;
      this.warningmessage = 'Preencha o campo abaixo';
    } else {
      this.validategroup = true;

      this.service.post_AddGroup(this.groupname, this.tension, this.ltcomp, this.category).subscribe((data: any) => {
        if (data) {
          this.validategroup = false;
          this.warning = true;
          this.addgroupError = true;
          this.warningmessage = 'O grupo ' + this.groupname + ' já esta cadastrado.';
        } else {
          this.warning = true;
          this.validategroup = false;
          this.addgroupOk = true;
          this.warningmessage = 'Grupo adicionado com sucesso';
        }
      });
    }
  }

}
