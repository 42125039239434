
<div class="grid-container">
  <h1 mat-dialog-title>
    <mat-icon style="display: inline-flex; vertical-align: middle;padding-bottom: 4px; padding-right: 6px;">edit</mat-icon> Alterar meus dados
  </h1>
<mat-card>
  <mat-card-content>
    <table style="width: 100%;">
      <tr *ngIf="this.validateedituser">
        <td style="border-bottom: 1px solid #E0E0E0;padding-bottom: 10px;">
          <mat-spinner  [diameter]="20" style="margin: auto"></mat-spinner> <b>Editando dados</b>
        </td>
      </tr>
      <tr *ngIf="warning">
        <td style="border-bottom: 1px solid #E0E0E0;padding-bottom: 10px;">
          <b [ngClass]="{warningOn: editUserError == true, warningOff: this.editUserOk == true}">
            {{this.warningmessage}}
          </b>
        </td>
      </tr>
      <tr *ngIf="this.service.isAdmin">
        <td style="text-align: left;padding-top: 10px;">
          <mat-checkbox [(ngModel)]="changePass" (change)="changeStatus($event)">Troca de senha no primeiro acesso</mat-checkbox>
        </td>
      </tr>
      <tr>
        <td style="text-align: left;padding-top: 10px;">
          <mat-checkbox [checked]="changeOscCheck" [(ngModel)]="getOscByEmail" (change)="changeStatusOscEmail($event)">Receber oscilografia por e-mail</mat-checkbox>
        </td>
      </tr>
      <tr>
        <td style="text-align: left;padding-top: 10px;">
          <mat-checkbox [checked]="changeNetworkCheck" [(ngModel)]="getNetworkFailByEmail" (change)="changeStatusNetworkEmail($event)">Receber falha de comunicação por e-mail</mat-checkbox>
        </td>
      </tr>
      <tr *ngIf="this.service.isAdmin">
        <mat-form-field class="full-width-input">
          <input class="inputcolor" matInput placeholder="Matrícula" [(ngModel)]="username" [value]="this.username" name="username">
        </mat-form-field>
      </tr>
      <tr>
        <mat-form-field class="full-width-input">
          <input class="inputcolor" matInput placeholder="Nome completo" [(ngModel)]="fullname" [value]="this.fullname" name="fullname">
        </mat-form-field>
      </tr>
      <tr>
        <tr>
          <mat-form-field class="full-width-input">
            <input class="inputcolor" matInput placeholder="E-mail" [(ngModel)]="email" [value]="this.email" name="email">
          </mat-form-field>
        </tr>
        <tr [ngClass]="{showAddPass: !this.changePassCheck, hideAddPass: this.changePassCheck}">
        <mat-form-field class="full-width-input">
          <input class="inputcolor" matInput placeholder="Nova senha(opcional)" [type]="fieldTextType ? 'text' : 'password'" [(ngModel)]="password" name="password" >
          <span class="lnr-eye">
            <i
              class="fa"
              [ngClass]="{
                'fa-eye-slash': !fieldTextType,
                'fa-eye': fieldTextType
              }"
              (click)="toggleFieldTextType(0)"
            ></i>
          </span>
        </mat-form-field>
      </tr>
      <tr *ngIf="this.service.isAdmin">
        <td>
          <mat-form-field class="full-width-input">
            <mat-select placeholder="Grupos">
              <mat-option *ngFor="let grupo of listaGruposCombobox; let i =index" [value]="grupo.id">
                  <mat-checkbox [checked]="grupo.status" (change)="OnChange($event, grupo.id)"> {{grupo.groupname}}</mat-checkbox>
              </mat-option>
            </mat-select>
          </mat-form-field>
        </td>
      </tr>
    </table>
  <br>
    <button mat-raised-button color="primary" type="submit" (click)="onEditUser()">Salvar</button>

  </mat-card-content>
</mat-card>
</div>

