<div class="networkdiv">
  <table cellspacing="0" cellpadding="0" class="maindiv">
    <th class="thFilter">Última checagem</th>
    <th class="thFilter">Última conexão válida</th>
    <th class="thFilter">Concessão</th>
    <th class="thFilter">Status</th>
    <tr *ngFor="let s of this.listNetworkStatus">
      <td class="divFilterVariableName">{{s.timestamp}}</td>
      <td class="divFilterVariableName">{{s.lastvalidconection}}</td>
      <td class="divFilterVariable">{{s.concession}}</td>
      <td class="divFilterVariable">
        <img *ngIf="s.status" src="./assets/wifion.png">
        <img *ngIf="!s.status" src="./assets/wifi-off.png">
      </td>
    </tr>
  </table>
</div>

<div class="mobile">
  <table cellspacing="0" cellpadding="0" class="maindivmobile">
  <th class="thFilter">Última conexão válida</th>
    <th class="thFilter">Concessão</th>
    <th class="thFilter">Status</th>
    <tr *ngFor="let s of this.listNetworkStatus">
    <td class="divFilterVariableName">{{s.lastvalidconection}}</td>
      <td class="divFilterVariable">{{s.concession}}</td>
      <td class="divFilterVariable">
        <img *ngIf="s.status" src="./assets/wifion.png">
        <img *ngIf="!s.status" src="./assets/wifi-off.png">
      </td>
    </tr>
  </table>
</div>
