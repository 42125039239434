import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { ServiceService } from './../service.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import {MatDialog} from '@angular/material/dialog';
import { EdituserComponent } from './../edituser/edituser.component';
import { AdduserComponent } from './../adduser/adduser.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
@Component({
  selector: 'app-tableusers',
  templateUrl: './tableusers.component.html',
  styleUrls: ['./tableusers.component.css']
})
export class TableusersComponent implements OnInit {

  constructor(
    public service: ServiceService,
    public dialog: MatDialog,
    public snackBar: MatSnackBar
  ) { }

  displayedColumnsUsers = ['username', 'nome', 'email', 'isAdmin', 'lastlogin', 'lastchangepass', 'edit', 'delete', 'iduser', 'status'];
  dataSource: MatTableDataSource<listusers>;

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  loading = false;
  listuserstable: listusers[] = [];
  interval;

  ngOnInit(): void {
    this.listUsers();
    this.refresh();    
  }

  listUsers() {
    this.loading = true;
    let isadmini = '';
    let lastlogintxt;
    let lastchangepasstxt;
    this.service.post_ListUsers().subscribe((data: any) => {

      for (let i = 0; i < data.length; i++) {
        if (data[i]['isAdmin']) {
          isadmini = 'Sim';
        } else {
          isadmini = 'Não';
        }

        if (data[i]['lastlogin'] == null) {
          lastlogintxt = "Nenhum login realizado";
        } else {
          lastlogintxt = moment(data[i]['lastlogin']).format('MM/DD/YYYY HH:mm:SS');
        }

        if (data[i]['lastchangepass'] == null) {
          lastchangepasstxt = "Nenhum login realizado";
        } else {
          lastchangepasstxt = moment(data[i]['lastchangepass']).format('DD/MM/YYYY HH:mm:SS');
        }

        this.listuserstable.push(
          {
            'username': data[i]['username'],
            'nome': data[i]['nome'],
            'email': data[i]['email'],
            'isAdmin': isadmini,
            'lastlogin': lastlogintxt,
            'lastchangepass': lastchangepasstxt,
            'edit': '',
            'delete': '',
            'iduser': data[i]['id'],
            'status': data[i]['status']
          }
        );
        if (i == data.length - 1) {
          this.loading = false;
          this.dataSource = new MatTableDataSource(this.listuserstable);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
        }
      }
    });

  }

  openDialog(type, username, iduser, fullname, email) {

    let dialogRef;

    if (type == 0) {
      dialogRef = this.dialog.open(AdduserComponent, {
        width: '500px',
      });
    } else {
      dialogRef = this.dialog.open(EdituserComponent, {
        width: '500px',
        data: { 'username': username, 'iduser': iduser, 'fullname': fullname, 'email': email, 'groups': [], 'type': 1 }
      });
    }
  }

  blockUser(iduser, statususer) {

    let strMessage = '';
    let flagStatus;

    if (statususer) {
      strMessage = 'bloqueado';
      flagStatus = false;
      this.service.post_AddToOperationHistory(this.service.idUser, 5).subscribe();
    } else {
      strMessage = 'desbloqueado';
      flagStatus = true;
      this.service.post_AddToOperationHistory(this.service.idUser, 6).subscribe();
    }

    this.service.post_DeleteUser(iduser, statususer).subscribe((data: any) => {
      if (data) {
        this.snackBar.open('Usuário ' + strMessage + ' com sucesso.', '', {
          duration: 1000,
          verticalPosition: 'top',
          horizontalPosition : 'center',
          panelClass: ['snackbarOk']
        });
        this.listuserstable.forEach((currentValue, index) => {
          if (currentValue.iduser == iduser) {
            currentValue.status = flagStatus;
          }
        });
      } else {

      }
    });
  }

  deleteUser(iduser) {
    this.loading = true;
    this.service.post_AddToOperationHistory(this.service.idUser, 10).subscribe();
    this.service.post_DeleteUserAdm(iduser).subscribe((data: any) => {
      if (data) {
        this.listuserstable = [];
        this.listUsers();
        this.snackBar.open('Usuário deletado com sucesso.', '', {
          duration: 3000,
          verticalPosition: 'top',
          horizontalPosition : 'center',
          panelClass: ['snackbarOk']
        });
        this.loading = false;
      }
    });
  }

  refresh() {
    this.interval = setInterval(() => {
      if (this.service.stoprefresh) {
        this.stopInterval();
      } else {
        console.log('entrei no refresh');
      }
    }, 2000);
  }

  stopInterval() {
    this.listuserstable = [];
    console.log('atualizei a lista');
    this.listUsers();
    //clearInterval(this.interval);
  }

  ngOnDestroy(){
    clearInterval(this.interval);
  }
}

export interface listusers {
  username: string;
  nome: string;
  email: string;
  isAdmin: string;
  lastlogin: string;
  lastchangepass: string;
  edit: string;
  delete: string;
  iduser: number;
  status: boolean;
}
