import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ServiceService } from './../service.service';
import * as moment from 'moment';

@Component({
  selector: 'app-historynetwork',
  templateUrl: './historynetwork.component.html',
  styleUrls: ['./historynetwork.component.css']
})
export class HistorynetworkComponent implements OnInit {

  constructor(
    public service: ServiceService
  ) { }

  displayedColumnsUsers = ['timestamp', 'lastvalidconection', 'concession', 'status'];
  dataSource: MatTableDataSource<listnetwork>;
  loading = false;
  plant = '';
  listnetwork = [];
  listnetworkexcel = [];
  initialdate;
  finaldate;
  selectOperation;
  listDicOperations = [];

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  ngOnInit(): void {
    this.service.sessionExpired();
    this.listNetworkHistory();
    this.listPlant();
  }

  listNetworkHistory() {
    this.loading = true;
    let statusend = '';
    this.service.post_HistoryNetwork(this.plant).subscribe((data: any) => {
      for (let i = 0; i < data.length; i++) {

        if (data[i]['status']) {
          statusend = 'Ativo';
        } else {
          statusend = 'Inativo';
        }

        this.listnetwork.push(
          {
            'timestamp': moment(data[i]['timestamp']).format('DD/MM/YYYY HH:mm:SS'),
            'lastvalidconection': moment(data[i]['lastvalidconection']).format('DD/MM/YYYY HH:mm:SS'),
            'concession': data[i]['concession'],
            'status': statusend,
            'statuscolor': data[i]['status']
          }
        )

        this.listnetworkexcel.push(
          {
            'Última checagem': moment(data[i]['timestamp']).format('DD/MM/YYYY HH:mm:SS'),
            'Última conexão válida': moment(data[i]['lastvalidconection']).format('DD/MM/YYYY HH:mm:SS'),
            'Concessão': data[i]['concession'],
            'Status': statusend
          }
        )
      }
      this.dataSource = new MatTableDataSource(this.listnetwork);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.loading = false;
    });
  }

  listPlant() {
    this.service.post_ListFiltersByPlant(this.service.idUser).subscribe((data: any) => {
      this.listDicOperations = data;
    });
  }

  filterByDate() {
    let listfilter = [];
    this.listnetworkexcel = [];
    let from_date = moment(this.initialdate).format('YYYY-MM-DD HH:mm:SS');
    let to_date = moment(this.finaldate).format('YYYY-MM-DD HH:mm:SS');
    let statusend = '';
    this.listnetwork.forEach((currentValue) => {

      if (currentValue.status) {
        statusend = 'Ativo';
      } else {
        statusend = 'Inativo';
      }

      if (moment(currentValue.timestamp).format('YYYY-DD-MM HH:mm:SS') > from_date && moment(currentValue.timestamp).format('YYYY-DD-MM HH:mm:SS') < to_date) {
        listfilter.push(
          {
            'timestamp': moment(currentValue.timestamp).format('DD/MM/YYYY HH:mm:SS'),
            'lastvalidconection': moment(currentValue.lastvalidconection).format('DD/MM/YYYY HH:mm:SS'),
            'concession': currentValue.concession,
            'status': currentValue.status,
            'statuscolor': statusend
          }
        );

        this.listnetworkexcel.push(
          {
            'Última checagem': moment(currentValue.timestamp).format('DD/MM/YYYY HH:mm:SS'),
            'Última conexão válida': moment(currentValue.lastvalidconection).format('DD/MM/YYYY HH:mm:SS'),
            'Concessão': currentValue.concession,
            'Status': statusend
          }
        )
      }
    });

    this.dataSource = new MatTableDataSource(listfilter);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.loading = false;
  }

  clearFilter() {
    this.initialdate = '';
    this.finaldate = '';
    this.selectOperation = '';
    this.dataSource = new MatTableDataSource(this.listnetwork);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.loading = false;
  }

  filterbyplant() {
    let listfilter = [];
    this.listnetworkexcel = [];
    let statusend;

    for (let i = 0; i < this.listnetwork.length; i++) {
      if (this.listnetwork[i]['concession'] == this.selectOperation) {

        if (this.listnetwork[i]['statuscolor']) {
          statusend = 'Ativo';
        } else {
          statusend = 'Inativo';
        }

        listfilter.push(
          {
            'timestamp': this.listnetwork[i]['timestamp'],
            'lastvalidconection': this.listnetwork[i]['lastvalidconection'],
            'concession': this.listnetwork[i]['concession'],
            'status': statusend,
            'statuscolor': this.listnetwork[i]['statuscolor']
          }
        );

        this.listnetworkexcel.push(
          {
            'Última checagem': this.listnetwork[i]['timestamp'],
            'Última conexão válida': this.listnetwork[i]['lastvalidconection'],
            'Concessão': this.listnetwork[i]['concession'],
            'Status': statusend
          }
        )
      }
    }

    this.dataSource = new MatTableDataSource(listfilter);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.loading = false;
  }

  ExportExcel() {
    this.service.exportAsExcelFile(this.listnetworkexcel, 'Historico de Rede', 'Historico de Rede');
  }

  refresh() {
    this.listnetwork = [];
    this.listnetworkexcel = [];
    this.listNetworkHistory();
  }
}

export interface listnetwork {
  timestamp: string;
  lastvalidconection: string;
  concession: string;
  status: boolean;
}